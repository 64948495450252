<template>
    <div>
        <div class="grey lighten-5 ai-watermark">
            <v-container class="content-container">
                <v-row>
                    <v-col
                        class="pt-10 pb-6">
                        <img
                            alt="ICC Ai Navigator Logo"
                            :src="asset(require('../../images/ai_navigator/ai_navigator_logo.svg'))"
                            width="150"/>
                        <h1 class="fs-40 font-weight-bold oxygen">
                            Understand codes faster with ICC AI Navigator
                        </h1>
                        <h3 class="fs-20 font-weight-regular my-3">
                            The power of AI, trained by ICC experts.
                        </h3>
                        <v-row>
                            <v-col>
                                <use-ai-navigation v-if="hasPremiumSubscription">
                                </use-ai-navigation>
                                <start-free-trial v-else>
                                </start-free-trial>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="!isMobilePort">
                        <img
                            alt="ICC Ai Navigator Example"
                            :src="asset(require('../../images/ai_navigator/chat_graphic.webp'))"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container text-center">
            <v-row>
                <v-col>
                    <h4 class="teal--text fs-20 font-weight-medium mt-8 mb-4">
                        Harnessing the power of OpenAI
                    </h4>
                    <h2 class="fs-32 font-weight-bold oxygen">
                        An AI assistant built for the future
                    </h2>
                </v-col>
            </v-row>
            <v-row class="my-6">
                <v-col
                    cols="12"
                    sm="3"
                    v-for="(item, key) in aiPower"
                    :key="key">
                    <v-avatar
                        color="primaryLight"
                        size="60">
                        <v-icon
                            x-large
                            color="primaryMedium">
                            {{item.icon}}
                        </v-icon>
                    </v-avatar>
                    <p class="fs-20 font-weight-medium mt-4">
                        {{item.text}}
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <div class="grey lighten-5">
            <v-container class="content-container">
                <v-row>
                    <v-col v-if="!isMobilePort">
                        <img
                            class="my-6"
                            alt="ICC Ai Navigator Example"
                            :src="asset(require('../../images/ai_navigator/get_answers_blurb.webp'))"/>
                    </v-col>
                    <v-col>
                        <h2 class="fs-36 my-4 oxygen">
                            Get answers to your compliance requirement questions anytime
                        </h2>
                        <p class="fs-20 font-weight-medium">
                            AI Navigator can offer quick, helpful replies to basic code questions, and is programmed to help users navigate our I-Codes like an expert.
                        </p>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="auto">
                                <use-ai-navigation v-if="hasPremiumSubscription">
                                </use-ai-navigation>
                                <start-free-trial v-else>
                                </start-free-trial>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container">
            <v-row class="text-center mb-4">
                <v-col>
                    <h4 class="teal--text fs-20 font-weight-medium mt-8 mb-4">
                        Accuracy and consistency
                    </h4>
                    <h2 class="fs-32 font-weight-bold oxygen">
                        How we developed a tool to meet your needs
                    </h2>
                </v-col>
            </v-row>
            <v-timeline
                :dense="isMobilePort">
                <v-timeline-item
                    v-for="(item, key) in howWeBuiltIt"
                    :key="key"
                    color="primaryMedium"
                    fill-dot>
                    <template v-slot:opposite>
                        <h2
                            class="font-weight-bold oxygen"
                            v-text="item.title">
                        </h2>
                    </template>
                    <h3
                        v-if="isMobilePort"
                        class="font-weight-bold oxygen mb-4"
                        v-text="item.title">
                    </h3>
                    <v-card class="grey lighten-5">
                        <v-card-text>
                            <div
                                v-html="item.description"
                                class="fs-16">
                            </div>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
            <v-row class="text-center mt-6 mb-0">
                <v-col>
                    <h4 class="teal--text fs-20 font-weight-medium mt-8 mb-4">
                        Constantly improving and ever evolving
                    </h4>
                    <h2 class="fs-32 font-weight-bold oxygen">
                        Updates from the team
                    </h2>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col
                    cols="12"
                    sm="8">
                    <v-card
                        class="grey lighten-5 mb-6"
                        outlined>
                        <v-card-text class="py-1">
                            <html-block
                                slug="ai-updates">
                                <p class="fs-16 mt-4 text-center font-weight-medium w-full">
                                    No updates yet!<br>  Please check back later.
                                </p>
                            </html-block>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div class="grey lighten-5 mt-4">
            <v-container class="content-container">
                <v-row align="center">
                    <v-col v-if="!isMobilePort">
                        <img
                            alt="ICC Ai Navigator Example"
                            :src="asset(require('../../images/ai_navigator/chat_graphic.webp'))"/>
                    </v-col>
                    <v-col
                        class="py-16">
                        <h1 class="fs-40 font-weight-bold oxygen">
                            Try it now with Digital Codes Premium
                        </h1>
                        <h3 class="fs-20 font-weight-regular my-3 mb-4">
                            Try Premium today and get access to AI Navigator in addition to insights, advanced search and more.
                        </h3>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="auto">
                                <use-ai-navigation v-if="hasPremiumSubscription">
                                </use-ai-navigation>
                                <start-free-trial v-else>
                                </start-free-trial>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container class="content-container mt-6 mb-8">
            <v-row>
                <v-col>
                    <f-a-qs :faqs="faqs">
                    </f-a-qs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import StartTrialButton from "../buttons/StartFreeTrial.vue";
    import StartFreeTrial from "../buttons/StartFreeTrial.vue";
    import UseAiNavigation from "../buttons/UseAiNavigator.vue";
    import {NavigatorApi} from "../../api/Navigator";
    import Mobile from "../inc/mixins/mobile";
    import HtmlBlock from "../HtmlBlock.vue";
    import FAQs from "../expands/FAQs.vue";

    export default {
        name: 'AiMarketing',
        components: {FAQs, HtmlBlock, UseAiNavigation, StartFreeTrial, StartTrialButton},
        data() {
            return {
                hasUpdates: true,
                aiPower: [
                    {
                        icon: 'mdi-navigation-outline',
                        text: 'Navigate I-codes with the help of powerful AI'
                    },
                    {
                        icon: 'mdi-text-search-variant',
                        text: 'Able to search multiple code years and across multiple I-Codes at once'
                    },
                    {
                        icon: 'mdi-account-hard-hat-outline',
                        text: 'Trained and tested by ICC experts'
                    },
                    {
                        icon: 'mdi-thumbs-up-down-outline',
                        text: 'Users can help rate and improve the ICC’s AI programming'
                    },
                ],
                howWeBuiltIt: [
                    {
                        title: 'Challenges worth investing in',
                        description: '<p class="fs-16">We at the ICC recognize the power of large language model (LLM) artificial intelligence to revolutionize how user can interact with content such as our codebooks. We committed early in 2023 to design and build a customized LLM to help users with ICC codes.</p>' +
                            '<p class="fs-16 mb-0">Codebook content is unique and poses challenges for any LLM. Many answers our readers want to find in ICC codes may exist in different sections, or even different codebooks, depending on the nature of the question. Furthermore, codes are revised on three-year cycles, with meaningful differences between the codes from each cycle.</p>'
                    },
                    {
                        title: 'Goals built around our users',
                        description: '<p class="fs-16">We recognize that we can provide unique programming to an LLM that will help us achieve two key goals:</p>' +
                            '<p class="fs-16 font-weight-bold">1. Achieving high accuracy.</p>' +
                            '<p class="fs-16">In our testing with “untrained” ChatGPT and other LLM artificial intelligence, we found accuracy of response to be very low. Our target for the ICC’s AI Navigator is to perform much better than those services now, and to eventually provide 90+% accuracy on what we believe are simple code questions.</p>' +
                            '<p class="fs-16 font-weight-bold">2. Providing consistent answers and direction</p>' +
                            '<p class="fs-16 mb-0">When using LLM artificial intelligence, it often can return 10-20 answers or more to any question. Users who have tried public AI tools often find that asking the same question twice will illicit different answers. We have provided our AI Navigator tool a unique toolset to provide consistent answers and to deliver the most relevant links to content.</p>'
                    },
                    {
                        title: 'Delivering a solution',
                        description: '<p class="fs-16">To achieve these goals, we at the ICC have taken unique steps with AI Navigator:</p>' +
                            '<ul class="mb-4">' +
                            '<li>Using an extensive set of vetted questions and answers from ICC Technical Experts to evaluate the tool as we update and “train” it.</li>' +
                            '<li>Programming AI Navigator to “know what it doesn’t know.” We want to eliminate the guessing and “hallucination” that can plague LLMs.</li>' +
                            '<li>Moving forward deliberately with the content we expose AI Navigator to. Over time we intend to expand AI Navigator to cover a large amount of valuable ICC content. But we want it to understand the important context needed to differentiate between content.</li>' +
                            '</ul>' +
                            '<p class="mb-0">We will be updating our users on the progress of AI Navigator as we evolve it. We invite our Digital Codes Premium users to try it and provide us valuable feedback.</p>'
                    }
                ],
                faqs: [
                    {
                        title: 'What does it mean that ICC AI Navigator is a “Beta”?',
                        description: '<p>AI Navigator uses Large Language Model (LLM) artificial intelligence to provide answers and guidance to the relevant code content for users of our Digital Codes platform. LLM AI tools like AI Navigator benefit from use and feedback by real users under real conditions.  The ICC has done extensive internal testing of this tool, but we intend to use feedback from our Beta to further refine and update it.</p>'
                    },
                    {
                        title: 'How do I get the best answers from AI Navigator?',
                        description: '<ul>'+
                            '<li><p>Ask AI Navigator questions that offer important details and ask for a clear resolution. Target the use you want to know about as specifically as possible.  For instance, a question like this will tend to get a high-quality response:</p><p class="text-italic">“How can I determine if a new construction school building requires an automatic sprinkler system?”</p><p>A question such as this is less likely to give you a meaningful answer:</p><p class="text-italic">“When is an automatic sprinkler system required?”</p></li>'+
                            '<li><p>Use the Referenced Sections links to read the relevant code sections.  Consider whether the section has the necessary information for your situation.  For instance, the International Energy Conservation Code has sections for both Residential and Commercial builds (using “R” and “C” prefixes).  If AI Navigator does not understand the type of use you are asking about, it may reference either section, or provide links to both.  The best way to validate  AI Navigator’s answer is right for you is always to read the code section in addition to the answer  AI Navigator returns on screen.</p></li>'+
                            '<li><p>Questions should be 500 characters or less. Shorter questions are best.</p></li>'+
                            '<li><p>You do not have to specify a codebook, but if you are sure of the codebook you want an answer from, say "In the IBC" or something similar.</p></li>'+
                            '<li><p>Ask AI Navigator just one question per submission. Multi-part questions get better answers when asked as multiple questions.</p></li>'+
                            '</ul>'
                    },
                    {
                        title: 'What codes is ICC AI Navigator trained on and when will it be trained on more?',
                        description: '<p>Currently,  AI Navigator is trained on ICC’s “I-Codes” – which are adopted as is in many locations.   AI Navigator searches the 2021 editions of the ICC I-codes, but users can also select the  2018 or 2015 editions by using the “Change Code Cycle” option on screen.</p>'+
                            '<p>The ICC will be continually expanding scope of contents across which AI Navigator is able to answer questions on including: </p>'+
                            '<ul>'+
                            '<li>The 2024 edition I-Codes</li>'+
                            '<li>Numerous US state and jurisdiction custom codes based on ICC codebooks</li>'+
                            '<li>ICC’s code commentaries and <a href="'+this.getRoute('premium_answers')+'">“Premium Answers.”</a></li>'+
                            '</ul>'
                    },
                    {
                        title: 'How do the ratings I provide on ICC AI Navigator answers help make it better?',
                        description: '<p>Throughout our build of AI Navigator, user ratings have been used to accomplish two goals:</p>'+
                            '<ol>'+
                            '<li>To highlight the best answer structure.   We want  AI Navigator to provide brief, informative answers.  So positive responses that the wording it is using is clear and concise help.</li>'+
                            '<li>To look for trends in what  AI Navigator knows.   Code content can be difficult for humans and AI alike.  We are aggregating both positive and negative ratings, and using the feedback to identify content that AI Navigator is well-versed in, and content where we need to analyze mistakes it may be making.</li>'+
                            '</ol>'
                    }
                ]
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style lang="scss">
    .ai-watermark {
        background-size: auto 100%;
        background-position: center;
        background-image: url('../../images/ai_navigator/watermark.svg');
    }
</style>
