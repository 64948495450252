var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "h1",
                { staticClass: "oxygen white--text font-weight-light mb-8" },
                [_vm._v("Who We Are")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "roboto white--text" }, [
                _vm._v(
                  "\n                The International Code Council develops construction and public safety codes through a governmental\n                consensus process. The International Codes, or I-Codes, are the most trusted and widely adopted\n                source of\n                codes across the world. Decades of knowledge and experience guide our code development process to\n                create\n                safe, sustainable and affordable communities at a level playing field for builders, developers and\n                manufacturers. As industry experts, we provide the codes and standards that our members turn to,\n                building\n                safety professionals rely on and manufacturers trust.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "roboto white--text mb-8" }, [
                _vm._v(
                  "\n\n                State and local governments partner with ICC to adopt the International Codes either as published or\n                with\n                modifications to create a custom code. ICC’s team of experts provide project-based consulting to\n                develop\n                custom codes and make implementing modern building codes and standards seamless and straightforward.\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    "x-large": _vm.isLargeAndUp,
                    href: "https://www.iccsafe.org/about/who-we-are/",
                  },
                },
                [_vm._v("\n                Learn More\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "text-center d-none d-lg-block",
              attrs: { cols: "6" },
            },
            [
              _c("img", {
                attrs: {
                  src: this.asset(
                    require("../../images/homepage/who-we-are.png")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }