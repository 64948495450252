var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.documentInfo && this.isBundledPremiumOnlyBook(_vm.documentInfo)
        ? [
            _c(
              "v-container",
              { staticClass: "pb-0 pt-5" },
              [
                _c("access-level-message", {
                  attrs: {
                    "access-level": _vm.documentInfo.content_access_level.id,
                  },
                }),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-container",
              { staticClass: "py-0" },
              [
                _c("v-row", { staticClass: "ml-0" }, [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v("Choose a subscription that suits you"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "caption" }, [
                    _vm._v(
                      "* Member pricing applied automatically when you sign in."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.priceSet
                  ? _c(
                      "v-radio-group",
                      {
                        staticClass: "pt-0 mt-0",
                        attrs: { dense: "", "hide-details": "" },
                        model: {
                          value: _vm.duration,
                          callback: function ($$v) {
                            _vm.duration = $$v
                          },
                          expression: "duration",
                        },
                      },
                      _vm._l(_vm.durations, function (item, key) {
                        return _c(
                          "v-row",
                          {
                            key: key,
                            class: {
                              "yellow lighten-5": item.id === _vm.duration,
                            },
                            attrs: { align: "center" },
                            on: {
                              click: function ($event) {
                                return _vm.changeDuration(item.id)
                              },
                            },
                          },
                          [
                            _c(
                              "v-col",
                              { staticClass: "pr-0", attrs: { cols: "auto" } },
                              [
                                _c("v-radio", {
                                  staticClass: "mr-0",
                                  attrs: { color: "accent2", value: item.id },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              [
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.text) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                item.id !== "M" && _vm.monthPrice
                                  ? [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "caption text-decoration-line-through font-weight-bold mb-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                ($" +
                                              _vm._s(
                                                (
                                                  _vm.getPricePerItem(
                                                    _vm.product,
                                                    _vm.isMember,
                                                    "M"
                                                  ) * _vm.product.quantity
                                                ).toFixed(2)
                                              ) +
                                              "/Month)\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "caption primary--text font-weight-bold mb-0",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                ($" +
                                              _vm._s(
                                                (
                                                  _vm.getProductTotalPrice(
                                                    _vm.product,
                                                    _vm.isMember,
                                                    item.id
                                                  ) /
                                                  (item.id === "C" ? 36 : 12)
                                                ).toFixed(2)
                                              ) +
                                              "/Month)\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "text-right",
                                attrs: { cols: "auto" },
                              },
                              [
                                [
                                  _c("p", { staticClass: "mb-0" }, [
                                    _vm._v(
                                      "\n                                $" +
                                        _vm._s(
                                          _vm.getProductTotalPrice(
                                            _vm.product,
                                            _vm.isMember,
                                            item.id
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  item.id !== "M" && _vm.monthPrice
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "caption font-weight-bold mb-0 primary--text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.getPurchaseSavings(
                                                  _vm.product,
                                                  _vm.isMember,
                                                  item.id
                                                )
                                              ) +
                                              "% savings\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _c(
                      "v-card",
                      { attrs: { flat: "", height: 220 } },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "fill-height" },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height",
                                attrs: { align: "center", justify: "center" },
                              },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "text-center" },
                                  [
                                    _vm.priceLoading &&
                                    _vm.isSinglePurchasableBook
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            indeterminate: "",
                                            color: "primary",
                                          },
                                        })
                                      : _vm.isPriceNotAvailable ||
                                        !_vm.isSinglePurchasableBook
                                      ? _c("p", { staticClass: "mb-0" }, [
                                          _c("b", [
                                            _vm._v(
                                              "This title is not available as Premium Singles offering.\n                                Please review available Premium Collections below within which this title is included."
                                            ),
                                          ]),
                                        ])
                                      : _c("p", { staticClass: "mb-0" }, [
                                          _c("b", [
                                            _vm._v(
                                              "The pricing information is not available for this title. Please contact ICC customer support."
                                            ),
                                          ]),
                                        ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isPriceNotAvailable && _vm.isSinglePurchasableBook
              ? _c(
                  "v-container",
                  { staticClass: "grey lighten-4 pt-3" },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-3",
                            staticStyle: { cursor: "pointer" },
                            attrs: { cols: "auto" },
                            on: { click: _vm.decrementQuantity },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "justify-center",
                                attrs: { color: "accent2" },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi-minus-circle-outline"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-text-field", {
                              staticClass: "centered-input",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                type: "tel",
                                min: 1,
                                solo: "",
                              },
                              model: {
                                value: _vm.product.quantity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.product, "quantity", $$v)
                                },
                                expression: "product.quantity",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "pt-3",
                            staticStyle: { cursor: "pointer" },
                            attrs: { cols: "auto" },
                            on: { click: _vm.incrementQuantity },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "justify-center",
                                attrs: { color: "accent2" },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi-plus-circle-outline"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  id: _vm.product.document_info.document_id,
                                  dark: "",
                                  color: "accent2",
                                  loading: _vm.addToCartLoading,
                                  disabled: !_vm.priceSet,
                                },
                                on: { click: _vm.addItem },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { left: "", small: "" } },
                                  [_vm._v("shopping_cart")]
                                ),
                                _vm._v(" Add to cart\n                    "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.product.quantity > 1
                      ? _c(
                          "v-row",
                          { staticClass: "ml-3" },
                          [
                            _c("v-col", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "caption primary--text mt-3 mb-0",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary--text",
                                      attrs: { left: "", small: "" },
                                    },
                                    [_vm._v("mdi-brightness-percent")]
                                  ),
                                  _vm._v(
                                    "\n                        Congrats! You've unlocked additional volume discounts.\n                    "
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "allow-overflow": "",
                                    "close-on-content-click": false,
                                    "offset-y": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "grey--text volume-button",
                                                  attrs: {
                                                    block: "",
                                                    rounded: "",
                                                    dense: "",
                                                    outlined: "",
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "\n                                            info\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " Additional Volume Savings\n                                    "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { right: "" } },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.menu
                                                            ? "mdi-chevron-up"
                                                            : "mdi-chevron-down"
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1119878155
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pb-0" },
                                        [
                                          _c("v-simple-table", [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _vm._v(
                                                      "\n                                                    Volume\n                                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.documentInfo &&
                                                _vm.documentInfo.content_type &&
                                                _vm.documentInfo.content_type
                                                  .name === "Premium Complete"
                                                  ? _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Discounts (Monthly and Annual)\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Discounts\n                                                "
                                                        ),
                                                      ]
                                                    ),
                                                _vm._v(" "),
                                                _vm.documentInfo &&
                                                _vm.documentInfo.content_type &&
                                                _vm.documentInfo.content_type
                                                  .name === "Premium Complete"
                                                  ? _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                    Discounts (3-Year)\n                                                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.discounts,
                                                function (item) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.volume },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.volume)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.discount)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm.documentInfo &&
                                                      _vm.documentInfo
                                                        .content_type &&
                                                      _vm.documentInfo
                                                        .content_type.name ===
                                                        "Premium Complete"
                                                        ? _c("td", [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  item.discount3Years
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "pb-0" }, [
                          _c("p", { staticClass: "caption mb-0" }, [
                            _vm._v(
                              "Have a discount code? You can enter it during checkout."
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c("p", { staticClass: "caption mb-0" }, [
                            _vm._v(
                              "\n                        For orders without a credit card or for Enterprise and Custom Solutions, contact our sales team  "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `mailto:${this.$getConst(
                                    "sales_email_address"
                                  )}`,
                                },
                              },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      this.$getConst("sales_email_address")
                                    ) +
                                    ")"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm._v(" "),
      _vm.isSingle
        ? _c(
            "v-container",
            { staticClass: "pt-3" },
            [
              _c("book-collections", {
                attrs: { "document-info": _vm.documentInfo },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }