<template>
    <outlined-card :showDivider="false">
        <template v-slot:title>
            <h2 class="text-h6 font-weight-bold profile-wrappe">
                Your Active Profile: {{ activeUserProfile.profileType }}
                <span class="active-code">{{ activeUserProfile.accessCodeOrName }}</span>
            </h2>
        </template>
        <template v-slot:content>
            <div class="fs-16">
                <template v-if="activeUserProfile.profileType">
                    <p class="mb-2 font-weight-bold">
                        <access-icon
                            :accessLevel="hasPremiumRole ? 'premiumActive' : 'basic'">
                        </access-icon>
                        {{ premiumTier }}
                    </p>
                    <p class="mb-2" v-if="hasPremiumRole">{{ premiumMessage }}:</p>
                    <p class="mb-2" v-else>{{ basicMessage }}:</p>
                </template>
                <v-progress-linear
                    v-else
                    class="mt-3"
                    indeterminate
                    color="primary">
                </v-progress-linear>

                <v-list dense>
                    <v-list-item
                        v-for="(link, index) in links"
                        :key="index"
                        role="listitem"
                        class="pa-0"
                        style="min-height: 32px;">
                        <template v-if="!hasPremiumRole && link.premiumRequired">
                            <span class="font-weight-bold disabled-link" aria-disabled="true">
                                {{ link.text }}
                            </span>
                        </template>
                        <template v-else>
                            <a
                                :href="link.url"
                                class="primary--text font-weight-bold"
                                target="_blank"
                                rel="noopener"
                                role="link">
                                {{ link.text }}
                            </a>
                        </template>
                    </v-list-item>
                </v-list>
            </div>
            <div v-if="!hasPremiumRole" class="pt-0">
                <v-btn
                    class="font-weight-bold"
                    outlined
                    depressed
                    target="_blank"
                    :href="getRoute('premium_features')">
                    Learn About Premium
                </v-btn>
            </div>
        </template>
    </outlined-card>
</template>

<script>
    import OutlinedCard from '../../../components/cards/OutlinedCard.vue';
    import AccessIcon from '../../../components/icons/AccessIcon.vue';
    import { StringUtil } from '../../../util/StringUtil';

    export default {
        data(){
            return {
                basicMessage: 'Subscribe for access to these features',
                premiumMessage: 'You have access to these features',
                links: [
                    {
                        text: 'AI Navigator',
                        url:  this.getRoute('ai_navigator')
                    },
                    {
                        text: 'Premium Answers',
                        url:  this.getRoute('premium_answers')
                    },
                    {
                        text: 'Compliance Calculators',
                        url:  this.getRoute('compliance_calculators')
                    },
                    {
                        text: 'Project Tags',
                        url:  this.getRoute('dashboard_my_tags'),
                        premiumRequired: true
                    },
                    {
                        text: 'Notes and Bookmarks',
                        url:  this.getRoute('notes'),
                        premiumRequired: true
                    }
                ]
            }
        },
        components: {
            OutlinedCard,
            AccessIcon
        },
        computed: {
            activeUserProfile() {
                const profile = this.activeProfile;
                if (!profile || !profile.type) {
                    return {
                        profileType: '',
                        accessCodeOrName: ''
                    };
                }

                const profileType = profile.type[0].toUpperCase() + profile.type.slice(1);
                let accessCodeOrName = '';

                if (profileType === 'Concurrent' && profile.assignments?.accessCode) {
                    accessCodeOrName = ` (${profile.assignments.accessCode})`;
                } else if (profileType === 'Enterprise' && profile.assignments?.companyName) {
                    accessCodeOrName = ` (${profile.assignments.companyName})`;
                }

                return {
                    profileType: profileType,
                    accessCodeOrName: accessCodeOrName
                };
            },
            premiumTier() {
                let profile = this.activeProfile;
                let premiumTier = this.personalProfileName || '';
                if ('concurrent' === profile?.type || 'enterprise' === profile?.type) {
                    if('' === this.currentSubscriptionType) {
                        premiumTier = 'Basic';
                    } else {
                        premiumTier = 'Premium ' + StringUtil.ucWord(this.currentSubscriptionType);
                    }
                }

                return premiumTier;
            },
            hasPremiumRole() {
                const allowedProfileNames = [
                    this.$getConst("userTier").single,
                    this.$getConst("userTier").collection,
                    this.$getConst("userTier").complete,
                    this.$getConst("userTier").staff,
                    this.$getConst("userTier").trial
                ];

                return allowedProfileNames.includes(this.premiumTier);
            },

        },
        methods: {
            setSubscriptionType(type){
                this.currentSubscriptionType = type;
            }
        },
        created(){
            EventBus.listen('set-subscription-type', (data) => this.setSubscriptionType(data));
        }
    }
</script>
<style scoped>
    .disabled-link {
        color: grey;
        cursor: not-allowed;
    }
    .profile-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .active-code {
        white-space: nowrap;
        flex-shrink: 0;
    }
</style>