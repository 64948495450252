var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.containerCss },
    [
      !_vm.freeView
        ? _c(
            "div",
            { ref: "insightsInformationContainer", staticClass: "px-3 py-2" },
            [
              !_vm.loading
                ? [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-select", {
                              staticClass: "insights-select white",
                              attrs: {
                                disabled: _vm.freeView,
                                outlined: "",
                                dense: "",
                                items: _vm.items,
                                clearable: true,
                                chips: "",
                                "small-chips": "",
                                "deletable-chips": "",
                                label: "Filter By Insight Type:",
                                multiple: "",
                                "item-text": "title",
                                "item-value": "key",
                                "hide-details": "",
                                "menu-props": { bottom: true, offsetY: true },
                              },
                              model: {
                                value: _vm.selectedInsights,
                                callback: function ($$v) {
                                  _vm.selectedInsights = $$v
                                },
                                expression: "selectedInsights",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 font-weight-medium caption mt-3",
                              },
                              [
                                _vm._v(
                                  "\n                        What are Insights?\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "caption mb-0" }, [
                              _vm._v(
                                "\n                        Premium Code Insights give additional context to code sections for greater understanding. Applicable Insights are identified by the [P] icon.\n                    "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && !_vm.freeView
        ? [
            _c("v-divider"),
            _vm._v(" "),
            _c("v-treeview", {
              staticClass: "overscroll-contain",
              staticStyle: { "overflow-y": "scroll" },
              attrs: {
                id: "insights-treeview",
                color: "primary",
                items: _vm.filteredSections,
                "item-children": "subsections",
                "item-text": "title",
                "item-key": "key",
                "expand-icon": "mdi-chevron-down",
                "open-on-click": "",
                open: _vm.openItems,
              },
              on: {
                "update:open": function ($event) {
                  _vm.openItems = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function ({ item, active }) {
                      return _c(
                        "v-row",
                        {
                          staticClass: "ma-0",
                          attrs: { dense: "", align: "center", row: "" },
                        },
                        [
                          _c("v-col", { staticClass: "xs11 pa-0" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-0 py-2 body-2 black--text cp font-weight-medium",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "toc-chapter-item-focused",
                                    attrs: { tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.insightClick(item)
                                      },
                                      keydown: function ($event) {
                                        return _vm.handleKeydown($event, item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.title) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    },
                  },
                ],
                null,
                false,
                2170325957
              ),
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.freeView
        ? _c(
            "div",
            { staticClass: "pa-3" },
            [
              _vm.freeView
                ? _c("trial-action", {
                    attrs: {
                      title: "Access Premium Code Insights",
                      content:
                        "Utilize relevant code Insights such as Commentaries,\n            Code Change Details, Hearing Videos, Key Changes, Code Interpretations and more.",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }