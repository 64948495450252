<template>
    <div>

        <h1 class="oxygen text-center font-weight-light mb-8">Frequently Asked Questions</h1>

        <v-expansion-panels class="roboto" flat>
            <v-expansion-panel
                    v-for="(item,i) in questions"
                    :key="i"
            class="py-2 border-bottom">

                <v-expansion-panel-header class="primary--text text-uppercase font-weight-bold">
                    {{ item.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p
                        class="mb-0"
                        v-html="item.text">
                    </p>
                    <a v-if="item.slug === `digital-codes-premium`"
                        @click="goToRoute('collections_landing',{documentSlug: 'premium-complete'})">
                        Learn more
                    </a>
                    <a v-if="item.slug === `digital-codes-premium-complete`"
                        @click="goToRoute('collections_landing',{documentSlug: 'premium-complete'})">
                        Click here for more details
                    </a>
                    <a v-if="item.slug === `digital-codes-contents`"
                        @click="goToRoute('codes')">
                        Learn more
                    </a>
                    <a v-if="item.slug === `digital-codes-premium-mobile`"
                        href="https://play.google.com/store/apps/details?id=org.iccsafe.codes"
                        target="_blank">
                        Learn more
                    </a>
                </v-expansion-panel-content>

            </v-expansion-panel>
        </v-expansion-panels>

    </div>
</template>

<script>
import {AppParameters} from "../../AppParameters";

export default {
    name: 'FrequentlyAskedQuestions',
    data() {
        return {
            questions: [
                {
                    title: 'What is ICC Digital Codes?', text: `
                ICC’s Digital Codes is the most trusted and authentic source of model codes and
standards, which conveniently provides access to the latest code text from
across the United States
                `,
                slug:`digital-codes`
                },
                {
                    title: 'What is Digital Codes Premium?',
                    text: `ICC Digital Codes Premium is a monthly or yearly subscription offering, that enhances
                    a user’s code experience by unlocking hundreds of latest codes and standards and powerful tools
                    and features that allow users to quickly search codes, add notes, bookmarks, access supplemental
                    content, view real-time code changes proposals, significant code changes,
                    license sharing and more.`,
                    slug:`digital-codes-premium`
                },
                {
                    title: 'How much does Digital Codes Premium cost?',
                    text: 'Premium Complete – Starting from $' + AppParameters.complete_monthly_price + '* per month<br/>Premium Collections – Starting from $' + AppParameters.collection_monthly_price + '* per month<br/>Premium Singles – Starting from $' + AppParameters.single_monthly_price + '* per month<br/><br/> *ICC member pricing Volume discounts are applied such that the more licenses you purchase the cheaper that each individual license costs. Additionally annual and three year durations provide price breaks across a longer term for access. Visit the product page across any title to see all available Premium subscription options and pricing.',
                    slug:`digital-codes-premium-cost`
                },
                {
                    title: 'What contents are available on Digital Codes Premium?',
                    text: `
ICC Digital Codes has hundreds of the latest codes and standards and enhanced tools to help you go beyond the codes.`,
                    slug:`digital-codes-contents`
                },
                {
                    title: 'Where can I use Digital Codes Premium?',
                    text: 'ICC Digital Codes be used on any mobile or digital device anywhere 24/7',
                    slug:`digital-codes-premium-use`
                },
                {
                    title: 'Is there a Digital Codes Premium mobile app?',
                    text: 'Yes, our mobile app is available for download on the Apple and Google Play Store.',
                    slug:`digital-codes-premium-mobile`
                },
                {
                    title: 'What is Digital Codes Premium Complete?',
                    text: `
                    ICC Digital Codes Premium Complete enhances a user’s code experience by unlocking hundreds of
                    codes and standards with powerful features such as advanced search, revision history tagging,
                    code interpretations, bookmarks, notes, revision history, and more.`,
                    slug: `digital-codes-premium-complete`,
                },
                {
                    title: 'What is Digital Codes Premium Enterprise?',
                    text: `
                    ICC Digital Codes Premium Enterprise provides convenient access for an entire organization.
                    Contact Digital Codes Sales Specialist Phil Anthony (panthony@iccsafe.org) to
                    learn more and craft a customized quote around your needs.
                    `,
                    slug:`digital-codes-premium-enterprise`
                }
            ]
        }
    }
}
</script>

<style scoped>
.border-bottom {
    border-bottom: 1px solid lightgray;
}
</style>
