import Vue from 'vue';
import Vuex from 'vuex';
import { SearchModule } from './SearchModule';
import { CodesModule } from './CodesModule';
import { UtilsModule } from './UtilsModule';
import { FavoritesModule } from './FavoritesModule';
import { LegendModule } from './LegendModule';
import { DocumentModule } from './DocumentModule';
import { NavigationModule } from './NavigationModule';
import { PremiumModule } from "./PremiumModule";
import { UpdatesModule } from "./UpdatesModule";
import {CategoryModule} from "./CategoryModule";
import {DebugModule} from "./DebugModule";
import {NotesModule} from "./NotesModule";
import {ExamModule} from "./ExamModule";
import {QuickAccessModule} from "./QuickAccessModule";
import {ProfilesModule} from "./ProfileModule";
import {AiNavigatorModule} from "./AiNavigatorModule";
import PricingModule from './PricingModule';
import {CartModule} from "./CartModule";
import { BillingModule } from './BillingModule';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: false,
    modules: {
        SearchModule,
        CodesModule,
        UtilsModule,
        FavoritesModule,
        LegendModule,
        DocumentModule,
        NavigationModule,
        PremiumModule,
        UpdatesModule,
        CategoryModule,
        DebugModule,
        NotesModule,
        ExamModule,
        quickAccess: {...QuickAccessModule},
        profile: {...ProfilesModule},
        AiNavigatorModule,
        PricingModule,
        CartModule,
        BillingModule
    }
});
