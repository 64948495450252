<template>
    <confirm-dialog
        :is-open="dialog"
        borderStyle
        showHeaderBorder
        noGrayActionArea
        :fullscreen="isMobilePort"
        @closed="emitClosed()">
        <template v-slot:title>
            {{ dialogTitle }}
        </template>
        <template v-slot:default>
            <alert-bar
                class="mt-3"
                v-if="hasLicenseRemovalRequest && !invalidVisaCart"
                id="display_license_remove_info"
                :message="`Subscription has pending change. Submitting a new change will overwrite the previously pending changes. <br />Pending change to be overwritten:
&emsp; <ul><li> Licenses quantity changes to ${getNextRenewalLicense()} licenses. Auto-renews on ${formatAutoRenewDate()} for $${getNextRenewalCost()}.</li></ul>`"
                notificationType="warning">
            </alert-bar>
            <div class="pt-4">
                <h3>Title</h3>
                <p class="mb-0">{{ displayDocumentTitle }}</p>
            </div>
            <license-quantity
                displayBigger
                v-if="step === 1"
                :actionDisabled="invalidVisaCart">
            </license-quantity>
            <div class="mt-2" v-else>
                <h3>License Quantity</h3>
                <p class="mb-0">
                    {{quantity}} licenses
                </p>
            </div>
            <p class="green--text darken-3"
                v-if="quantity !== item.licences">
                {{ licenseMessage }}
            </p>
            <v-sheet v-if="savingsText.length"
                    class="rounded py-0 px-2 py-1 my-1 mb-2"
                    color="accent2Light">
                    <v-row dense>
                        <v-col cols="auto">
                            <v-icon
                                color="accent2"
                                class="mt-n1">
                                mdi-sale-outline
                            </v-icon>
                        </v-col>
                        <v-col>
                            <h4 class="font-weight-bold">
                                Discounts Applied:
                            </h4>
                            <ul>
                                <li
                                    v-for="(item, key) in savingsText"
                                    :key="key">
                                    {{ item.discount }}% {{ item.label }}
                                </li>
                            </ul>
                        </v-col>
                </v-row>
            </v-sheet>
            <div class="mb-4">
                <h3>Billing Frequency</h3>
                <p class="mb-0">{{ displayBillingType() }}</p>
                <p class="mb-0">
                    Auto-renews {{ formatAutoRenewDate() }} for
                    <span v-if="loadingPrice">
                        <v-progress-circular
                            indeterminate
                            :size="25"
                            color="primary">
                        </v-progress-circular>
                    </span>
                    <span v-else>${{ getPrice |  formatPrice }}</span>
                </p>
                <p class="green--text darken-3 mb-0" v-if="quantity !== item.licences">
                    Changed from ${{ initialPrice | formatPrice }} / {{ billingPeriodShortText }}
                </p>
            </div>
            <div>
                <h3>Payment Method</h3>
                <p v-if="!invalidVisaCart">{{ visaCardNumber }}</p>
                <alert-bar
                    class="mt-3"
                    v-if="invalidVisaCart"
                    id="display_http_messages"
                    :message="`Payment method information not found. <a href='${getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                        Update your payment method</a> to continue.`"
                    notificationType="warning">
                </alert-bar>
            </div>
            <p v-if="step === 2 && quantity > item.licences && !invalidVisaCart && getProRatePrice">
                You will be charged a prorated price of ${{ getProRatePrice |  formatPrice }} today.
                Your subscription will auto-renew with these changes for the full price of
                ${{ getPrice |  formatPrice }} {{ formatAutoRenewDate() }}. Includes applicable volume discounts.
            </p>
            <p v-else-if="step === 2 && quantity > item.licences && !invalidVisaCart && isFreeTransaction">
                There is no immediate charge required for the additional license(s) being added.
                Your subscription will auto-renew for the increased quantity of licenses at the price of ${{ getPrice |  formatPrice }} on {{ formatAutoRenewDate() }}.
                Includes applicable volume discounts.
            </p>
            <p v-else-if="step === 2 && quantity < item.licences && !invalidVisaCart">
                No payment required. This change will take effect when your subscription auto-renews.
            </p>
            <div>
                <p class="mb-0">
                    For assistance,
                    <a
                        :href="$getConst('links')['contactSupport']"
                        class="text-decoration-underline"
                        target="_blank">
                        please contact customer support.
                    </a>
                </p>
            </div>
        </template>
        <template v-slot:buttons>
            <v-container class="py-0 px-2">
                <v-row dense>
                    <v-col>
                        <alert-bar
                            dismissible
                            @dismiss="dismissAlert"
                            id="display_http_messages"
                            :message="message"
                            :notificationType="notifyType">
                        </alert-bar>
                    </v-col>
                </v-row>
                <v-row dense class="px-2" v-if="step === 2 && quantity > item.licences">
                    <v-col>
                        <h3 class="fs-20">Total Price Today</h3>
                    </v-col>
                    <v-col cols="auto">
                        <h3 class="fs-20">
                            <span v-if="loadingProratePrice">
                                <v-progress-circular
                                    indeterminate
                                    :size="25"
                                    color="primary">
                                </v-progress-circular>
                            </span>
                            <span v-else-if="getProRatePrice">${{ getProRatePrice |  formatPrice }}</span>
                            <span v-else-if="isFreeTransaction">$0.00</span>
                        </h3>
                    </v-col>
                </v-row>
                <v-row justify="end" class="mr-2 my-2">
                    <v-btn
                        v-if="step === 2"
                        class="font-weight-bold"
                        text
                        @click="step = 1">
                        Back
                    </v-btn>
                    <v-btn
                        class="mr-2 font-weight-bold"
                        outlined
                        @click="emitClosed()">
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="step == 1"
                        class="font-weight-bold"
                        color="accent2"
                        :disabled="isDisableReviewBtn"
                        @click="reviewChanges"
                        depressed>Review
                    </v-btn>
                    <v-btn
                        v-else
                        @click="submitAndPay"
                        color="accent2"
                        depressed
                        class="font-weight-bold"
                        :disabled="isDisableSubmitAndPayBtn"
                        :loading="loadingSubmit">
                        {{`Submit ${addedLicenseCount > 0 ? 'and Pay' : ''}`}}
                    </v-btn>
                </v-row>
            </v-container>
        </template>
    </confirm-dialog>
</template>

<script>
    import Moment from 'moment';
    import AlertBar from '../notifications/AlertBar.vue';
    import ConfirmDialog from '../ConfirmDialog.vue';
    import LicenseQuantity from '../pricing/LicenseQuantity.vue';
    import { ApiService } from '../../api/ApiService';
    import { BillingApi } from '../../api/BillingApi';;
    import Mobile from '../inc/mixins/mobile'

    import { mapState, mapGetters, mapActions } from 'vuex';


    export default {
        components: { AlertBar, ConfirmDialog, LicenseQuantity },
        data() {
            return {
                dialog: false,
                loadingPrice: false,
                disabledReview: false,
                initialPrice: 0,
                proRatePrice: '',
                loadingProratePrice: false,
                loadingSubmit: false,
                step: 1,
                item: {},
                notifyType: null,
                message: '',
                displayDocumentTitle: '',
                documentId: null,
                loggedId: null,
                logStatus: '',
                logMessage: ''
            }
        },
        mixins: [
            Mobile
        ],
        props: {
            shopUrl: {
                required: true,
                type: String
            },
            userId: {
                type: String,
                required: false
            }
        },
        computed: {
            ...mapGetters('PricingModule', [
                'cartTotalPrice',
                'getCartItems',
                'getIsMember',
                'displayPrice',
                'billingPeriodShortText',
                'savingsText'
            ]),
            ...mapGetters('BillingModule', [
                'getPaymentProfile',
            ]),
            ...mapState('PricingModule', ['quantity']),
            isLoggedIn() {
                return '' !== this.$store.getters.getLoggedIn;
            },
            visaCardNumber() {
                return `${this.getPaymentProfile?.card_type} ${this.getPaymentProfile?.cc_number}`;
            },
            getPrice(){
                return this.getIsMember ? this.cartTotalPrice.memberTotal : this.cartTotalPrice.nonMemberTotal;
            },
            getProRatePrice(){
                return this.proRatePrice * this.addedLicenseCount;
            },
            licenseMessage() {
                let quantityDiff = 0;
                let actionType = '';
                if (this.quantity > this.item.licences) { //increased quantity
                    quantityDiff = this.addedLicenseCount;
                    actionType = 'Added';
                } else {
                    quantityDiff = this.removedLicenseCount;
                    actionType = 'Removed';
                }

                return `${actionType} ${quantityDiff} licenses`;
            },
            addedLicenseCount() {
                return this.quantity - this.item.licences;
            },
            removedLicenseCount() {
                return this.item.licences - this.quantity;
            },
            isDisableReviewBtn(){
                return this.item.licences === this.quantity || this.invalidVisaCart;
            },
            dialogTitle() {
                return this.step === 1 ?  'Change License Quantity' : 'Review Subscription Change';
            },
            invalidVisaCart() {
                return _.isEmpty(this.getPaymentProfile);
            },
            actionTriggered() {
                if (this.quantity > this.item.licences) {
                    return 'Add Licenses';
                }
                if (this.quantity < this.item.licences) {
                    return 'Remove Licenses';
                }
                return '';
            },
            renewalLicenses() {
                return this.item.licences != this.quantity ? this.quantity : '';
            },
            isDisableSubmitAndPayBtn(){
                return  (this.quantity > this.item.licences && this.proRatePrice === '') ||
                        this.loadingProratePrice === true;
            },
            isFreeTransaction() {
                return '0' === this.proRatePrice;
            },
            hasLicenseRemovalRequest() {
                return this.item.licences > this.item.next_renewal_license;
            }
        },
        methods: {
            ...mapActions('PricingModule', [
                'addToCart',
                'setQuantity',
                'setFrequency',
                'emptyCart'
            ]),
            ...mapActions('BillingModule', [
                'setBillingUpdateMessage'
            ]),
            emitClosed() {
                this.logMessage = 'Change quantity action has been aborted.';
                this.logStatus = 'aborted';
                this.logSubscriptionChange();
                this.dialog = false;
            },
            getShopManagePaymentsPage() {
                return this.shopUrl + this.$getConst("links").shopManagePayments;
            },
            displayBillingType() {
                const subscriptionLabels = {
                    'M': 'Monthly',
                    'B': 'Annually'
                };

                return subscriptionLabels[this.item.subscription_type] || '3-Year';
            },
            hasPrice(title) {
                return !isNaN(parseFloat(this.displayPrice(title))) && isFinite(parseFloat(this.displayPrice(title)))
            },
            formatAutoRenewDate() {
                return `on ${Moment(this.item?.expiration).format('ll')}`;
            },
            getNextRenewalLicense() {
                return this.item.next_renewal_license;
            },
            getOrderDetailsUrl() {
                return this.item.subscription_detail_url;
            },
            getNextRenewalCost() {
                return new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(this.item.next_renewal_cost);
            },
            addItemToCart(titleObj) {
                const title = _.cloneDeep(titleObj);
                const itemInCart = this.getCartItems.find(item => item.id === title.id);
                if (!itemInCart && this.hasPrice(title)) {
                    this.addToCart(title);
                    this.initialPrice = this.getPrice;
                }
            },
            notifyUser(data){
                this.notifyType = !data.success ? 'error' : 'success';
                this.message = data.message;
            },
            reviewChanges(){
                this.step = 2;
                if (this.quantity < this.item.licences) { // if licenses decreased then no need of prorate price.
                    this.logSubscriptionChange();
                    return false;
                }
                this.loadingProratePrice = true;
                const request = {
                    orderId: this.item.parent_order_id,
                    sku: this.item.sku + this.item.subscription_type,
                    licenses: this.quantity
                }
                BillingApi.proRatePrice(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            this.proRatePrice =  respose.data.prorated_price;
                            this.logSubscriptionChange();
                        } else {
                            this.proRatePrice = '';
                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                        }
                    })
                    .catch((error) => {
                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.message
                        };
                       this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingProratePrice = false)
            },
            addQuantityAction() {
                this.loadingSubmit = true;
                let paymentMethodInfo = {};

                if('0' === this.proRatePrice) {
                    paymentMethodInfo = {
                        method: 'free',
                    };
                } else {
                    paymentMethodInfo = {
                        method: 'paymentechhpf',
                        additional_data: {
                            customer_ref_num: this.getPaymentProfile.profile_id
                        }
                    };
                }
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    paymentMethod: paymentMethodInfo,
                    billingAddress: this.getPaymentProfile.billing_address,
                    quantity: this.quantity - this.item.licences,
                    sku: this.item.sku,
                    subscription_type: this.item.subscription_type,
                    custom_price: this.proRatePrice,
                    subscription_id: this.item.subscription_id,
                    action: 'Add subscription licenses',
                    userId: parseInt(this.userId)
                }
                BillingApi.addLicensesCheckout(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            const message = `${this.displayDocumentTitle} update successful - order #${this.item.parent_increment_id}. <a href='${this.getOrderDetailsUrl()}' target='_blank' class='black--text text-decoration-underline'>See order details for more information.</a>`;
                            this.logMessage = message;
                            this.logStatus = 'success';

                            // Check and cancel any license removal request as well
                            if(this.item.licences >= this.item.next_renewal_license) {
                                this.cancelLicenseRemovalRequest();
                            }
                            this.logSubscriptionChange();
                            EventBus.fire('sync-billing-page', true);
                            this.dialog = false;
                            this.setBillingUpdateMessage(message);
                        } else {
                            this.logMessage = respose.data.message;
                            this.logStatus = 'failed';
                            this.logSubscriptionChange();

                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                            this.proRatePrice = ''; // To disable the submit button
                        }
                    })
                    .catch((error) => {
                        this.logMessage = error.response.data.message;
                        this.logStatus = 'error';
                        this.logSubscriptionChange();

                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.message
                        };
                        this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingSubmit = false)
            },
            removeQuantityAction() {
                this.loadingSubmit = true;
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    orderId: this.item.parent_order_id,
                    sku: this.item.sku + this.item.subscription_type,
                    qty: this.quantity,
                    userId: parseInt(this.userId)
                }
                BillingApi.removeLicensesCheckout(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            const message = `Subscription to ${this.displayDocumentTitle} updated successfully.`;

                            this.logMessage = message;
                            this.logStatus = 'success';
                            this.logSubscriptionChange();

                            EventBus.fire('sync-billing-page', false);
                            this.dialog = false;
                            this.setBillingUpdateMessage(message);
                        } else {
                            this.logMessage = respose.data.message;
                            this.logStatus = 'failed';
                            this.logSubscriptionChange();

                            const notifyObj = {
                                'success': false,
                                'message': respose.data.message
                            };
                            this.notifyUser(notifyObj);
                        }
                    })
                    .catch((error) => {
                        this.logMessage = error.response.data.message;
                        this.logStatus = 'error';
                        this.logSubscriptionChange();

                        const notifyObj = {
                            'success': false,
                            'message': error.response.data.message
                        };
                        this.notifyUser(notifyObj);
                    })
                    .finally(()=>this.loadingSubmit = false)
            },
            cancelLicenseRemovalRequest() {
                const request = {
                    purchaseId: this.item.parent_increment_id,
                    subscriptionId: this.item.subscription_id
                }
                BillingApi.cancelLicenseRemovalRequest(request)
                    .then((respose) => {
                        if (respose.data.success) {
                            EventBus.fire('sync-billing-page', true);
                        }
                    })
                    .catch((error) => {
                        // TODO: Audit log
                    });
            },
            submitAndPay() {
                if (this.quantity > this.item.licences) { //licenses increased
                    this.addQuantityAction();
                } else {
                    this.removeQuantityAction();
                }
            },
            dismissAlert(){
                this.message = '';
            },
            getDocInfoWithPrice(sku){
                this.loadingPrice = true;
                ApiService.getDocumentBySku(sku).then((res) => {
                    this.displayDocumentTitle = `${res?.year?.year} ${res?.title}`;
                    this.documentId = res.id;
                    this.addItemToCart(res);
                    this.logSubscriptionChange();
                }).catch((error) => {
                    this.message = 'Could not fetch price info, please try after some time.';
                    this.notifyType = 'error';
                }).finally(() => this.loadingPrice = false)
            },
            resetState(){
                this.step = 1;
                this.dialog = true;
                this.notifyType =  null;
                this.message =  '';
                this.logStatus = '';
                this.logMessage = '';
                this.loggedId = null;
                this.proRatePrice = '';
            },
            logSubscriptionChange() {
                if (this.invalidVisaCart) {
                    this.logStatus = 'invalid_card';
                    this.logMessage = 'Credit card information is not available or invalid card';
                }
                const request = {
                    orderId: this.item.parent_increment_id,
                    documentId: this.documentId,
                    actionTriggered: this.actionTriggered,
                    priorValue: this.item.licences,
                    newValue: this.renewalLicenses,
                    actionChangeCost: this.getProRatePrice,
                    renewalDate:  Moment(this.item?.expiration).format('ll'),
                    renewalQuantity: this.renewalLicenses,
                    renewalAmount: this.getPrice,
                    renewalDuration: this.displayBillingType(),
                    loggedId: this.loggedId,
                    status: this.logStatus,
                    message: this.logMessage,
                    userId: this.item?.userId
                }
                BillingApi.logSubscriptionChange(request)
                    .then((response) => {
                        if (response.data.success) {
                            this.loggedId = response.data.loggedId;
                        }
                    })
            }
        },
        created(){
            EventBus.listen('license-quantity-update-dialog', (data) => {
                this.resetState();
                this.item = data;
                this.emptyCart();
                this.setFrequency(data.subscription_type);
                this.setQuantity(data.licences);
                this.getDocInfoWithPrice(data.sku);
            });
        }
    }
</script>
