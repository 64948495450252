<template>
    <v-dialog
        v-model="modal"
        max-width="600"
        scrollable>
        <v-card :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`">
            <v-card-title class="pa-4 pb-2">
                <v-row dense>
                    <v-col cols="auto">
                        <v-icon
                            class="mt-n2"
                            color="accent2">
                            mdi-creation
                        </v-icon>
                    </v-col>
                    <v-col>
                        <h2 class="fs-20 font-weight-bold text-break">
                            {{ title ? title : 'Add to Cart'}}
                        </h2>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            small
                            icon
                            class="mt-n2"
                            @click="closeModal">
                            <v-icon
                                color="accent">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-card-text v-if="modalLoading">
                <v-row>
                    <v-col>
                        <loading-linear>
                        </loading-linear>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text
                v-else
                id="addToCartText"
                class="px-4">
                <alert-bar
                    class="mt-2"
                    :message="message"
                    :notification-type="type"
                    @dismiss="[message = null, type = null]">
                </alert-bar>
                <v-row>
                    <v-col>
                        <h4 class="mt-1">
                            Billing Frequency
                        </h4>
                        <v-btn-toggle
                            class="mt-2 rounded"
                            v-model="frequency"
                            tile
                            active-class="primary white--text">
                            <v-btn
                                outlined
                                v-if="availableFrequencies.includes('M')"
                                :class="{'primary--text': frequency !== 'M'}"
                                :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                                value="M">
                                Monthly
                            </v-btn>
                            <v-btn
                                outlined
                                v-if="availableFrequencies.includes('B')"
                                :class="{'primary--text': frequency !== 'B'}"
                                :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                                value="B">
                                Annually
                            </v-btn>
                            <v-btn
                                outlined
                                v-if="availableFrequencies.includes('C')"
                                :class="{'primary--text': frequency !== 'C'}"
                                :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                                value="C">
                                3-Year
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h4>
                            Number of Licenses
                        </h4>
                        <v-row
                            class="my-1"
                            align="center"
                            dense>
                            <v-col cols="auto">
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    :disabled="quantity === 1"
                                    @click="decrementQuantity">
                                    <v-icon large>
                                        mdi-minus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-text-field
                                    outlined
                                    v-model="quantity"
                                    hide-details
                                    dense
                                    style="max-width: 50px;">
                                </v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    @click="quantity++">
                                    <v-icon large>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-radio-group
                    class="mt-0"
                    v-model="options">
                    <v-row
                        dense
                        align="center">
                        <v-col cols="auto">
                            <h4>
                                Premium Tier
                            </h4>
                        </v-col>
                        <v-col v-if="isMember">
                            <v-chip
                                rounded
                                small
                                color="primaryLight primary--text font-weight-bold">
                                Member Pricing Applied
                            </v-chip>
                        </v-col>
                    </v-row>
                    <p
                        v-if="collectionBundle"
                        class="mb-0 mt-2">
                        Only available as a part of following collection or Premium Complete
                    </p>
                    <template v-for="(item, key) in selection">
                        <v-card
                            @click="options = item.sku"
                            outlined
                            class="my-1"
                            :class="{'primaryLight': item.sku === options}"
                            :style="item.sku === options ? `border: 2px solid ${$vuetify.theme.themes.light.primary}!important;` : ''">
                            <v-card-text class="py-1 px-2">
                                <v-row dense>
                                    <v-col cols="auto">
                                        <v-radio
                                            :value="item.sku">
                                        </v-radio>
                                    </v-col>
                                    <v-col>
                                        <h4 class="fs-16">
                                            {{ item.title }}
                                        </h4>
                                        <p class="mb-0">
                                            {{ item.description }}
                                        </p>
                                        <a
                                            v-if="item.learnMore"
                                            class="text-decoration-underline"
                                            target="_blank"
                                            :href="item.learnMore">
                                            Learn more
                                        </a>
                                    </v-col>
                                    <v-col
                                        class="text-right"
                                        cols="auto">
                                        <v-progress-circular
                                            v-if="loading[item.key]"
                                            indeterminate
                                            size="18"
                                            width="2"
                                            color="primary">
                                        </v-progress-circular>
                                        <template v-else>
                                            <template v-if="item.prices">
                                                <h4 class="fs-16">
                                                    ${{ item.pricePerLicense | formatPrice }} / license
                                                </h4>
                                                <v-chip
                                                    v-if="item.bestValue"
                                                    color="accent2Light"
                                                    small
                                                    class="mt-2 px-2">
                                                    <span class="font-weight-bold">
                                                        <template v-if="completeSavingsEnabled">
                                                            15% Off During Trial
                                                        </template>
                                                        <template v-else>
                                                            Best Value
                                                        </template>
                                                    </span>
                                                </v-chip>
                                            </template>
                                            <h4
                                                v-else
                                                class="fs-16">
                                                Price Unavailable
                                            </h4>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-radio-group>
                <p class="mb-0">
                    For orders without a credit card or for Enterprise and Custom Solutions, contact our sales team (<a :href="`mailto:${this.$getConst('sales_email_address')}`" title="Contact Digital Codes Sales">{{ this.$getConst('sales_email_address') }}</a>).
                </p>
            </v-card-text>
            <v-divider>
            </v-divider>
            <v-card-actions>
                <v-container class="py-0 px-1">
                    <v-sheet
                        v-if="savingsText.length"
                        class="rounded py-0 px-2 py-1 my-1"
                        color="accent2Light">
                        <v-row
                            dense>
                            <v-col cols="auto">
                                <v-icon
                                    color="accent2"
                                    class="mt-n1">
                                    mdi-currency-usd
                                </v-icon>
                            </v-col>
                            <v-col>
                                <h4 class="font-weight-bold">
                                    Discounts Applied:
                                </h4>
                                <ul>
                                    <li
                                        v-for="(item, key) in savingsText"
                                        :key="key">
                                        {{ item.discount }}% {{ item.label }}
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-sheet>
                    <v-row>
                        <v-col>
                            <h2>
                                Total
                            </h2>
                        </v-col>
                        <v-col cols="auto">
                            <h2>
                                <template v-if="activeTier && activeTier.displayedPrice">
                                    ${{ activeTier.displayedPrice | formatPrice }} / {{ billingPeriodText }}
                                </template>
                                <template v-else>
                                    $0
                                </template>
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row
                        dense
                        justify="end">
                        <v-col cols="auto">
                            <v-btn
                                outlined
                                target="_blank"
                                color="primary"
                                :href="getRoute('premium_features')">
                                Learn About Premium
                            </v-btn>
                            <v-btn
                                color="accent2"
                                depressed
                                @click="addItem"
                                :disabled="loading.addToCart || !activeTier || !activeTier.displayedPrice"
                                :loading="loading.addToCart">
                                <v-icon>
                                    mdi-cart-outline
                                </v-icon>
                                Add to Cart
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {CartApi} from '../../api/CartApi';
    import AlertBar from "../notifications/AlertBar.vue";
    import {AppParameters} from "../../AppParameters";
    import {TitlesApi} from "../../api/Titles";
    import LoadingLinear from "../loaders/LoadingLinear.vue";

    const singleKey = 'single'
    const collectionKey = 'collection'
    const completeKey = 'complete'

    export default {
        name: 'AddToCartModal',
        components: {LoadingLinear, AlertBar},
        data() {
            return {
                modal: false,
                type: null,
                message: null,
                title: null,
                frequency: 'M',
                quantity: 1,
                options: 0,
                loading: {
                    [singleKey]: false,
                    [collectionKey]: false,
                    [completeKey]: false,
                    addToCart: false
                },
                modalLoading: false,
                selection: [],
                newSelection: [],
                availableFrequencies: [
                    'M',
                    'B',
                    'C'
                ],
                collectionBundle: false,
                isMember: false,
                trialDiscountEnabled: AppParameters.premium_convert_discount_enabled,
                trialDiscount: AppParameters.discount_value_in_percent
            }
        },
        computed: {
            billingPeriodText() {
                switch (this.frequency) {
                    case 'M':
                        return 'Month';
                    case 'B':
                        return 'Year';
                    case 'C':
                        return '3-Year';
                }
            },
            hasVolumeDiscounts() {
                return this.selection.some(item => item.hasVolumeDiscounts);
            },
            activeTier() {
                return this.selection.find(item => item.sku === this.options);
            },
            completeSavingsSelected() {
                return this.completeSavingsEnabled &&
                    this.activeTier &&
                    this.activeTier.sku === this.$getConst('premiumCompleteSku')
            },
            completeSavingsEnabled() {
                return this.trialDiscountEnabled &&
                    this.hasActiveTrial;
            },
            selectedSavings() {
                const selectedItem = this.selection.find(item => item.sku === this.options);
                if (!selectedItem || !selectedItem.prices) return null;

                // Find the price tier for a single license in the monthly frequency as the base comparison
                const singleLicenseMonthlyPriceInfo = selectedItem.prices['M'] ? selectedItem.prices['M'].find(price => price.qty === 1) : null;
                // Find the price tier for a single license in the selected frequency
                const singleLicenseSelectedFrequencyPriceInfo = selectedItem.prices[this.frequency] ? selectedItem.prices[this.frequency].find(price => price.qty === 1) : null;

                if (!singleLicenseMonthlyPriceInfo || !singleLicenseSelectedFrequencyPriceInfo) return null;

                let totalCostForSingleLicenseSelectedFrequency = singleLicenseSelectedFrequencyPriceInfo.price;
                let totalCostForSingleLicenseIfBilledMonthly = singleLicenseMonthlyPriceInfo.price;

                let savings = 0;
                if (this.frequency === 'B') { // Annual
                    totalCostForSingleLicenseIfBilledMonthly *= 12; // 12 months
                } else if (this.frequency === 'C') { // 3-Year
                    totalCostForSingleLicenseIfBilledMonthly *= 36; // 36 months
                }

                // Calculate savings based on the cost of a single license
                if (totalCostForSingleLicenseIfBilledMonthly > totalCostForSingleLicenseSelectedFrequency) {
                    savings = ((totalCostForSingleLicenseIfBilledMonthly - totalCostForSingleLicenseSelectedFrequency) / totalCostForSingleLicenseIfBilledMonthly) * 100;
                }

                return savings.toFixed(0); // Return savings as a percentage
            },
            savingsText() {
                const activeTier = this.activeTier;
                let savings = [];
                if (activeTier) {
                    if (this.completeSavingsSelected) {
                        savings.push({
                            discount: this.trialDiscount,
                            label: 'Premium Complete Trial Discount (converting before trial ends)'
                        });
                    }
                    if (this.isMember) {
                        savings.push({
                            discount: 25,
                            label: 'ICC Membership Discount Applied'
                        });
                    }
                    if (this.selectedSavings > 0) {
                        savings.push({
                            discount: this.selectedSavings,
                            label: 'Billing Frequency Discount Applied (versus monthly)'
                        });
                    }
                    if (activeTier.savingsPercentage > 0) {
                        savings.push({
                            discount: activeTier.savingsPercentage,
                            label: 'License Quantity Discount Applied (versus single license)'
                        })
                    }
                }
                return savings
            }
        },
        watch: {
            frequency: 'updatePrices',
            quantity(newValue, oldValue) {
                if (newValue < 1) {
                    this.quantity = 1;
                } else if (!newValue) {
                    this.quantity = oldValue;
                }
                this.updatePrices()
            },
        },
        methods: {
            async openModal(data) {
                // If the data is a reference content, fetch the document info.
                if (data.getTitle && data.documentId) {
                    this.modal = true;
                    this.modalLoading = true;
                    const response = await TitlesApi.getTitleInfo(data.documentId);
                    data = response.data;
                    this.modalLoading = false;
                }

                this.collectionBundle = false
                this.selection = [];
                if (data) {
                    this.options = data.sku
                    this.title = this.getBookTitle(data)
                    if (!this.isBundledPremiumOnlyBook(data)) {
                        this.selection.push({
                            key: singleKey,
                            heading: 'Premium Single',
                            sku: data.sku,
                            title: this.getBookTitle(data),
                            description: 'Just this title',
                            prices: null,
                        })
                        this.getProductPrice(data.sku)
                    } else {
                        this.collectionBundle = true
                        this.options = this.$getConst('premiumCompleteSku')
                    }
                    if (data.part_of_complete) {
                        this.selection.push({
                            key: completeKey,
                            heading: 'Premium Complete',
                            sku: this.$getConst('premiumCompleteSku'),
                            title: 'Premium Complete',
                            description: `${this.premiumCompleteTitlesCount()}+ titles including this title`,
                            prices: null,
                            bestValue: true,
                            learnMore: this.getRoute('collections_landing', {documentSlug: 'premium-complete'})
                        })
                        this.getProductPrice(this.$getConst('premiumCompleteSku'), completeKey)
                    }
                    if (data.collections && data.collections.length) {
                        // Make sure the collection is not PC.
                        const collection = this.filterCollectionPrices(data.collections)[0];
                        if (collection) {
                            this.selection.push({
                                key: collectionKey,
                                heading: 'Premium Collection',
                                sku: collection.sku,
                                title: this.getBookTitle(collection),
                                description: '',
                                prices: null,
                                learnMore: collection.link
                            })
                            this.getProductPrice(collection.sku, collectionKey)
                        }
                    }
                }
                this.modal = true;
            },
            filterCollectionPrices(collections) {
                // Todo:  For now we are just hiding a few collections but we need to make this more dynamic
                const excludedSkus = [
                    this.$getConst('premiumCompleteSku'),
                    '8705ACM',
                    '8950A890'
                ];
                return collections.filter(item => !excludedSkus.includes(item.sku));
            },
            async getProductPrice(sku, type = singleKey) {
                this.$set(this.loading, type, true);
                try {
                    const response = await CartApi.getProductPrice(sku);
                    if (response && response.data) {
                        this.isMember = response.data.is_member
                        let prices = response.data.is_member ? response.data.member[sku] : response.data.general[sku];
                        this.availableFrequencies = Object.keys(prices);
                        this.frequency = this.availableFrequencies.some(frequency => frequency === this.frequency) ? this.frequency : this.availableFrequencies[0];
                        this.selection = this.selection.map(item => {
                            if (item.key === type) {
                                const hasVolumeDiscounts = Object.values(prices).some(price => price.length > 1); // Check if any frequency has more than one tier, indicating volume discounts
                                return { ...item, prices: prices, displayedPrice: null, hasVolumeDiscounts };
                            }
                            return item;
                        });
                        this.updatePrices();
                    }
                } catch (error) {
                    if (type === singleKey) {
                        this.showError(`An error occurred while fetching the price information for item ${sku}.  Please try again later or contact support.`)
                    } else {
                        this.selection = this.selection.filter(item => item.key !== type);
                    }
                } finally {
                    this.$set(this.loading, type, false);
                }
            },
            updatePrices() {
                this.selection = this.selection.map(item => {
                    if (item.prices && item.prices[this.frequency]) {
                        const priceOptions = item.prices[this.frequency];

                        // Sort priceOptions by qty in descending order to ensure the correct tier is found
                        priceOptions.sort((a, b) => b.qty - a.qty);

                        // Find the most favorable price tier for the selected quantity
                        let applicablePrice = priceOptions.find(option => this.quantity >= option.qty) || priceOptions[priceOptions.length - 1];

                        // Calculate the total displayedPrice based on the tier's price and selected quantity
                        let totalCost = applicablePrice.price;
                        if (this.completeSavingsEnabled && item.key === completeKey) {
                            totalCost = totalCost * (1 - this.trialDiscount / 100);
                        }
                        const totalDisplayedPrice = (totalCost * this.quantity).toFixed(2); // Total price for all licenses

                        // Calculate price per license
                        const pricePerLicense = (totalDisplayedPrice / this.quantity).toFixed(2);

                        // Calculate the savings percentage
                        // Assuming the last element in priceOptions is the base price (for 1 license)
                        const basePrice = priceOptions[priceOptions.length - 1].price;
                        const savingsPercentage = ((basePrice - applicablePrice.price) / basePrice * 100).toFixed(0);

                        item.displayedPrice = totalDisplayedPrice;
                        item.pricePerLicense = pricePerLicense;
                        item.savingsPercentage = savingsPercentage; // Add this line to include savingsPercentage in the item
                    } else {
                        // If there's no price information for the selected frequency
                        item.displayedPrice = null;
                        item.pricePerLicense = null;
                        item.savingsPercentage = null;
                    }
                    return item;
                });
            },
            async addItem() {
                const data = {
                    'quantity': this.quantity,
                    'sku': this.options,
                    'subscription_type': this.frequency
                };
                if (this.isActiveUser) {
                    this.loading.addToCart = true;
                    try {
                        await CartApi.addToCart(data);
                        EventBus.fire('set-cart-items-count', {
                            type: 'increment',
                            qty: data.quantity
                        });
                        this.closeModal();
                        this.goToRoute('cart_page')
                    } catch (error) {
                        this.showError(error.response.data.error || 'An error occurred while adding the item to your cart.')
                    } finally {
                        this.loading.addToCart = false;
                    }
                } else {
                    this.$session.set('cartItem', data);
                    EventBus.fire('openLoginModal', {
                        message: 'Login to add this item to your cart.',
                        fromCart: true
                    });
                }
            },
            showError(message) {
                this.type = 'error';
                this.message = message
                const textContainer = document.getElementById('addToCartText');
                if (textContainer) {
                    textContainer.scrollIntoView({ behavior: 'smooth' });
                }
            },
            closeModal() {
                this.modal = false;
                this.message = null;
                this.type = null;
            },
            decrementQuantity() {
                if (this.quantity > 1) {
                    this.quantity--
                }
            },
        },
        created() {
            EventBus.listen('show-premium-subscribe-dialog', (data) => this.openModal(data));
        }
    }
</script>