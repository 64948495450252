var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c(
        "v-card-title",
        {
          staticClass: "primaryLight text-center py-2",
          staticStyle: { "word-break": "normal" },
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _c("img", {
                  attrs: {
                    width: "auto",
                    height: "90",
                    src: _vm.asset(
                      require("../../images/banners/premium_answers.png")
                    ),
                    alt: "PREMIUMANSWERS",
                    title: "premiumanswers",
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c("p", [
            _vm._v(
              "\n            Premium Answers delivers a constantly expanding searchable database of questions and their carefully crafted interpretations from the ICC team of technical experts. Support for expedited custom questions is upcoming.\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.blurbs, function (item, key) {
            return _c(
              "v-row",
              { key: key, attrs: { dense: "" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("v-icon", { attrs: { color: "primary" } }, [
                      _vm._v(
                        "\n                    mdi-check\n                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-col", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item) +
                        "\n                "
                    ),
                  ]),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }