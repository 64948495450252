// TODO: merge this with ExpirableStorage
export class EasyStorage {

    /**
     * The purpose is to always return a BOOLEAN type
     * @param key
     * @return {boolean}
     */
    static has(key) {
        const value = localStorage.getItem(key);
        return value !== null;
    }

    /**
     * Get item value and then remove
     * @param {string} key
     * @return {string|null}
     */
    static getAndRemove(key) {
        const value = localStorage.getItem(key);
        localStorage.removeItem(key);
        return value;
    }

    /**
     * Remove item from localStorage, but unlike with default removeItem, return TRUE if anything was deleted
     * @param key
     * @return {boolean}
     */
    static removeItemWithStatus(key) {
        return this.getAndRemove(key) !== null;
    }

    /**
     * Returns LATEST toggle status
     * @param key
     * @return {boolean}
     */
    static toggle(key) {

        if (this.has(key)) {
            localStorage.removeItem(key);
            return false;
        } else {
            localStorage.setItem(key, "on");
            return true;
        }
    }

    static watch(key, callback, excludeCurrentDocument = true) {

        addEventListener('storage', (/** @type {StorageEvent} */ event) => {

            if (event.key === key && typeof callback === 'function') {
                callback(event.newValue);
            }

        });
    }
}
