<template>
    <div class="content-container">

        <v-row>
            <v-col cols="6">
                <h2 class="oxygen primary--text text-uppercase">Popular Publishers</h2>
            </v-col>

            <v-col cols="6" class="text-right">
                <p class="oxygen text-uppercase accent2--text font-weight-bold">
                    <a href="/codes/publisher" class="accent2--text">See More Publishers</a>
                    <v-icon class="accent2--text">mdi-chevron-right</v-icon>
                </p>
            </v-col>
        </v-row>

        <v-row class="py-2">
            <v-col class="text-center">
                <img :src="this.asset(require('../../images/homepage/popular-publishers.webp'))">
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    name: 'PopularPublishers'
}
</script>
