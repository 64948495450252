<template>
    <v-dialog
        v-model="feedbackModal"
        max-width="700px"
        :fullscreen="isMobilePort"
        scrollable>
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Thanks for taking the time to give us your Feedback
                <v-spacer></v-spacer>
                <v-icon small class="mr-2 accent2--text" @click="feedbackModal = false">close</v-icon>
            </v-card-title>
            <v-card-text>
                <v-row v-if="alertMessage">
                    <v-col>
                        <v-alert
                            light
                            :value="alertStatus"
                            :type="alertType">
                            {{ alertMessage }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-alert
                            light
                            :value="infoBox"
                            type="info">
                            Please provide your feedback below. If you would like us to follow up with you please
                            include your name and email.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Name"
                            solo
                            hide-details
                            v-model="name">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Email"
                            solo
                            hide-details
                            v-model="email">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Summary *"
                            solo
                            hide-details
                            v-model="summary">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            solo
                            hide-details
                            :rules="[() => rules.file]"
                            :error-messages="rules.message"
                            label="Attach File"
                            @click='pickFile'
                            v-model='filename'
                            prepend-icon='attach_file'>
                        </v-text-field>
                        <input
                            accept=".xls,.xlsx,.pdf,.doc,.docx,.txt,.jpeg,.jpg,.png"
                            type="file"
                            style="display: none"
                            ref="document"
                            @change="addFile">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            solo
                            hide-details
                            label="Description"
                            v-model="description"
                            rows="5">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="feedbackModal = false">
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    @click="validateSubmitForm">
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    import { GoogleRecaptchaV3 } from '../../util/GoogleRecaptchaV3Loader';
    export default {
        data() {
            return {
                feedbackModal: false,
                name: '',
                email: '',
                filename: '',
                summary: '',
                description: '',
                file: '',
                alertStatus: false,
                infoBox: true,
                alertMessage: 'Please enter a summary for your feedback.',
                alertType: 'error',
                allowedExtensions: [
                    'jpeg',
                    'jpg',
                    'png',
                    'doc',
                    'docx',
                    'xls',
                    'xlsx',
                    'pdf',
                    'txt'
                ],
                rules: {
                    file: true,
                    message: ''
                }
            }

        },
        mixins: [Mobile],
        watch: {
            summary: function () {
                if (this.summary != '') {
                    this.alertStatus = false;
                }
            }
        },
        methods: {
            pickFile() {
                this.$refs.document.click();
            },
            addFile(file) {
                let document = file ? file.target.files[0] : '';

                if (document) {
                    let fileExtension = _.toLower(document.name.split('.').pop());
                    
                    //Checking for file size max allowed 2MB
                    if (document.size > 2048 * 1024) {
                        this.rules.file = false;
                        this.rules.message = 'The file size exceeded the maximum size permitted of 2MB';

                        return;
                    }
                    //Checking for file extension
                    if(_.includes(this.allowedExtensions, fileExtension)){
                        this.filename = document.name;
                        this.file = document;
                        this.rules.file = true;
                        this.rules.message = '';
                    }else {
                       this.rules.file = false;
                       this.rules.message = 'This file format is not allowed. Allowed format "DOCX, XLS PDF, JPG/JPEG, PNG"';
                    }
                }
            },
            async validateSubmitForm(e) {
                if (this.summary == '') {
                    this.alertStatus = true;
                } else {
                    let formData = new FormData();
                    formData.append('name', this.name);
                    formData.append('email', this.email);
                    formData.append('summary', this.summary);
                    formData.append('description', this.description);
                    formData.append('file', this.file);
                    formData.append('filename', this.filename);
                    formData.append('currentUrl', window.location);
                    //Load recaptcha token
                    try {
                        let token = await GoogleRecaptchaV3.execute('upload_feedback');
                        if (token) {
                            formData.append('recaptchaAnswer', token);
                        }
                    } catch (error) {
                        console.error('Could not load captcha! Error is: ');
                        console.error(error);
                    }
                    
                    this.$http.post(Routing.generate('upload_feedback'), formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        if (response.data == 'success') {
                            this.infoBox = false;
                            this.alertStatus = true;
                            this.alertMessage = 'You have successfully submit your information.';
                            this.alertType = 'success';
                            setTimeout(() => this.resetForm(), 2000);
                        } else {
                            this.infoBox = false;
                            this.alertStatus = true;
                            this.alertMessage = 'Sorry, but we couldn\'t process your request. Captcha was not valid. Please, try again!';
                            this.alertType = 'error';
                        }
                    });

                }

            },
            resetForm() {
                this.feedbackModal = false;
                this.name = '';
                this.email = '';
                this.filename = '';
                this.summary = '';
                this.description = '';
                this.file = '';
                this.$refs.document.value = '';
                this.alertMessage = 'Please enter a summary for your feedback.';
                this.alertType = 'error';
                this.alertStatus = false;
                this.infoBox = true;
            }
        },
        created() {
            EventBus.listen('show-feedback-modal', () => this.feedbackModal = true);
        }
    }
</script>
