var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("confirm-dialog", {
    attrs: {
      "is-open": _vm.dialog,
      borderStyle: "",
      showHeaderBorder: "",
      noGrayActionArea: "",
      fullscreen: _vm.isMobilePort,
    },
    on: {
      closed: function ($event) {
        return _vm.emitClosed()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v("\n            " + _vm._s(_vm.dialogTitle) + "\n        "),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _vm.hasLicenseRemovalRequest && !_vm.invalidVisaCart
              ? _c("alert-bar", {
                  staticClass: "mt-3",
                  attrs: {
                    id: "display_license_remove_info",
                    message: `Subscription has pending change. Submitting a new change will overwrite the previously pending changes. <br />Pending change to be overwritten:
&emsp; <ul><li> Licenses quantity changes to ${_vm.getNextRenewalLicense()} licenses. Auto-renews on ${_vm.formatAutoRenewDate()} for $${_vm.getNextRenewalCost()}.</li></ul>`,
                    notificationType: "warning",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "pt-4" }, [
              _c("h3", [_vm._v("Title")]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.displayDocumentTitle)),
              ]),
            ]),
            _vm._v(" "),
            _vm.step === 1
              ? _c("license-quantity", {
                  attrs: {
                    displayBigger: "",
                    actionDisabled: _vm.invalidVisaCart,
                  },
                })
              : _c("div", { staticClass: "mt-2" }, [
                  _c("h3", [_vm._v("License Quantity")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.quantity) +
                        " licenses\n                "
                    ),
                  ]),
                ]),
            _vm._v(" "),
            _vm.quantity !== _vm.item.licences
              ? _c("p", { staticClass: "green--text darken-3" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.licenseMessage) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.savingsText.length
              ? _c(
                  "v-sheet",
                  {
                    staticClass: "rounded py-0 px-2 py-1 my-1 mb-2",
                    attrs: { color: "accent2Light" },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mt-n1",
                                attrs: { color: "accent2" },
                              },
                              [
                                _vm._v(
                                  "\n                                mdi-sale-outline\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", [
                          _c("h4", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "\n                                Discounts Applied:\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "ul",
                            _vm._l(_vm.savingsText, function (item, key) {
                              return _c("li", { key: key }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(item.discount) +
                                    "% " +
                                    _vm._s(item.label) +
                                    "\n                                "
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("h3", [_vm._v("Billing Frequency")]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(_vm.displayBillingType())),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                    Auto-renews " +
                    _vm._s(_vm.formatAutoRenewDate()) +
                    " for\n                    "
                ),
                _vm.loadingPrice
                  ? _c(
                      "span",
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            indeterminate: "",
                            size: 25,
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("span", [
                      _vm._v("$" + _vm._s(_vm._f("formatPrice")(_vm.getPrice))),
                    ]),
              ]),
              _vm._v(" "),
              _vm.quantity !== _vm.item.licences
                ? _c("p", { staticClass: "green--text darken-3 mb-0" }, [
                    _vm._v(
                      "\n                    Changed from $" +
                        _vm._s(_vm._f("formatPrice")(_vm.initialPrice)) +
                        " / " +
                        _vm._s(_vm.billingPeriodShortText) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("h3", [_vm._v("Payment Method")]),
                _vm._v(" "),
                !_vm.invalidVisaCart
                  ? _c("p", [_vm._v(_vm._s(_vm.visaCardNumber))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.invalidVisaCart
                  ? _c("alert-bar", {
                      staticClass: "mt-3",
                      attrs: {
                        id: "display_http_messages",
                        message: `Payment method information not found. <a href='${_vm.getShopManagePaymentsPage()}' target='_blank' class='black--text text-decoration-underline'>
                        Update your payment method</a> to continue.`,
                        notificationType: "warning",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.step === 2 &&
            _vm.quantity > _vm.item.licences &&
            !_vm.invalidVisaCart &&
            _vm.getProRatePrice
              ? _c("p", [
                  _vm._v(
                    "\n                You will be charged a prorated price of $" +
                      _vm._s(_vm._f("formatPrice")(_vm.getProRatePrice)) +
                      " today.\n                Your subscription will auto-renew with these changes for the full price of\n                $" +
                      _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                      " " +
                      _vm._s(_vm.formatAutoRenewDate()) +
                      ". Includes applicable volume discounts.\n            "
                  ),
                ])
              : _vm.step === 2 &&
                _vm.quantity > _vm.item.licences &&
                !_vm.invalidVisaCart &&
                _vm.isFreeTransaction
              ? _c("p", [
                  _vm._v(
                    "\n                There is no immediate charge required for the additional license(s) being added.\n                Your subscription will auto-renew for the increased quantity of licenses at the price of $" +
                      _vm._s(_vm._f("formatPrice")(_vm.getPrice)) +
                      " on " +
                      _vm._s(_vm.formatAutoRenewDate()) +
                      ".\n                Includes applicable volume discounts.\n            "
                  ),
                ])
              : _vm.step === 2 &&
                _vm.quantity < _vm.item.licences &&
                !_vm.invalidVisaCart
              ? _c("p", [
                  _vm._v(
                    "\n                No payment required. This change will take effect when your subscription auto-renews.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n                    For assistance,\n                    "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-decoration-underline",
                    attrs: {
                      href: _vm.$getConst("links")["contactSupport"],
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        please contact customer support.\n                    "
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "v-container",
              { staticClass: "py-0 px-2" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c("alert-bar", {
                          attrs: {
                            dismissible: "",
                            id: "display_http_messages",
                            message: _vm.message,
                            notificationType: _vm.notifyType,
                          },
                          on: { dismiss: _vm.dismissAlert },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.step === 2 && _vm.quantity > _vm.item.licences
                  ? _c(
                      "v-row",
                      { staticClass: "px-2", attrs: { dense: "" } },
                      [
                        _c("v-col", [
                          _c("h3", { staticClass: "fs-20" }, [
                            _vm._v("Total Price Today"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _c("h3", { staticClass: "fs-20" }, [
                            _vm.loadingProratePrice
                              ? _c(
                                  "span",
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        size: 25,
                                        color: "primary",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.getProRatePrice
                              ? _c("span", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("formatPrice")(
                                          _vm.getProRatePrice
                                        )
                                      )
                                  ),
                                ])
                              : _vm.isFreeTransaction
                              ? _c("span", [_vm._v("$0.00")])
                              : _vm._e(),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-row",
                  { staticClass: "mr-2 my-2", attrs: { justify: "end" } },
                  [
                    _vm.step === 2
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold",
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                _vm.step = 1
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Back\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2 font-weight-bold",
                        attrs: { outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.emitClosed()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Cancel\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.step == 1
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              color: "accent2",
                              disabled: _vm.isDisableReviewBtn,
                              depressed: "",
                            },
                            on: { click: _vm.reviewChanges },
                          },
                          [_vm._v("Review\n                    ")]
                        )
                      : _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold",
                            attrs: {
                              color: "accent2",
                              depressed: "",
                              disabled: _vm.isDisableSubmitAndPayBtn,
                              loading: _vm.loadingSubmit,
                            },
                            on: { click: _vm.submitAndPay },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  `Submit ${
                                    _vm.addedLicenseCount > 0 ? "and Pay" : ""
                                  }`
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }