<template>
    <div>
        <v-row>
            <v-col>
                <p class="primary--text caption mb-0">
                    My Purchases lists all Premium subscription licenses purchased through the ICC shop.
                    Individual licenses can be configured for personal use, assigned to a specific individual or made concurrent for shared access.
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels accordion>
                    <v-data-iterator
                        :items="subscriptions"
                        :items-per-page="pagination.rowsPerPage"
                        :loading="loading"
                        :single-expand="true"
                        :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                        :server-items-length="pagination.totalItems"
                        class="elevation-0 no-margin parent-row">
                        <template v-slot:item="data">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <v-row
                                        dense
                                        align="center">
                                        <v-col cols="auto">
                                            <a @click="bookLink(data.item.document_info)">
                                                <graphic
                                                    :title="data.item.document_info"
                                                    thumbnail
                                                    width="40px"
                                                    additionalClasses="mr-2">
                                                </graphic>
                                            </a>
                                        </v-col>
                                        <v-col>
                                            <a @click="bookLink(data.item.document_info)">
                                                <book-title
                                                    :title="getBookTitle(data.item.document_info)"
                                                    :print_version_edition="printEdition(data.item.document_info.print_version_edition.version)"
                                                    :printing_text="data.item.document_info.print_info">
                                                </book-title>
                                            </a>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="grey lighten-4">
                                    <v-data-table
                                        :headers="subscription_details"
                                        :items="data.item.group_licenses"
                                        hide-default-footer
                                        class="no-margin details-row">
                                        <template v-slot:item="{ item }">
                                            <tr class="green lighten-5 text-center">
                                                <td>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Licenses</p>
                                                            {{item.licenses}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Transaction Id</p>
                                                            {{item.purchase_id}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Type</p>
                                                            {{checkSubscriptionType(item.subscription_type)}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                            {{item.status}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Purchase Date</p>
                                                            {{item.purchase_date | moment("MMMM Do, YYYY")}}
                                                        </v-col>
                                                        <v-col>
                                                            <p class="caption font-weight-bold mb-0">Subscription Status</p>
                                                            {{item.expiration_date | moment("MMMM Do, YYYY")}}
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <v-btn
                                                                small
                                                                mt-2
                                                                @click="gotToMyeCodes">
                                                                {{getSubscriptionActionLabel(item.subscription_type)}}
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                        <template slot="no-data">
                            <v-card v-if="!subscriptions_loaded">
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </v-card>
                            <p
                                class="mt-4"
                                v-if="subscriptions_loaded">You do not have any Premium subscriptions.</p>
                        </template>
                    </v-data-iterator>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Licenses from '../../../inc/mixins/licenses.js';
    import Purchases from '../mixins/purchases.js'
    import Graphic from "../../../titles/cover/Graphic";

    export default {
        mixins: [Licenses, Purchases],
        components: {
            Graphic
        },
        data() {
            return {
                title_details: [
                    {text: 'Book Title', align: 'left', value: 'di.title', expand: true},
                ]
            }
        },
        mounted() {
            this.getUserAllSubscriptions();
        }
    }

</script>
