<template>
    <div class="v-card-item text note-bookmark-list-item">
        <v-row class="ma-0">
            <v-col sm="auto">
                <v-btn depressed fab small>
                    <v-icon :color="getTagColor(data.tag)">{{ getIcon() }}</v-icon>
                </v-btn>
            </v-col>
            <v-col>
                <p
                    v-if="type == 'note'"
                    class="note-list-quote font-italic pa-1 mb-0 mt-1 left">
                    "{{ data.annotation.quote }}"
                </p>
                <div
                    v-if="getDescription()"
                    class="caption cl description" v-html="getDescription()">
                </div>
                <h5 class="font-weight-regular left"><strong>Project Tag:</strong> {{ getTagName(data.tag) }}</h5>
                <h5 class="font-weight-regular left"><strong>Created By:</strong> {{ data.user.first_name }} {{ data.user.last_name }} ({{ data.user.email }})</h5>
            </v-col>
            <v-col sm="auto" :title="getTooltipMessage(data.isCodeAdmin)">
                <v-btn
                    text
                    icon
                    color="grey darken-1"
                    class="ma-1"
                    active-class="primary"
                    :disabled="!data.isCodeAdmin"
                    @click="showShareModal(data.id)">
                    <v-icon>share</v-icon>
                </v-btn>
                <v-btn
                    text
                    icon
                    color="grey darken-1"
                    class="ma-1"
                    :disabled="!data.isCodeAdmin"
                    @click="showEditModal(data)">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                    text
                    icon
                    color="grey darken-1"
                    class="ma-1"
                    :disabled="!data.isCodeAdmin"
                    @click="showDeleteModal(data)">
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                required: true,
                type: String
            },
            data: {
                required: true,
                type: Object
            }
        },
        computed: {
            tags() {
                return window.vm.$store.getters.getTags;
            }
        },
        methods: {
            getTooltipMessage(isAdmin) {
                return isAdmin ? '' : 'You can only edit or delete your own notes within concurrent connection.';
            },
            getDescription() {
                switch(this.type) {
                    case('note'):
                        return this.data.annotation.text;
                        break;
                    case('bookmark'):
                        return this.data.text;
                        break;
                }
            },
            getIcon() {
                return (this.type == 'note') ? 'border_color' : 'bookmark';
            },
            getTagColor(id) {
                let tags = _.isUndefined(this.data.isUpdate) ? this.tags : this.getTagFromStore(id);
                let tag = _.find(tags, {'id': _.toInteger(id)});
                if (tag && tag.color) {
                    return '#'+tag.color;
                }else{
                    let storeTag = this.getTagFromStore(id);
                    return (!_.isUndefined(storeTag) && !_.isUndefined(storeTag.color)) ? '#'+storeTag.color : this.$getConst('defaultTagColor');
                }
            },
            getTagName(id) {
                let tags = _.isUndefined(this.data.isUpdate) ? this.tags : this.getTagFromStore(id);
                let tag = _.find(tags, {'id': _.toInteger(id)});
                if(tag){
                    return tag.tag;
                }else{
                    let storeTag = this.getTagFromStore(id);
                    return storeTag ? storeTag.tag : 'Default';
                }
            },
            getTagFromStore(id){
                return _.find(window.vm.$store.getters.getTags, {'id': _.toInteger(id)});
            },
            showShareModal(id) {
                switch(this.type) {
                    case('note'):
                        EventBus.fire('show-modal-share-annotation-bookmark', 'annotation_'+id);
                        break;
                    case('bookmark'):
                        EventBus.fire('show-modal-share-annotation-bookmark', 'bookmark_'+id);
                        break;
                }
            },
            showEditModal(data) {
                switch(this.type){
                    case 'note':
                        EventBus.fire('note-modal', {id : data.id});
                        break;
                    case 'bookmark':
                        EventBus.fire('bookmark-modal', {id : data.id});
                        break;
                }
            },
            showDeleteModal(data) {
                switch(this.type){
                    case 'note':
                        EventBus.fire('remove-note', {
                            'id': data.id,
                            'sectionID': data.section_number,
                            'tagID': data.tag,
                            'annotation': data.annotation
                        });
                        break;
                    case 'bookmark':
                        EventBus.fire('remove-bookmark', {
                            'id' : data.id,
                            'sectionId': data.section_id
                        });
                        break;
                }
            }
        }
    }
</script>

<style lang="scss">
    .note-list-quote {
        background: #e0e0e0;
        border-radius: 5px;
    }
    .v-card-item {
        border-bottom: 1px solid #e0e0e0;
    }
    .note-bookmark-list-item {
        p {
            margin: 0;
            font-size: 12px;
        }
        .description {
            p {
                margin-bottom: 0!important;
            }
        }
    }
</style>
