var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-card-item text note-bookmark-list-item" },
    [
      _c(
        "v-row",
        { staticClass: "ma-0" },
        [
          _c(
            "v-col",
            { attrs: { sm: "auto" } },
            [
              _c(
                "v-btn",
                { attrs: { depressed: "", fab: "", small: "" } },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: _vm.getTagColor(_vm.data.tag) } },
                    [_vm._v(_vm._s(_vm.getIcon()))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", [
            _vm.type == "note"
              ? _c(
                  "p",
                  {
                    staticClass:
                      "note-list-quote font-italic pa-1 mb-0 mt-1 left",
                  },
                  [
                    _vm._v(
                      '\n                "' +
                        _vm._s(_vm.data.annotation.quote) +
                        '"\n            '
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getDescription()
              ? _c("div", {
                  staticClass: "caption cl description",
                  domProps: { innerHTML: _vm._s(_vm.getDescription()) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h5", { staticClass: "font-weight-regular left" }, [
              _c("strong", [_vm._v("Project Tag:")]),
              _vm._v(" " + _vm._s(_vm.getTagName(_vm.data.tag))),
            ]),
            _vm._v(" "),
            _c("h5", { staticClass: "font-weight-regular left" }, [
              _c("strong", [_vm._v("Created By:")]),
              _vm._v(
                " " +
                  _vm._s(_vm.data.user.first_name) +
                  " " +
                  _vm._s(_vm.data.user.last_name) +
                  " (" +
                  _vm._s(_vm.data.user.email) +
                  ")"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              attrs: {
                sm: "auto",
                title: _vm.getTooltipMessage(_vm.data.isCodeAdmin),
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  attrs: {
                    text: "",
                    icon: "",
                    color: "grey darken-1",
                    "active-class": "primary",
                    disabled: !_vm.data.isCodeAdmin,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showShareModal(_vm.data.id)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("share")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  attrs: {
                    text: "",
                    icon: "",
                    color: "grey darken-1",
                    disabled: !_vm.data.isCodeAdmin,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showEditModal(_vm.data)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("edit")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ma-1",
                  attrs: {
                    text: "",
                    icon: "",
                    color: "grey darken-1",
                    disabled: !_vm.data.isCodeAdmin,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showDeleteModal(_vm.data)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("delete")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }