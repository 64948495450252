var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "hero-container",
      class: {
        "py-16": !_vm.isMobilePort,
        "pb-8": _vm.isMobilePort,
      },
      attrs: { align: "center", justify: "center" },
    },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", order: !_vm.isMobilePort ? 1 : 2 } },
        [
          _c("h1", {
            staticClass: "font-weight-bold mb-6 oxygen",
            class: _vm.isMobilePort ? "fs-24" : "fs-36",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _vm.subtitle
            ? _c("h2", {
                staticClass: "font-weight-regular mb-6 fs-20",
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("button"),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { order: !_vm.isMobilePort ? 2 : 1 } },
        [_vm._t("image")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }