var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "accent2-top-border", attrs: { flat: "" } },
    [
      _c("img", {
        attrs: {
          width: "100%",
          src: _vm.asset(require("../../../images/pc_page/pc_computer.png")),
        },
      }),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _vm._l(_vm.info, function (item, key) {
            return _c(
              "v-row",
              { key: key, attrs: { align: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("v-icon", { attrs: { color: "accent2" } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.icon) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-col", [
                  _c(
                    "p",
                    { staticClass: "mb-0 primary--text font-weight-bold" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.text) +
                          "\n                "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", dark: "", color: "accent2" },
                      on: {
                        click: function ($event) {
                          return _vm.goToRoute("premium_features")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Learn More\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }