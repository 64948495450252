<template>
    <div
        v-if="showPremiumInsights"
        class="mb-4 px-2 premium-tag-category-item"
        :id="`tag-category-${contentId}`">
        <div class="scrollable-container">
            <v-row
            class="scrollable-row"
            dense
            align="center">
            <v-col cols="auto">
                <p
                    class="pb-0 caption mb-0 text-left black--text">
                    {{ activeTag.title }} :
                </p>
            </v-col>
            <v-col
                v-for="(item, key) in categories"
                v-if="item.active"
                :key="key"
                cols="auto">
                <v-tooltip
                    bottom
                    z-index="199"
                    max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            label
                            color="grey lighten-5"
                            class="cp"
                            @click="selectCategory(item)"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon
                                v-if="item?.icon"
                                size="20"
                                color="grey lighten-1"
                                class="mr-1">
                                mdi-{{ item.icon }}
                            </v-icon>
                            <span class="caption black--text">
                                {{ item.name }}
                            </span>
                        </v-chip>
                    </template>
                    <span>
                        {{ item.description }}
                    </span>
                </v-tooltip>
            </v-col>
        </v-row>
        </div>
    </div>
</template>

<script>
import {ApiService} from "../../../api/ApiService";

export default {
    data() {
        return {
            selectedCategory: null
        };
    },
    props: {
        contentId: {
            type: Number,
            required: true
        },
        tagType: {
            type: String,
            required: true
        },
        reportTypeShow: {
            type: String,
            required: true
        },
        categories: {
            type: Array,
            required: true,
            default: []
        },
        activeTag: {
            type:Object,
            required: true,
        },
        meta: {
            type: Object,
            required: true
        },
        isMobile: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        showPremiumInsights() {
            return this.categories.some(obj => obj.active === true)
        }
    },
    methods: {
        logCategoryClick() {
            const category = this.selectedCategory?.id;
            ApiService.logPremiumCategoryClick(category, this.contentId);
        },
        selectCategory(category) {

            this.selectedCategory = category;
            let showSubscription = '' == this.reportTypeShow && 'premiumcontent' == this.tagType;
            if (showSubscription) { //display new subscribe modal
                let categoryInfo = {
                    categoryId: category.id,
                    tagType: this.activeTag.type
                }
                EventBus.fire('show-tag-restricted-dialog', categoryInfo);
            } else {
                EventBus.fire('show-premium-tag-modal', {
                    category: category,
                    contentId: this.contentId,
                    meta: this.meta
                });
            }

            this.logCategoryClick();
        },
    },
}
</script>

<style lang="scss">
    .premium-tag-category-item {
        position: relative;
        .scrollable-container {
            overflow-x: auto;
        }
        .scrollable-row {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
        }
        .v-chip:hover, .v-chip:focus {
            color: #e0e0e0!important;
        }
    }
</style>
