var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isEnterpriseOrConcurrentActive
    ? _c("outlined-card", {
        attrs: { showDivider: false },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _c("h2", { staticClass: "text-h6 font-weight-bold" }, [
                    _vm._v("\n            Concurrent Access\n        "),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "fs-16" },
                    [
                      _c("p", [
                        _vm._v(
                          "Concurrent access lets multiple people access the same title and share notes for that title."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          label: "Concurrent Access Code",
                        },
                        model: {
                          value: _vm.accessCode,
                          callback: function ($$v) {
                            _vm.accessCode = $$v
                          },
                          expression: "accessCode",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4 mb-2 font-weight-bold",
                          attrs: { depressed: "", outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.concurrentAccessValidation()
                            },
                          },
                        },
                        [_vm._v("\n                Submit\n            ")]
                      ),
                      _vm._v(" "),
                      _vm.isPreviousCodesBusy
                        ? [
                            _c("v-progress-linear", {
                              staticClass: "mt-3",
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ]
                        : _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pa-2" },
                                _vm._l(_vm.previousCodes, function (code, key) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: key,
                                      staticClass: "ma-1",
                                      attrs: {
                                        text: "",
                                        outlined: _vm.isExpired(code),
                                        color: _vm.isExpired(code)
                                          ? "red"
                                          : "primaryLight",
                                      },
                                      on: {
                                        click: function ($event) {
                                          !_vm.isExpired(code) &&
                                            _vm.setUserProfile({
                                              type: "concurrent",
                                              metadata: code.accessCode,
                                            })
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "p",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "mb-0 primary--text",
                                                          },
                                                          "p",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                                " +
                                                            _vm._s(
                                                              code.accessCode
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                        _vm.isExpired(code)
                                                          ? _c("span", [
                                                              _vm._v(
                                                                " EXPIRED "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                                Last Used, " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    code.lastUsedTime,
                                                    "MMMM Do, YYYY"
                                                  )
                                                ) +
                                                "\n                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            right: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showConfirmation(code)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            mdi-close\n                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.confirmDialog,
                            callback: function ($$v) {
                              _vm.confirmDialog = $$v
                            },
                            expression: "confirmDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                        Please Confirm\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-card-text", [
                                _c("p", [
                                  _vm._v(
                                    "\n                            Are you sure you want to remove this code from the list?\n                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", light: "" },
                                      on: { click: _vm.cancelRemoveCode },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Cancel\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "error",
                                      on: { click: _vm.confirmRemoveCode },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Remove\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1540630063
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }