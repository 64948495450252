var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: !_vm.isExtraLarge } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v(
                          "\n                        mdi-book\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("h3", [
                      _vm._v(
                        "\n                        All Included Titles\n                    "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c("v-text-field", {
                staticClass: "white",
                attrs: {
                  dense: "",
                  clearable: "",
                  outlined: "",
                  rounded: "",
                  "hide-details": "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search across included titles",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("title-list", {
        attrs: {
          titles: _vm.titles,
          loading: _vm.loading,
          search: _vm.search,
          itemsPerPage: _vm.itemsPerPage,
        },
      }),
      _vm._v(" "),
      _vm.itemsPerPage < _vm.titles.length
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.itemsPerPage = _vm.itemsPerPage + 10
                        },
                      },
                    },
                    [
                      _vm._v("\n                Show More\n                "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          "\n                    mdi-chevron-down\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v(
                          "\n                        mdi-clipboard-text-multiple-outline\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("h3", [
                      _vm._v(
                        "\n                        Exclusive Bonus Content (" +
                          _vm._s(_vm.bonusContents.length) +
                          ")\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { right: "", color: "black" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { small: "" } },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                                info\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _c("p", { staticClass: "caption mb-0" }, [
                              _vm._v(
                                "\n                                These titles are only available as a part of the Premium Complete subscription."
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { md: "6", xl: "4" } },
            [
              _c("v-text-field", {
                staticClass: "white",
                attrs: {
                  dense: "",
                  clearable: "",
                  outlined: "",
                  rounded: "",
                  "hide-details": "",
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search across bonus content",
                },
                model: {
                  value: _vm.exclusiveSearch,
                  callback: function ($$v) {
                    _vm.exclusiveSearch = $$v
                  },
                  expression: "exclusiveSearch",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("title-list", {
        attrs: {
          titles: _vm.bonusContents,
          loading: _vm.loading,
          search: _vm.exclusiveSearch,
          itemsPerPage: _vm.itemsPerPage,
        },
      }),
      _vm._v(" "),
      _vm.itemsPerPage < _vm.bonusContents.length
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.itemsPerPage = _vm.itemsPerPage + 10
                        },
                      },
                    },
                    [
                      _vm._v("\n                Show More\n                "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          "\n                    mdi-chevron-down\n                "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _vm.recentlyAdded.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n                        mdi-update\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("h3", [
                          _vm._v(
                            "\n                        Recently Added\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", color: "black" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { small: "" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                info\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1927257384
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _c("p", { staticClass: "caption mb-0" }, [
                                  _vm._v(
                                    "\n                                The number of titles included within Premium Complete is always expanding.\n                                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "These are the most recent titles added to Premium Complete within the last 30 days."
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("title-list-dense", {
                            attrs: {
                              titles: _vm.recentlyAdded,
                              loading: _vm.loading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.excludedTitles.length > 0
            ? _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(
                              "\n                        mdi-notebook-remove-outline\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("h3", [
                          _vm._v(
                            "\n                        Available Add-on Titles (Not Included with Premium Complete)\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", color: "black" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { small: "" } },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n                                info\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1927257384
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _c("p", { staticClass: "caption mb-0" }, [
                                  _vm._v(
                                    "\n                                While Premium Complete is the largest collection of titles available\n                                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "it does not include everything that is available on Digital Codes.\n                                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "The titles listed here are not currently a part of Premium Complete."
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("title-list-dense", {
                            attrs: {
                              titles: _vm.excludedTitles,
                              loading: _vm.loading,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }