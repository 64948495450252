var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isTrialExcluded(_vm.documentInfo)
        ? _c("excluded-message", { attrs: { title: _vm.documentInfo } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.trialActive ||
      (_vm.trialActive && !_vm.documentInfo.part_of_complete)
        ? [
            _vm.documentInfo.content_type &&
            (_vm.documentInfo.content_type.name ===
              _vm.$getConst("contentTypeCollection") ||
              _vm.documentInfo.content_type.name ===
                _vm.$getConst("contentTypeComplete")) &&
            "" === _vm.examId &&
            !_vm.trialStarted &&
            !_vm.hasCompleteSubscription
              ? _c("free-trial-button")
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isPremiumConvertDiscountActive &&
      _vm.trialActive &&
      _vm.documentInfo.sku === this.$getConst("premiumCompleteSku")
        ? [
            _c(
              "v-alert",
              {
                staticClass: "mb-5 pl-0 caption dollar-class",
                attrs: {
                  outlined: "",
                  type: "success",
                  color: "primary",
                  icon: "mdi-currency-usd",
                  prominent: "",
                },
              },
              [
                _c(
                  "b",
                  {
                    staticStyle: { "font-size": "15px" },
                    attrs: { color: "primary" },
                  },
                  [_vm._v("Save " + _vm._s(_vm.activeDiscountVal) + "%")]
                ),
                _vm._v("\n                on your initial billing period of "),
                _c("b", [_vm._v("Premium Complete")]),
                _vm._v(
                  "\n                when you subscribe before your trial period ends.*\n                Once you complete the purchase, your "
                ),
                _c("b", [_vm._v("Premium Complete")]),
                _vm._v(
                  "\n                subscription will be activated and the trial period will come to an end.\n        "
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.getIsSaleableProduct
        ? [
            _c(
              "v-card",
              { staticClass: "accent2-top-border mb-5", attrs: { flat: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "pt-4 pr-0", attrs: { cols: "auto" } },
                      [
                        _c("img", {
                          staticClass: "float-left ml-3",
                          attrs: {
                            width: "50%",
                            src: _vm.asset(
                              require("../../../images/icons/" +
                                _vm.getAccessLevelImage(
                                  _vm.documentInfo.content_type
                                )),
                              "default"
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-col", { staticClass: "pl-0 pb-0" }, [
                      _c("h4", { staticClass: "float-left" }, [
                        _vm._v("Digital Codes Premium Subscription Offerings"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    (!_vm.trialActive && _vm.hasPremiumAccess) ||
                    (_vm.trialActive && _vm.documentInfo.access_outside_trial)
                      ? _c("vertical-pricing-info")
                      : _vm._e(),
                    _vm._v(" "),
                    _c("vertical-pricing", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.hasPremiumAccess ||
                            _vm.showPriceComponent ||
                            (_vm.trialActive &&
                              !_vm.documentInfo.access_outside_trial),
                          expression:
                            "!hasPremiumAccess ||\n                    showPriceComponent ||\n                    (trialActive && !documentInfo.access_outside_trial)",
                        },
                      ],
                      attrs: {
                        documentInfo: _vm.documentInfo,
                        isSingle: _vm.isSingle,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-card",
              { staticClass: "accent2-top-border mb-5", attrs: { flat: "" } },
              [
                _c("v-card-text", { staticClass: "pa-4" }, [
                  _c("p", [
                    _vm._v(
                      "\n                The ICC Member Benefit Collection is an exclusive offering to ICC dues paying members only and is assigned alongside a membership transaction.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "mb-2 primary--text font-weight-bold" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getIccMembershipUrl(),
                            target: "_blank",
                          },
                        },
                        [_vm._v("Learn About Membership")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "mb-0 primary--text font-weight-bold" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getIccMembershipApplicationUrl(),
                            target: "_blank",
                          },
                        },
                        [_vm._v("Apply for Membership")]
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }