<template>
    <v-row
        class="hero-container"
        :class="{
            'py-16': !isMobilePort,
            'pb-8': isMobilePort
        }"
        align="center"
        justify="center">
        <v-col
            cols="12"
            sm="6"
            :order="!isMobilePort ? 1 : 2">
            <h1
                :class="isMobilePort ? 'fs-24' : 'fs-36'"
                class="font-weight-bold mb-6 oxygen"
                v-html="title">
            </h1>
            <h2
                v-if="subtitle"
                class="font-weight-regular mb-6 fs-20"
                v-html="subtitle">
            </h2>
            <slot name="button">
            </slot>
        </v-col>
        <v-col :order="!isMobilePort ? 2 : 1">
            <slot name="image">
            </slot>
        </v-col>
    </v-row>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';

    export default {
        name: 'HeroBannerNew',
        props: {
            title: {
                required: true,
                type: String
            },
            subtitle: {
                required: false,
                type: String
            },
            button: {
                required: false,
                type: Object
            }
        },
        mixins: [
            Mobile
        ]
    }
</script>

<style
    lang="scss"
    scoped>
    .hero-container {
        img {
            max-width: 100%;
        }
    }
</style>
