<template>
    <div>
        <premium-answers-header>
        </premium-answers-header>
        <v-divider>
        </v-divider>
        <div class="grey lighten-5">
            <v-container class="content-container pt-0">
                <account-access-expand>
                </account-access-expand>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col class="pt-0"
                                :class="isMobilePort && 'px-0'">
                                <search-questions
                                    :filters="filters"
                                    @enterClicked="enterClicked"
                                    @clearFilters="clearFilters">
                                </search-questions>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2 class="font-weight-regular mb-3">
                                    {{paHeading}}
                                </h2>
                                <answer-list
                                    :loading="loading"
                                    :premium-answers="premiumAnswers"
                                    :lazy-loading="lazyLoading"
                                    :show-more="totalResults > filters.limit && totalResults !== premiumAnswers.length"
                                    :page="page"
                                    @titleClick="(data) => filters.documentIds = [data]"
                                    @topicClick="(data) => filters.topicIds = [data]"
                                    @nextPage="page++">
                                </answer-list>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                       cols="12"
                       sm="3">
                        <what-is-premium-answers>
                        </what-is-premium-answers>
                        <recently-viewed-questions class="mt-3">
                        </recently-viewed-questions>
                        <DidYouKnow class="mt-6"/>
                        <premium-answer-disclaimer
                            :viewPage="false"
                            class="mt-3">
                        </premium-answer-disclaimer>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
    import WhatIsPremiumAnswers from "../../components/premium-answers/WhatIsPremiumAnswers.vue";
    import SearchQuestions from "../../components/premium-answers/SearchQuestions.vue";
    import RecentlyViewedQuestions from "../../components/premium-answers/RecentlyViewedQuestions.vue";
    import AnswerList from "../../components/premium-answers/AnswerList.vue";
    import AccountAccessExpand from "../../components/expands/AccountAccessExpand.vue";
    import Mobile from "../../components/inc/mixins/mobile";
    import {PremiumAnswersApi} from "../../api/PremiumAnswers";
    import DidYouKnow from "../../components/premium-answers/DidYouKnow.vue";
    import PremiumAnswerDisclaimer from "../../components/premium-answers/PremiumAnswerDisclaimer.vue";
    import PremiumAnswersHeader from "../../components/premium-answers/PremiumAnswersHeader.vue";

    export default {
        name: 'PremiumAnswers',
        data() {
            return {
                loading: true,
                lazyLoading: false,
                page: 1,
                filters: {
                    search: '',
                    documentIds: [],
                    topicIds: [],
                    limit: 10
                },
                getPremiumAnswersDebounced: null,
                premiumAnswers: [],
                totalResults: 0,
                paHeading: 'Questions and Answers Database',
                checkEnterClicked: false
            }
        },
        mixins: [
            Mobile
        ],
        components: {
            PremiumAnswersHeader,
            AccountAccessExpand,
            AnswerList,
            RecentlyViewedQuestions,
            SearchQuestions,
            WhatIsPremiumAnswers,
            DidYouKnow,
            PremiumAnswerDisclaimer
        },
        watch: {
            page(value) {
                if (value !== 1) {
                    this.getPremiumAnswersDebounced(true)
                }
            }
        },
        methods: {
            async getPremiumAnswers(append = false) {
                this.loading = !append
                this.totalResults = 0
                this.lazyLoading = true
                const filters = Object.assign({}, this.filters, { page: this.page })
                const results = await PremiumAnswersApi.getPremiumAnswers(filters)
                if (!append) {
                    this.premiumAnswers = results.data.results
                } else {
                    this.premiumAnswers.push(...results.data.results)
                }
                this.totalResults = results.data.totalResults
                this.loading = false
                this.lazyLoading = false
                if ((this.checkEnterClicked && filters.search)
                    || filters.documentIds.length
                    || filters.topicIds.length) {
                    this.paHeading = `${this.totalResults} result${this.totalResults > 1 ? 's from Questions and Answers Database' : ''}`;
                } else {
                    this.paHeading = 'Questions and Answers Database';
                }
            },
            checkParams() {
                const urlParams = new URLSearchParams(window.location.search)
                let params = Object.fromEntries(urlParams.entries())

                if (params.page !== undefined) {
                    this.page = params.page
                }
                if (params.topic !== undefined) {
                    this.filters.topicIds = [params.topic]
                }
                if (params.document !== undefined) {
                    this.filters.documentIds = [params.document]
                }
                window.history.replaceState(null, '', window.location.pathname)
            },
            enterClicked() {
                this.premiumAnswers = [];
                this.page = 1;
                this.getPremiumAnswers();
                this.checkEnterClicked = true;
            },
            clearFilters() {
                this.filters = {
                    search: '',
                    documentIds: [],
                    topicIds: [],
                    limit: 10
                }
                this.page = 1
            }
        },
        created() {
            this.checkParams()
        },
        mounted() {
            this.getPremiumAnswersDebounced =  _.debounce((data) => this.getPremiumAnswers(data), 500);
            this.getPremiumAnswers()

            // when either filter changed - update immediately
            this.$watch(() => [this.filters.documentIds, this.filters.topicIds], (newValue) => {
                this.loading = true
                this.premiumAnswers = [];
                this.page = 1;
                this.getPremiumAnswersDebounced();

            });
        }
    }
</script>
