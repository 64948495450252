var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto" } },
            [
              _c("graphic", {
                attrs: {
                  title: _vm.documentInfo,
                  thumbnail: _vm.documentInfo.is_astm,
                  width: "60px",
                  minHeight: "240px",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { dense: "", "align-content": "space-between" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pl-0",
                      attrs: { cols: _vm.isActiveAdmin() ? 8 : 12 },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "text-h5 font-weight-bold",
                          class: { "subtitle-1": _vm.isMobileAndTablet },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.getBookTitle(
                                  _vm.documentInfo,
                                  _vm.documentInfo.is_astm ? false : true
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isActiveAdmin() &&
                  _vm.documentInfo &&
                  _vm.documentInfo.content_type &&
                  _vm.documentInfo.content_type.name === "Premium Complete"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { cols: "4", align: "center" },
                        },
                        [
                          _c(
                            "v-btn",
                            _vm._b(
                              { attrs: { color: "primary" } },
                              "v-btn",
                              _vm.btnSize,
                              false
                            ),
                            [
                              !_vm.csvLoading
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportToCSV()
                                        },
                                      },
                                    },
                                    [_vm._v("Export Titles List")]
                                  )
                                : _c("v-progress-circular", {
                                    attrs: {
                                      size: 20,
                                      indeterminate: "",
                                      color: "white",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.count
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "p",
                          {
                            staticClass: "primary--text mb-0 font-weight-bold",
                          },
                          [
                            _vm._v(
                              "\n                        Collection includes access to content and features across " +
                                _vm._s(_vm.count) +
                                " titles\n                    "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSingle &&
      "" === _vm.examId &&
      !_vm.isPremiumSubscribed(_vm.documentInfo.sku)
        ? _c("access-level-message", {
            staticClass: "mt-4",
            attrs: {
              id: "access_level_message",
              documentInfo: _vm.documentInfo,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.documentInfo.is_astm
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        text: "",
                        color: "cdpBlue",
                        border: "left",
                        icon: "mdi-information-outline",
                      },
                    },
                    [
                      _c("p", { staticClass: "mb-0 accent--text" }, [
                        _vm._v(
                          "\n                    The version on Digital Codes is the specific version referenced within the 2021 I-Codes.\n                "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.documentInfo.adoption_notes
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("h3", { staticClass: "text-h6 font-weight-bold mb-3" }, [
                  _vm._v("Adoption Note"),
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "mb-0 text-body-1",
                  domProps: {
                    innerHTML: _vm._s(_vm.documentInfo.adoption_notes),
                  },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.documentInfo.description
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("h3", { staticClass: "text-h6 font-weight-bold mb-3" }, [
                  _vm._v("About This Title"),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-body-1",
                  domProps: { innerHTML: _vm._s(_vm.documentInfo.description) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }