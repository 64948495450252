<template>
    <premium-tool-header
        title="Premium Answers"
        sub-title="Real questions answered by our expert team">
        <template v-slot:avatar>
            <img
                width="30"
                height="102"
                :src="asset(require('../../images/icons/premium_answers_icon.svg'))"
                alt="FEEDBACK"
                title="Feedback">
        </template>
    </premium-tool-header>
</template>

<script>
    import PremiumToolHeader from "../headers/PremiumToolHeader.vue";

    export default {
        name: 'PremiumAnswersHeader',
        components: {PremiumToolHeader}
    }
</script>
