var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-container" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("h2", { staticClass: "oxygen primary--text text-uppercase" }, [
              _vm._v("Popular Publishers"),
            ]),
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-right", attrs: { cols: "6" } }, [
            _c(
              "p",
              {
                staticClass:
                  "oxygen text-uppercase accent2--text font-weight-bold",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "accent2--text",
                    attrs: { href: "/codes/publisher" },
                  },
                  [_vm._v("See More Publishers")]
                ),
                _vm._v(" "),
                _c("v-icon", { staticClass: "accent2--text" }, [
                  _vm._v("mdi-chevron-right"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "py-2" },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("img", {
              attrs: {
                src: this.asset(
                  require("../../images/homepage/popular-publishers.webp")
                ),
              },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }