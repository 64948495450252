<template>
    <div>
        <template v-for="(item, index) in links">
            <v-list-item
                v-if="item.active"
                :key="index"
                :href="!item.checkAccess || (item.checkAccess && isActiveUser) ? item.link : null"
                @click="$emit('updateComponent', item.component ? item.component : null)">
                <v-list-item-icon
                    v-if="!hideIcon"
                    class="mr-2">
                    <v-icon
                        v-if="item.checkAccess && !isActiveUser"
                        color="grey lighten-1">
                        mdi-lock-outline
                    </v-icon>
                    <v-icon
                        v-else
                        :color="item.iconColor ? item.iconColor : 'primary'">
                        {{ item.icon }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title
                    class="fs-16 font-weight-bold"
                    :class="item.checkAccess && !isActiveUser ? 'grey--text lighten-1' : 'accent--text'">
                    {{item.title}}
                    <v-chip
                        v-if="item.chip"
                        class="ml-2 primary--text cp"
                        small
                        color="primaryLight">
                        {{ item.chip }}
                    </v-chip>
                </v-list-item-title>
                <v-list-item-icon>
                    <v-icon
                        v-if="item.component"
                        @click.stop.prevent="item.component"
                        @click="$emit('updateComponent', item.component ? item.component : null)"
                        color="accent">
                        mdi-chevron-right
                    </v-icon>
                </v-list-item-icon>
            </v-list-item>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'LinkListIcons',
        props: {
            links: {
                required: true,
                type: Array
            },
            hideIcon: {
                required: false,
                type: Boolean,
                default: false
            }
        }
    }
</script>
