<template>
    <a
        :href="documentInfo.link">
        <v-card
            outlined
            color="white"
            class="rounded-lg light-border-color included-title-card fill-height title-card">
            <v-card-text
                class="pa-2"
                :style="displayBorder && displayPremiumBorder ? `border: 1px solid ${borderColor}` : ''"
            >
                <v-badge
                    v-if="documentInfo.recently_added"
                    content="NEW"
                    color="primary"
                    class="title-recent-badge"
                />

                <v-row align="center">
                    <v-col
                        align-self="start"
                        cols="auto"
                        class="py-0">
                        <graphic
                            :title="documentInfo"
                            thumbnail
                            width="50px"
                            additionalStyles="max-width: 50px;">
                        </graphic>
                    </v-col>
                    <v-col class="pa-0">
                        <p
                            class="mb-0 font-weight-bold"
                            v-html="documentInfo.display_title">
                        </p>
                        <div class="mt-1 additional-details">
                            <template v-if="documentInfo.is_collection">
                                <p class="mb-0 grey--text text--darken-1">{{documentInfo.collection_item_count}} titles</p>
                            </template>
                            <template v-else-if="hasAdoptionDetails">
                                <p class="mb-0 grey--text text--darken-1">
                                    <span v-if="documentInfo.base_code_info">{{documentInfo.base_code_info.year}} {{documentInfo.base_code_info.short_title}}</span> <span v-if="documentInfo.custom_code_profile_label">{{documentInfo.custom_code_profile_label}}</span>
                                </p>
                                <p class="mb-0 grey--text text--darken-1" v-if="documentInfo.effective_date">Effective Date: {{documentInfo.effective_date}}</p>
                            </template>
                        </div>
                    </v-col>
                    <v-col cols="auto" align-self="start" class="pt-0">
                        <access-icon
                            v-if="hasAccessIcon"
                            :access-level="accessLevel">
                        </access-icon>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </a>
</template>

<script>

import Graphic from "../../titles/cover/Graphic.vue";
import Mobile from "../../inc/mixins/mobile";
import AccessIcon from "../../icons/AccessIcon.vue";

export default {
    props: {
        documentInfo: {
            type: Object,
            required: true
        },
        displayBorder:{
            required: false,
            type: Boolean,
        },
        borderColor: {
            required: false,
            type: String,
        },
        rightArrowColor: {
            required: false,
            type: String,
        },
    },
    mixins: [
        Mobile
    ],
    components: {
        AccessIcon,
        Graphic,
    },
    computed: {
        accessLevel() {

            if (this.isPremiumSubscribed(this.documentInfo.sku)) {
                return 'premiumActive';
            }

            if (this.isBasicAccessBook(this.documentInfo.content_access_level_id)) {
                return 'basic';
            }

            return 'premium';
        },
        displayPremiumBorder() {
            return this.documentInfo.content_premium === '1' && this.documentInfo.content_protection === '1';
        },
        hasAccessIcon() {
            return this.documentInfo.is_collection === false;
        },
        hasAdoptionDetails() {
            return this.documentInfo.is_collection === false;
        },
    },
}
</script>

<style lang="scss">
    .title-card {

        .v-tooltip__content {
            pointer-events: initial;
        }

        .title-recent-badge {
            position: absolute;
            left: -9px; // carefully picked random value so on mobile left edge is not cut off or overlaps with tablet columns
            top: 1px;
            line-height: 20px !important;

            .v-badge__badge {
                padding: 2px 8px;
                line-height: 16px; // box size is set to 20px so 16px line height will center the text (excluding the padding)
                font-weight: 700;
            }
        }

        .additional-details {
            p {
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }
</style>