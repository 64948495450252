import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=4b04b783&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=4b04b783&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b04b783",
  null
  
)

export default component.exports