var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tagtype === "es"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
              mouseover: function ($event) {
                _vm.mouseOn = true
              },
              mouseleave: function ($event) {
                _vm.mouseOn = false
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "es" } })],
          1
        ),
      ])
    : _vm.tagtype === "errata"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
              mouseover: function ($event) {
                _vm.mouseOn = _vm.tagTypeShow == "" ? false : true
              },
              mouseleave: function ($event) {
                _vm.mouseOn = false
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "errata" } })],
          1
        ),
      ])
    : _vm.tagtype === "premiumcontent"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "premium" } })],
          1
        ),
      ])
    : _vm.tagtype === "srcc"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
              mouseover: function ($event) {
                _vm.mouseOn = true
              },
              mouseleave: function ($event) {
                _vm.mouseOn = false
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "srcc" } })],
          1
        ),
      ])
    : _vm.tagtype === "video"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
              mouseover: function ($event) {
                _vm.mouseOn = true
              },
              mouseleave: function ($event) {
                _vm.mouseOn = false
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "video" } })],
          1
        ),
      ])
    : _vm.tagtype === "cdp"
    ? _c("span", [
        _c(
          "a",
          {
            staticClass: "tag-label",
            class: [_vm.showOnPremium, _vm.showOnPublic],
            on: {
              click: function ($event) {
                return _vm.fireModal()
              },
              mouseover: function ($event) {
                _vm.mouseOn = _vm.tagTypeShow == "" ? false : true
              },
              mouseleave: function ($event) {
                _vm.mouseOn = false
              },
            },
          },
          [_c("tag-label", { attrs: { tag: "cdp" } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }