var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {},
    [
      _c("h1", { staticClass: "oxygen font-weight-light text-center mb-8" }, [
        _vm._v(
          "\n        Simplify your Workflow and Search from Anywhere with Digital Codes Premium\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "text-uppercase text-center roboto font-weight-bold primary--text mb-8",
        },
        [
          _vm._v(
            "\n        Access the latest features and contents from your fingertips\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-10", attrs: { "justify-lg": "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column justify-center",
              attrs: { cols: "12", lg: "5" },
            },
            [
              _c(
                "ul",
                { attrs: { id: "list_benefits" } },
                _vm._l(_vm.benefitList, function (item, i) {
                  return _c(
                    "li",
                    { key: i },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "theme--light accent2--text",
                          attrs: { large: "" },
                        },
                        [_vm._v(_vm._s(item.icon))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "ml-5 roboto font-weight-bold" },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://apps.apple.com/us/app/digital-codes-premium/id1523315914",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-5",
                      attrs: {
                        src: this.asset(
                          require("../../images/homepage/download-app-store.png")
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://play.google.com/store/apps/details?id=org.iccsafe.codes",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-5",
                      attrs: {
                        src: this.asset(
                          require("../../images/homepage/get-on-google-play.png")
                        ),
                      },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "d-none d-lg-block text-center",
              attrs: { cols: "5" },
            },
            [
              _c("img", {
                attrs: {
                  src: this.asset(
                    require("../../images/pc_page/pc_mobile.png")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c("h1", { staticClass: "oxygen font-weight-light mb-5" }, [
          _vm._v("Premium Complete starting at "),
          _c("strong", { staticClass: "font-weight-bold" }, [
            _vm._v("$" + _vm._s(_vm.premiumCompletePrice)),
          ]),
          _vm._v("\n            per month"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "roboto mb-8" }, [
          _vm._v(
            "ICC Member and Quantity Discounts applied automatically during checkout"
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            !_vm.hasCompleteSubscription || _vm.trialActive
              ? _c(
                  "v-btn",
                  {
                    staticClass: "roboto mr-5",
                    attrs: {
                      "x-large": _vm.isLargeAndUp,
                      dark: "",
                      color: "accent2",
                    },
                    on: { click: _vm.premiumSubscribe },
                  },
                  [
                    _vm._v(
                      "\n                Get Premium Complete\n            "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.trialStarted && !_vm.hasCompleteSubscription
              ? _c(
                  "v-btn",
                  {
                    staticClass: "cdpBlue roboto mr-5",
                    attrs: { "x-large": _vm.isLargeAndUp, dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.goToRoute("trial_signup")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                Start Free 14-day Premium Complete Trial\n            "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                staticClass: "roboto",
                attrs: {
                  "x-large": _vm.isLargeAndUp,
                  dark: "",
                  color: "primary",
                },
                on: { click: _vm.learnMoreClicked },
              },
              [_vm._v("\n                Learn More\n            ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }