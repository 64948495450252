var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasBookmarksOrAnnotations
    ? _c(
        "v-card",
        {
          staticClass: "mt-3 notes-expansion-panel-wrapper",
          attrs: {
            id: `notes-expansion-panel-wrapper-${_vm.sectionId}`,
            outlined: "",
          },
        },
        [
          _c(
            "v-expansion-panels",
            {
              staticClass: "notes-expansion-panel",
              model: {
                value: _vm.panel,
                callback: function ($$v) {
                  _vm.panel = $$v
                },
                expression: "panel",
              },
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    { attrs: { "expand-icon": "mdi-chevron-down" } },
                    [
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v("My Notes"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    { staticClass: "pa-0" },
                    [
                      _vm._l(_vm.bookmarks, function (item) {
                        return [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                id: "bookmark-list-item-" + item.id,
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c("note-list-item", {
                                    attrs: {
                                      type: "bookmark",
                                      data: item,
                                      tags: _vm.tags,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.annotations, function (item) {
                        return [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                id: "annotation-list-item-" + item.id,
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-0" },
                                [
                                  _c("note-list-item", {
                                    attrs: {
                                      type: "note",
                                      data: item,
                                      tags: _vm.tags,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }