var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("premium-tool-header", {
    attrs: {
      title: "Premium Answers",
      "sub-title": "Real questions answered by our expert team",
    },
    scopedSlots: _vm._u([
      {
        key: "avatar",
        fn: function () {
          return [
            _c("img", {
              attrs: {
                width: "30",
                height: "102",
                src: _vm.asset(
                  require("../../images/icons/premium_answers_icon.svg")
                ),
                alt: "FEEDBACK",
                title: "Feedback",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }