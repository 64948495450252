<template>
    <div>
        <v-dialog
            v-model="checkoutError" max-width="290">
            <v-card>
                <v-card-title class="headline">Proceed To Checkout</v-card-title>
                <v-card-text>
                    Error! Sorry! We are unable to process your request. Please try again later.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        flat="flat"
                        @click="checkoutError = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="checkoutLoader" max-width="290">
            <v-card>
                <v-card-title class="headline">Proceed To Checkout</v-card-title>
                <v-card-text>
                    Please wait, you are being forwarded to the checkout page.
                </v-card-text>
                <div class="text-center py-6">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="loginModal" max-width="290">
            <v-card>
                <v-card-title class="headline">Sign In Required</v-card-title>
                <v-card-text>
                    Please <a :href="getLoginUrl()"><b>sign in</b></a> or
                    <a href="https://www.iccsafe.org/register/" target="_blank"><b>register</b></a>
                    to add items to your cart.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            flat="flat"
                            @click="loginModal = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                checkoutError: false,
                checkoutLoader: false,
                loginModal: false
            };
        },
        methods: {
            goToCheckout() {
                if (this.$store.getters.getLoggedIn !== '' && !this.checkoutLoader) {
                    this.checkoutLoader = true;
                    this.$http.get(Routing.generate('get_shop_url'))
                        .then(response => {
                            EventBus.fire('get-products');
                            this.checkoutLoader = false;
                            var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
                            if (isSafari) {
                                window.location = response.data.shopUrl + 'checkout/cart/';
                            } else {
                                window.open(response.data.shopUrl + 'checkout/cart/', '_blank');
                            }
                        });
                } else {
                    this.loginModal = true;
                }
            },
            getRoute(slug, parm = []) {
                return Routing.generate(slug, parm);
            },
        },
        created() {
            EventBus.listen('go_to_checkout', () => this.goToCheckout());
        }
    }
</script>
