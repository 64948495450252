<template>
    <div>
        <excluded-message
            v-if="isTrialExcluded(documentInfo)"
            :title="documentInfo">
        </excluded-message>
        <template v-if="!trialActive || trialActive && !documentInfo.part_of_complete">
            <free-trial-button
                v-if="documentInfo.content_type 
                    &&
                    (documentInfo.content_type.name === $getConst('contentTypeCollection') ||  
                    documentInfo.content_type.name === $getConst('contentTypeComplete')
                    )
                    && '' === examId
                    && !trialStarted
                    && !hasCompleteSubscription">
            </free-trial-button>
        </template>
        <template v-if="isPremiumConvertDiscountActive &&
            trialActive &&
            documentInfo.sku === this.$getConst('premiumCompleteSku')">
            <v-alert
                outlined
                class="mb-5 pl-0 caption dollar-class"
                type="success"
                color="primary"
                icon="mdi-currency-usd"
                prominent>
                    <b style="font-size:15px" color="primary">Save {{ activeDiscountVal }}%</b>
                    on your initial billing period of <b>Premium Complete</b>
                    when you subscribe before your trial period ends.*
                    Once you complete the purchase, your <b>Premium Complete</b>
                    subscription will be activated and the trial period will come to an end.
            </v-alert>
        </template>
        <template v-if="getIsSaleableProduct">
        <v-card
            flat
            class="accent2-top-border mb-5">
            <v-row>
                <v-col cols="auto" class="pt-4 pr-0">
                    <img
                        class="float-left ml-3"
                        width="50%"
                        :src="asset(require('../../../images/icons/'+getAccessLevelImage(documentInfo.content_type)), 'default')"/>
                </v-col>
                <v-col class="pl-0 pb-0">
                    <h4 class="float-left">Digital Codes Premium Subscription Offerings</h4>
                </v-col>
            </v-row>
            <v-card-text class="pa-0">
                <vertical-pricing-info v-if="(!trialActive && hasPremiumAccess)
                    || (trialActive && documentInfo.access_outside_trial)">
                </vertical-pricing-info>
                <vertical-pricing
                    v-show="!hasPremiumAccess ||
                        showPriceComponent ||
                        (trialActive && !documentInfo.access_outside_trial)"
                    :documentInfo="documentInfo"
                    :isSingle="isSingle">
                </vertical-pricing>
            </v-card-text>
        </v-card>
    </template>
    <template v-else>
        <v-card
            flat
            class="accent2-top-border mb-5">
            <v-card-text class="pa-4">
                <p>
                    The ICC Member Benefit Collection is an exclusive offering to ICC dues paying members only and is assigned alongside a membership transaction.
                </p>
                <p class="mb-2 primary--text font-weight-bold">
                    <a :href="getIccMembershipUrl()" target="_blank">Learn About Membership</a>
                </p>
                <p class="mb-0 primary--text font-weight-bold">
                    <a :href="getIccMembershipApplicationUrl()" target="_blank">Apply for Membership</a>
                </p>
            </v-card-text>
        </v-card>
    </template>
    </div>
</template>

<script>
    import VerticalPricingInfo from '../../cart/partials/VerticalPricingInfo.vue';
    import VerticalPricing from './../../cart/partials/vertical_pricing';
    import FreeTrialButton from './free_trial_button';
    import ExcludedMessage from "./ExcludedMessage.vue";
    import {AppParameters} from "../../../AppParameters";

    export default {
        data() {
            return {
                isSaleableProduct: true,
                showPriceComponent: false,
                isPremiumConvertDiscountActive: AppParameters.premium_convert_discount_enabled,
                activeDiscountVal: AppParameters.discount_value_in_percent,
            }
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            isSingle: {
                required: false,
                type: Boolean
            },
            examId: {
                type: String,
                required: false,
                default: ''
            },
        },
        components: {
            VerticalPricing,
            VerticalPricingInfo,
            FreeTrialButton,
            ExcludedMessage
        },
        computed: {
            hasPremiumAccess() {
                return this.isPremiumSubscribed(this.documentInfo['sku']);
            },
            getIsSaleableProduct() {
                return this.isSaleableProduct;
            }
        },
        methods: {
            getAccessLevelImage(contentType) {
                let contentTypeName = contentType?.name;
                if (contentTypeName === this.$getConst('contentTypeCollection')) {
                    return 'diamond_collection.png';
                } else if(contentTypeName === this.$getConst('contentTypeComplete')) {
                    return 'diamond_complete.png';
                } else {
                    return 'diamond_single.png';
                }
            },
            getIccMembershipUrl() {
                return this.$getConst('icc_membership_info_url');
            },
            getIccMembershipApplicationUrl() {
                return this.$getConst('icc_membership_application_url');
            }
        },
        created() {
            EventBus.listen('show-price-component', (data) => this.showPriceComponent = data);
            EventBus.listen('is-member-benefit-collection', (result) => this.isSaleableProduct = result);
        }
    }
</script>
<style>
    .dollar-class .v-alert__wrapper .v-icon {
        align-self: center;
        height: 30px;
        min-width: 30px;
        margin-left: 12px;
        font-size: 25px;
    }
</style>
