var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { id: "premiumAnswersFilter", outlined: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pb-2" },
        [
          _c("h2", [_vm._v("\n            Search Q&A Database\n        ")]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      label: "Search",
                      "append-icon": "mdi-magnify",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                      "click:append": _vm.handleSearch,
                    },
                    model: {
                      value: _vm.filters.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "search", $$v)
                      },
                      expression: "filters.search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.expand,
                      expression: "expand",
                    },
                  ],
                  attrs: { align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          clearable: "",
                          multiple: "",
                          outlined: "",
                          dense: "",
                          items: _vm.titles,
                          "item-value": "id",
                          "item-text": "title",
                          "hide-details": "",
                          loading: _vm.titlesLoading,
                          disabled: _vm.titlesLoading,
                          label: "Title",
                          "menu-props": { bottom: true, offsetY: true },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "primary--text",
                                    attrs: { small: "", color: "primaryLight" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.title) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filters.documentIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "documentIds", $$v)
                          },
                          expression: "filters.documentIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      class: !_vm.isMobilePort && "grow",
                      attrs: { cols: "12", sm: "auto" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          chips: "",
                          clearable: "",
                          multiple: "",
                          outlined: "",
                          dense: "",
                          items: _vm.topics,
                          "item-value": "id",
                          "item-text": "title",
                          "hide-details": "",
                          loading: _vm.topicsLoading,
                          disabled: _vm.topicsLoading,
                          label: "Topic",
                          "menu-props": { bottom: true, offsetY: true },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "cdpBlue--text",
                                    attrs: { small: "", color: "cdpBlueLight" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.title) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filters.topicIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "topicIds", $$v)
                          },
                          expression: "filters.topicIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: _vm.isMobilePort,
                            outlined: "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("clearFilters")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Clear filters\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobilePort
            ? _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-16 primary--text mb-0 text-decoration-underline",
                        on: {
                          click: function ($event) {
                            _vm.expand = !_vm.expand
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.expand ? "Hide filters" : "Show filters"
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }