<template>
    <v-dialog
        v-model="dialog"
        max-width="800">
        <v-card>
            <v-card-title
                class="primary white--text"
                :class="{
                    'headline': !isMobilePort,
                    'caption mobile-title': isMobilePort
                }">
                Recent Updates
                <v-spacer></v-spacer>
                <v-icon
                    small
                    class="accent2--text dark"
                    @click="dialog = false">
                    close
                </v-icon>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-tabs
                    v-if="!loading"
                    v-model="tab"
                    vertical
                    background-color="grey lighten-3"
                    active-class="primary--text grey lighten-4">
                    <v-tab
                        v-for="(item, key) in updates"
                        :key="key">
                        {{ item.title }}
                    </v-tab>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            v-for="(item, key) in updates"
                            :key="key">
                            <v-card
                                flat
                                max-height="400"
                                style="overflow-y: scroll;">
                                <v-card-text class="py-2">
                                    <v-row dense>
                                        <v-col>
                                            <p class="primary--text mb-0">
                                                {{ item.publishDate | moment("MMMM Do, YYYY") }}
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            <div v-html="item.description">
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-btn
                                                v-if="item.link"
                                                @click="item.link.href"
                                                small
                                                color="primary">
                                                {{ item.link.title }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-tabs>
                <v-row v-else>
                    <v-col class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="primary">
                        </v-progress-circular>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data: function() {
            return {
                dialog: false,
                loading: true,
                updates: [],
                tab: null
            };
        },
        mixins: [Mobile],
        methods: {
            showDialog() {
                this.dialog = true;
                this.userCount = this.$store.getters.getUpdateCount;
                this.hasUpdate = false;
                if (_.size(this.updatesData) === 0) { // if empty in store then call endpoint
                    this.getUpdates();
                } else {
                    this.loading = false;
                    this.updates = this.updatesData;
                }
            },
            getUpdates() {
                this.loading = true;
                this.$http.get(Routing.generate('get_updates')).then(
                    response => {
                        if (response.data) {
                            this.updates = response.data;
                        }
                        this.$nextTick(() => {
                            this.loading = false;
                        });
                    }
                );
            }
        },
        created() {
            EventBus.listen('show-notification-modal', () => this.showDialog());
            // Todo: WebSocket
            // notify that there is an unread update!
            this.hasUpdate = (parseInt(this.userCount) !== parseInt(this.$store.getters.getUpdateCount));
        }
    };
</script>
