var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("premium-answers-header"),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container pt-0" },
            [
              _c("account-access-expand"),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              class: _vm.isMobilePort && "px-0",
                            },
                            [
                              _c("search-questions", {
                                attrs: { filters: _vm.filters },
                                on: {
                                  enterClicked: _vm.enterClicked,
                                  clearFilters: _vm.clearFilters,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "h2",
                                { staticClass: "font-weight-regular mb-3" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.paHeading) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("answer-list", {
                                attrs: {
                                  loading: _vm.loading,
                                  "premium-answers": _vm.premiumAnswers,
                                  "lazy-loading": _vm.lazyLoading,
                                  "show-more":
                                    _vm.totalResults > _vm.filters.limit &&
                                    _vm.totalResults !==
                                      _vm.premiumAnswers.length,
                                  page: _vm.page,
                                },
                                on: {
                                  titleClick: (data) =>
                                    (_vm.filters.documentIds = [data]),
                                  topicClick: (data) =>
                                    (_vm.filters.topicIds = [data]),
                                  nextPage: function ($event) {
                                    _vm.page++
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("what-is-premium-answers"),
                      _vm._v(" "),
                      _c("recently-viewed-questions", { staticClass: "mt-3" }),
                      _vm._v(" "),
                      _c("DidYouKnow", { staticClass: "mt-6" }),
                      _vm._v(" "),
                      _c("premium-answer-disclaimer", {
                        staticClass: "mt-3",
                        attrs: { viewPage: false },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }