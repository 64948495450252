<template>
    <v-container>

        <v-row>

            <v-col cols="12" lg="6" class="d-flex align-center">
                <div>

                    <h1 class="white--text oxygen font-weight-light mb-8">
                        The Most Trusted and Authentic Source of Building Codes, Standards and More
                    </h1>

                    <h1 class="white--text oxygen font-weight-light mb-8">
                        Go Beyond the <strong class="font-weight-bold">Codes with Digital Codes Premium
                        Complete</strong>
                    </h1>

                    <p class="text-uppercase accent2--text font-weight-bold roboto">
                        Unlock all contents and features to enhance code compliance research with the most
                        comprehensive subscription platform.
                    </p>
                </div>

            </v-col>

            <v-col cols="6" class="text-center d-none d-lg-block">
                <img :src="this.asset(require('../../images/category_search.png'))">
            </v-col>

        </v-row>

        <v-row justify-lg="center">
            <v-col cols="12">

                <v-btn
                        v-if="!hasCompleteSubscription || trialActive"
                        dark
                        color="accent2"
                        :x-large="isLargeAndUp"
                        class="mr-5 roboto"
                        @click="premiumSubscribe">
                    Subscribe to Premium Complete
                </v-btn>

                <v-btn
                        v-if="!trialStarted && !hasCompleteSubscription"
                        data-qa="landing-begin-trial"
                        dark
                        :x-large="isLargeAndUp"
                        class="cdpBlue mr-5 roboto"
                        @click="trialClick">
                    Start Free 14-day Premium Complete Trial
                </v-btn>

                <v-btn
                        :x-large="isLargeAndUp"
                        class="roboto"
                        @click="learnMoreClick">
                    Learn More
                </v-btn>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import Mobile from '../inc/mixins/mobile';

export default {
    name: 'PremiumUpsell',
    mixins: [Mobile],
    methods: {
        premiumSubscribe() {
            this.updatePremiumCompleteConversionUrl().then(() => {
                this.getPremiumCompleteBook();
            });
        },
        trialClick() {
            this.gtmEvent('Sign Up', 'Free Trial', 'Homepage Trial CTA');
            this.goToRoute('trial_signup')
        },
        learnMoreClick() {
            this.gtmEvent('Product Discovery', 'Complete Features', 'Homepage Learn CTA');
            this.goToRoute('premium_features')
        }
    }
}
</script>
