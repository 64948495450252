var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { attrs: { href: _vm.documentInfo.link } },
    [
      _c(
        "v-card",
        {
          staticClass:
            "rounded-lg light-border-color included-title-card fill-height title-card",
          attrs: { outlined: "", color: "white" },
        },
        [
          _c(
            "v-card-text",
            {
              staticClass: "pa-2",
              style:
                _vm.displayBorder && _vm.displayPremiumBorder
                  ? `border: 1px solid ${_vm.borderColor}`
                  : "",
            },
            [
              _vm.documentInfo.recently_added
                ? _c("v-badge", {
                    staticClass: "title-recent-badge",
                    attrs: { content: "NEW", color: "primary" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { "align-self": "start", cols: "auto" },
                    },
                    [
                      _c("graphic", {
                        attrs: {
                          title: _vm.documentInfo,
                          thumbnail: "",
                          width: "50px",
                          additionalStyles: "max-width: 50px;",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "pa-0" }, [
                    _c("p", {
                      staticClass: "mb-0 font-weight-bold",
                      domProps: {
                        innerHTML: _vm._s(_vm.documentInfo.display_title),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-1 additional-details" },
                      [
                        _vm.documentInfo.is_collection
                          ? [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 grey--text text--darken-1",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.documentInfo.collection_item_count
                                    ) + " titles"
                                  ),
                                ]
                              ),
                            ]
                          : _vm.hasAdoptionDetails
                          ? [
                              _c(
                                "p",
                                {
                                  staticClass: "mb-0 grey--text text--darken-1",
                                },
                                [
                                  _vm.documentInfo.base_code_info
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.documentInfo.base_code_info.year
                                          ) +
                                            " " +
                                            _vm._s(
                                              _vm.documentInfo.base_code_info
                                                .short_title
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.documentInfo.custom_code_profile_label
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.documentInfo
                                              .custom_code_profile_label
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.documentInfo.effective_date
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-0 grey--text text--darken-1",
                                    },
                                    [
                                      _vm._v(
                                        "Effective Date: " +
                                          _vm._s(
                                            _vm.documentInfo.effective_date
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0",
                      attrs: { cols: "auto", "align-self": "start" },
                    },
                    [
                      _vm.hasAccessIcon
                        ? _c("access-icon", {
                            attrs: { "access-level": _vm.accessLevel },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }