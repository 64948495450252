import axios from 'axios'
const client = axios.create();

export const GraphicsApi = {
    getGraphicsResults(data) {
        const {
            search,
            titles,
            categories,
            years,
            page
        } = data;
        return client.get(Routing.generate('graphics_search_results', {
            searchTermAny: search,
            docInfoIds: titles,
            categories: categories,
            years: years,
            page: page,
            limit: 12
        }));
    },
    getFigureContent(data) {
        const {
            documentId,
            contentId
        } = data;
        return client.get(Routing.generate('get_figure_content', {
            documentId: documentId,
            contentId: contentId
        }));
    }
}