<template>
    <div>
        <v-row>
            <v-col>
                <template v-if="!activeConcurrent">
                    <p>
                        <a :href="supportLink" target="_blank">Concurrent access</a> allows one or more users to access code book content and collaborate through a common code. The number of users able to connect to a concurrent code at the same time, as well as the code book titles available through a code, is configured by the user who creates the code.
                    </p>
                    <p>
                        To join a concurrent connection, please enter the access code in the below text box. Once connected, you will be able to see all notes entered from any other users who connect from the same code. The yellow banner at the top of your screen will indicate your concurrent connection.
                    </p>
                </template>
                <template v-else>
                    <p class="mb-0">
                        <a :href="supportLink" target="_blank">Concurrent access</a> allows one or more users to access code book content and collaborate through a common code. The number of users able to connect to a concurrent code at the same time, as well as the code book titles available through a code, is configured by the user who creates the code.
                    </p>
                </template>
            </v-col>
        </v-row>
        <v-card
            flat
            class="grey lighten-5">
            <v-card-text v-if="!isLoggedIn()">
                <v-row align="center">
                    <v-col
                        cols="12"
                        sm="auto">
                        Please Sign In before entering your Concurrent Access Code
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto">
                        <v-btn
                            class="white--text"
                            color="primary"
                            x-large
                            :href="getLoginUrl()">
                            Sign In
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else-if="!activeConcurrent">
                <v-row v-if="hasError">
                    <v-col>
                        <v-alert
                            outlined
                            dismissible
                            class="mb-0"
                            type="error">
                            {{ concurrentStatus.message }}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col
                        cols="12"
                        sm="6">
                        <v-text-field
                            v-model="accessCode"
                            outlined
                            hide-details
                            color="primary"
                            label="Enter Concurrent Access Code">
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto">
                        <v-btn
                            color="primary"
                            x-large
                            @click="concurrentAccessValidation()">
                            Submit
                        </v-btn>
                    </v-col>
                </v-row>
                <template
                    v-if="isPreviousCodesBusy"
                    class="my-4">
                    <v-progress-linear
                        indeterminate
                        color="primary">
                    </v-progress-linear>
                </template>
                <v-row v-else>
                    <v-col
                        v-for="(code, key) in previousCodes"
                        :key="key"
                        cols="auto">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    label
                                    outlined
                                    :color="isExpired(code) ? 'red' : 'primary'"
                                    @click="!isExpired(code) &&
                                        setUserProfile({
                                            type: 'concurrent',
                                            metadata: code.accessCode
                                        })">
                                    <p class="mb-0 caption primary--text">
                                        {{code.accessCode}}
                                        <span v-if="isExpired(code)">
                                        EXPIRED
                                    </span>
                                    </p>
                                    <v-icon
                                        right
                                        color="primary"
                                        @click.stop="showConfirmation(code)">
                                        mdi-close
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>
                            Last Used, {{code.lastUsedTime | moment("MMMM Do, YYYY")}}
                        </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else>
                <v-row align="center">
                    <v-col
                        cols="12"
                        sm="auto">
                        <p class="mb-0">
                            You are now using concurrent access for Access Code <b>{{activeConcurrentCode}}</b>.
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="auto">
                        <v-btn
                            color="primary"
                            x-large
                            @click="exitConcurrentAccess()">
                            Exit Concurrent Access
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="confirmDialog"
            max-width="500">
            <v-card>
                <v-card-title>
                    Please Confirm
                </v-card-title>
                <v-card-text>
                    <p>
                        Are you sure you want to remove this code from the list?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        light
                        @click="cancelRemoveCode">
                        Cancel
                    </v-btn>
                    <v-btn
                        class="error"
                        @click="confirmRemoveCode">
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Concurrent from "./mixins/concurrent";

    export default {
        data() {
            return {
                activeConcurrent: this.$session.has('active-concurrent'),
                confirmDialog: false,
                codeToBeRemoved: null,
                supportLink: 'https://support.iccsafe.org/ht_kb/digital-codes/premium-for-teams-with-concurrent-access/'
            }
        },
        mixins: [
            Concurrent
        ],
        methods: {
            isExpired(code) {
                return code.status !== undefined && !code.status;
            },
            showConfirmation(code) {
                const plainCode = Object.assign({}, code);

                this.codeToBeRemoved = plainCode;
                this.confirmDialog = true
            },
            confirmRemoveCode() {
                this.confirmDialog = false;

                // will take care of setting/unsetting of "busy" state
                this.removePreviousUsedCode(this.codeToBeRemoved);
            },
            cancelRemoveCode() {
                this.codeToBeRemoved = null;
                this.confirmDialog = false;
            },
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
        },
        mounted() {
            this.getPreviousUsedCodes();
        }
    }
</script>
