var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "premium-team-container" },
        [
          _c(
            "v-row",
            {
              class: { "py-10": !_vm.isMobilePort },
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  class: { "text-center": _vm.isMobilePort },
                  attrs: { cols: !_vm.isMobilePort ? "auto" : "12", md: "6" },
                },
                [
                  _c("h1", { staticClass: "font-weight-light mb-5 oxygen" }, [
                    _vm._v(
                      "\n                    Premium for Teams\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "font-weight-regular mb-5 oxygen" }, [
                    _vm._v(
                      "\n                    Empower Collaboration\n                    "
                    ),
                    _c("br"),
                    _vm._v("Within Your Organization\n                "),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "font-weight-regular mb-5 oxygen" }, [
                    _vm._v(
                      "\n                    Coordinated Team access to ICC Digital\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "Codes Premium. Boost your team's\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "collaboration and keep costs low.\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "Customize a solution that fits your needs.\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        dark: "",
                        "x-large": "",
                        color: "accent2",
                        target: "_blank",
                        href: this.$getConst("demoUrl"),
                      },
                    },
                    [_c("b", [_vm._v("Schedule Demo")])]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "mt-7" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-underline mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.showModal()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Already have a concurrent access code? "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                        Click here to get started\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { attrs: { order: "1" } }, [
                _c("img", {
                  attrs: {
                    width: "486",
                    height: "450",
                    src: _vm.asset(
                      require("../../images/teams/teams_people.jpg")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                {
                  staticClass: "grey lighten-3",
                  attrs: { flat: "", rounded: "" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center", dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm.isMobilePort
                              ? _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "font-weight-light pt-10 oxygen",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Two Options to Meet Your Needs\n                            "
                                    ),
                                  ]
                                )
                              : _c(
                                  "h1",
                                  {
                                    staticClass:
                                      "font-weight-light pt-10 oxygen",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Two Options to Meet Your Needs\n                            "
                                    ),
                                  ]
                                ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "", outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c("img", {
                                              attrs: {
                                                width: 150,
                                                height: 150,
                                                src: _vm.asset(
                                                  require("../../images/teams/teams_icon_concurrent.png")
                                                ),
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("h2", { staticClass: "pb-5" }, [
                                        _vm._v(
                                          "\n                                        Concurrent Licenses\n                                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "option-text" }, [
                                        _vm._v(
                                          "\n                                    Buy only what you need — share a select"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    number of licenses across your team\n                                    "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "", outlined: "" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-center" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c("img", {
                                              attrs: {
                                                width: 150,
                                                height: 150,
                                                src: _vm.asset(
                                                  require("../../images/teams/teams_icon_enterprise.png")
                                                ),
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("h2", { staticClass: "pb-5" }, [
                                        _vm._v(
                                          "\n                                        Enterprise Seats\n                                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "option-text" }, [
                                        _vm._v(
                                          "\n                                    Guaranteed access with dedicated "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                    seats for each person\n                                    "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "premium-team-container" },
        [
          _c(
            "v-row",
            {
              class: { "py-10": !_vm.isMobilePort },
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center pt-20",
                  attrs: { cols: !_vm.isMobilePort ? "6" : "auto" },
                },
                [
                  _c("img", {
                    attrs: {
                      width: "550",
                      height: "300",
                      src: _vm.asset(
                        require("../../images/teams/teams_youtube.png")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center" },
                [
                  _c("h3", [
                    _vm._v(
                      "\n                    For more details on the benefits"
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    of Concurrent vs. Enterprise, "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    watch our latest video.\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "my-4",
                      attrs: {
                        rounded: "",
                        dark: "",
                        "x-large": "",
                        color: "accent2",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.redirectToUrl(_vm.youTubeUrl)
                        },
                      },
                    },
                    [_c("b", [_vm._v("Watch Now")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "premium-team-container" },
        [
          _c(
            "v-row",
            { staticClass: "access-highlights" },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center access-display",
                  staticStyle: { margin: "auto" },
                  attrs: { md: "5", sm: "3" },
                },
                [
                  _c("h1", { staticClass: "font-weight-light oxygen" }, [
                    _vm._v("\n                    Access "),
                    _c("br"),
                    _vm._v(
                      "\n                    Highlights\n                "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("v-divider", {
                staticClass: "v-divider mx-4",
                attrs: { vertical: "" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "access-display" },
                [
                  _c(
                    "v-card",
                    { staticClass: "access-highlights", attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("img", {
                                  attrs: {
                                    width: 100,
                                    height: 100,
                                    src: _vm.asset(
                                      require("../../images/teams/teams_icon_concurrent.png")
                                    ),
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "pb-1" }, [
                            _vm._v("\n                            Concurrent"),
                            _c("br"),
                            _vm._v("Licenses\n                        "),
                          ]),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(
                              "\n                            Shared licenses\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", {
                staticClass: "v-divider mx-4",
                attrs: { vertical: "" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "access-display" },
                [
                  _c(
                    "v-card",
                    { staticClass: "access-highlights", attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("img", {
                                  attrs: {
                                    width: 100,
                                    height: 100,
                                    src: _vm.asset(
                                      require("../../images/teams/teams_icon_enterprise.png")
                                    ),
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "pb-1" }, [
                            _vm._v("\n                            Enterprise"),
                            _c("br"),
                            _vm._v("Seats\n                        "),
                          ]),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(
                              "\n                            Dedicated seats\n                        "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.accessHighlights, function (item, key) {
            return _c(
              "v-row",
              { key: key, class: item.class, attrs: { align: "center" } },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-7", attrs: { md: "5", sm: "3" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-icon",
                              { attrs: { "x-large": "", color: "accent2" } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.icon) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", [
                          _c("h4", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.title) +
                                "\n                        "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider", {
                  staticClass: "v-divider mx-4",
                  attrs: { vertical: "" },
                }),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    item.isConcurrent
                      ? _c(
                          "v-icon",
                          { attrs: { "x-large": "", color: "primary" } },
                          [
                            _vm._v(
                              "\n                        mdi-check-circle\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider", {
                  staticClass: "v-divider mx-4",
                  attrs: { vertical: "" },
                }),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "text-center" },
                  [
                    item.isEnterprise
                      ? _c(
                          "v-icon",
                          { attrs: { "x-large": "", color: "primary" } },
                          [
                            _vm._v(
                              "\n                        mdi-check-circle\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "access-highlights", attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticClass: "pa-7", attrs: { md: "5", sm: "3" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { "x-large": "", color: "accent2" } },
                            [
                              _vm._v(
                                "\n                        mdi-sale\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c("h4", [
                          _vm._v(
                            "\n                            Volume discounts\n                        "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
              _vm._v(" "),
              _c("v-col", { staticClass: "text-center" }, [
                _c("h4", [_vm._v("Up to 50% off")]),
              ]),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
              _vm._v(" "),
              _c("v-col", { staticClass: "text-center" }, [
                _c("h4", [_vm._v("Lowest per-user cost ")]),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "grey lighten-3 my-10" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-carousel",
                {
                  staticClass: "mb-10",
                  attrs: {
                    cycle: "",
                    "show-arrows": false,
                    "hide-delimiter-background": "",
                    light: "",
                    height: "auto",
                    id: "top_carousel",
                  },
                  model: {
                    value: _vm.slideNumber,
                    callback: function ($$v) {
                      _vm.slideNumber = $$v
                    },
                    expression: "slideNumber",
                  },
                },
                _vm._l(_vm.testimonials, function (item, i) {
                  return _c("v-carousel-item", { key: i }, [
                    _c("div", { staticClass: "pa-16 quoted-wrapper" }, [
                      _c("p", { staticClass: "quoted" }, [
                        _c("q", [_vm._v(_vm._s(item.text))]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "font-weight-bold text-center" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        rounded: "",
                        "x-large": !_vm.isMobilePort,
                        large: _vm.isMobilePort,
                        dark: "",
                        color: "accent2",
                        target: "_blank",
                        href: this.$getConst("demoUrl"),
                      },
                    },
                    [_c("b", [_vm._v("Schedule a Demo Today")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isMobilePort
            ? _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "auto pa-8" } }, [
                    _c("img", {
                      staticClass: "cp",
                      attrs: {
                        width: "322",
                        height: "77",
                        src: _vm.asset(
                          require("../../images/google_play_dark.png")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.appClick()
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto pa-8" } }, [
                    _c("img", {
                      staticClass: "cp",
                      attrs: {
                        width: "271",
                        height: "77",
                        src: _vm.asset(
                          require("../../images/apple_store_dark.png")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.appClick(true)
                        },
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }