var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grey lighten-5" }, [
    !_vm.isMobilePort
      ? _c(
          "div",
          { staticClass: "white", attrs: { id: "ai_header" } },
          [
            _c(
              "v-container",
              { staticClass: "px-0" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "", align: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("asset-image", {
                          staticClass: "mb-n1",
                          attrs: {
                            src: require("../../images/ai_navigator/ai_navigator_icon.svg"),
                            width: "40",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-col", { attrs: { cols: "auto" } }, [
                      _c("h2", { staticClass: "oxygen mt-n1" }, [
                        _vm._v("ICC AI Navigator"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-chip",
                          { attrs: { small: "", color: "primaryLight" } },
                          [
                            _c("span", { staticClass: "primary--text" }, [
                              _vm._v(
                                "\n                            Beta\n                        "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold mt-n1",
                            attrs: {
                              small: "",
                              color: "accent2",
                              elevation: "0",
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(
                                "\n                            mdi-creation\n                        "
                              ),
                            ]),
                            _vm._v(
                              "\n                        PREMIUM ACTIVE\n                    "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider"),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "d-lg-none d-flex flex-column",
            attrs: { id: "ai_header" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between align-center pa-3 mb-2 border-bottom",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("asset-image", {
                      staticClass: "mr-2",
                      attrs: {
                        src: require("../../images/ai_navigator/ai_navigator_icon.svg"),
                        width: "30",
                      },
                      on: { load: _vm.setAiContainerHeight },
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "oxygen" }, [
                      _vm._v("ICC AI Navigator"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", small: "" },
                    on: { click: _vm.mobileHeaderQuestionMarkClicked },
                  },
                  [
                    _c("v-icon", { attrs: { color: "primary" } }, [
                      _vm._v("mdi-help-circle-outline"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.mobileHeaderDropdownVisible
              ? _c(
                  "div",
                  {
                    staticClass: "h-full pa-3",
                    attrs: { id: "question_block" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "20px",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.mobileHeaderDropdownVisible = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("navigator-tips-block"),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-4" }),
                    _vm._v(" "),
                    _c("navigator-sidebar-disclaimer-block"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary white--text",
                            on: {
                              click: function ($event) {
                                _vm.mobileHeaderDropdownVisible = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Close\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "content-container d-flex flex-column flex-lg-row",
        attrs: { id: "ai_container" },
      },
      [
        !_vm.isMobilePort
          ? _c("ai-navigator-sidebar", {
              staticClass: "fill-height py-4",
              staticStyle: { width: "25%", "overflow-y": "auto" },
              attrs: { id: "ai_right" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("confirm-terms-dialog", {
          attrs: {
            visible: _vm.disclaimerModalVisible,
            "can-be-closed": !_vm.disclaimerConfirmationRequired,
          },
          on: {
            closed: function ($event) {
              _vm.disclaimerModalVisible = false
            },
            confirmed: _vm.disclaimerConfirmed,
          },
        }),
        _vm._v(" "),
        _c("notes"),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-lg-none d-flex flex-column",
            attrs: { id: "ai_top" },
          },
          [
            _vm.mobileHeaderDropdownVisible
              ? _c(
                  "div",
                  {
                    staticClass: "h-full pa-3",
                    attrs: { id: "question_block" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "20px",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                _vm.mobileHeaderDropdownVisible = false
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("navigator-tips-block"),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-4" }),
                    _vm._v(" "),
                    _c("navigator-sidebar-disclaimer-block"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary white--text",
                            on: {
                              click: function ($event) {
                                _vm.mobileHeaderDropdownVisible = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        Close\n                    "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "h-full lg:w-9/12 flex-grow-1 d-flex flex-column px-3 px-lg-5 pt-4 grey lighten-5",
            staticStyle: { "overflow-x": "hidden" },
          },
          [
            _c(
              "div",
              {
                staticClass: "flex-grow-1 overflow-auto pr-1 pr-lg-3",
                attrs: { id: "msg_rows" },
              },
              [
                _vm.hasMessages
                  ? [
                      _vm._l(_vm.messages, function (message, index) {
                        return [
                          typeof message === "object"
                            ? [
                                _c("chat-server-message", {
                                  staticClass: "mb-5",
                                  attrs: {
                                    prompt: message,
                                    questionId: _vm.questionId,
                                    namespace: _vm.selectedCodeCycle,
                                  },
                                  on: {
                                    done: _vm.onServerResponse,
                                    countdown: _vm.onCountDown,
                                  },
                                }),
                              ]
                            : _c(
                                "chat-message",
                                {
                                  staticClass: "mb-3",
                                  attrs: { type: "user" },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(message) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                        ]
                      }),
                    ]
                  : _c("ai-navigator-start", {
                      attrs: {
                        "can-send-messages": !_vm.isQuestionLimitReached,
                      },
                      on: { "question-clicked": _vm.onQuestionClicked },
                    }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3 mt-lg-5", attrs: { id: "input_box" } },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isBusy,
                      expression: "isBusy",
                    },
                  ],
                  staticClass: "chat-busy-actions",
                }),
                _vm._v(" "),
                _c("question-limit-alert", {
                  attrs: {
                    countdownremaining: _vm.countdownremaining,
                    "countdown-reset": _vm.countdownReset,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between align-center mb-1 text-muted",
                  },
                  [
                    _c(
                      "div",
                      [
                        _vm.codeCyclesLoading
                          ? _c("v-progress-linear", {
                              staticStyle: { "min-width": "10em" },
                              attrs: {
                                active: true,
                                color: "primary",
                                indeterminate: "",
                              },
                            })
                          : _vm.codeCyclesError
                          ? _c("p", { staticClass: "red--text mb-0" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.codeCyclesError) +
                                  "\n                        "
                              ),
                            ])
                          : "" !== _vm.getLocationNote
                          ? _c("p", { staticClass: "mb-0 text-sm" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.getLocationNote) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "text-sm text-no-wrap" },
                      [
                        _vm.isMobilePort
                          ? _c(
                              "v-btn",
                              {
                                attrs: { text: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.locationSelectionModal = true
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-tune")])],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", small: "" },
                            on: { click: _vm.clearChat },
                          },
                          [
                            _c("v-icon", [_vm._v("mdi-delete-outline")]),
                            _vm._v(" "),
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? [_vm._v("Clear")]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("v-textarea", {
                  staticClass: "white",
                  attrs: {
                    label: "Enter question",
                    "hide-details": "",
                    outlined: "",
                    rows: "1",
                    "auto-grow": "",
                    counter: "true",
                    autocomplete: "off",
                  },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.onEnterClicked.apply(null, arguments)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _vm.isBusy
                            ? _c("v-progress-circular", {
                                staticClass: "disabled-icon",
                                attrs: {
                                  indeterminate: "",
                                  color: "grey",
                                  size: "28",
                                },
                              })
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.sendMessage },
                                },
                                [_c("v-icon", [_vm._v("mdi-send-outline")])],
                                1
                              ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.question,
                    callback: function ($$v) {
                      _vm.question = $$v
                    },
                    expression: "question",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-space-between mt-2" },
                  [
                    _c("p", [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "caption text--secondary" }, [
                      _vm._v(
                        _vm._s(_vm.currentCharCount) +
                          " / " +
                          _vm._s(_vm.maxCharCount) +
                          " chars"
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "confirm-dialog",
          {
            attrs: { "is-open": _vm.locationSelectionModal },
            on: {
              closed: function ($event) {
                _vm.locationSelectionModal = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(
                      "\n                Location / I-Codes\n            "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "buttons",
                fn: function () {
                  return [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.locationSelectionModal = false
                          },
                        },
                      },
                      [_vm._v("\n                    Close\n                ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _c("navigator-location-selection", { staticClass: "mt-5" }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }