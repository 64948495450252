var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "testimonials-container" },
        [
          _c(
            "v-row",
            {
              class: {
                "py-10": !_vm.isMobilePort,
                flexDirection: _vm.isMobilePort,
              },
              attrs: {
                align: "center",
                justify: "center",
                dense: _vm.isMobilePort,
              },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto", order: "1" } },
                [
                  _c("h1", { staticClass: "font-weight-light mb-4 oxygen" }, [
                    _vm._v("\n                    Customers and"),
                    _c("br"),
                    _vm._v("Testimonials\n                "),
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "font-weight-regular" }, [
                    _vm._v(
                      "\n                    Inspiring stories, real Digital"
                    ),
                    _c("br"),
                    _vm._v("Codes customers\n                "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: { dark: "", medium: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.goToRoute("trial_signup")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Start FREE Trial Today\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { order: "2" } },
                [
                  _c("asset-image", {
                    attrs: {
                      width: "100%",
                      src: require("../../images/testimonials/testimonial.webp"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              attrs: {
                dense: _vm.isMobilePort,
                align: "center",
                justify: "center",
              },
            },
            [
              _c("v-col", { staticClass: "text-center py-0" }, [
                _c("h2", { staticClass: "font-weight-regular" }, [
                  _vm._v(
                    "\n                    Our Valued Customers\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "px-10 py-10 companyLogos",
              attrs: { dense: _vm.isMobilePort },
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "carousel",
                    {
                      attrs: {
                        "per-page": _vm.isMobilePort
                          ? _vm.mobilePerPage
                          : _vm.perPage,
                        paginationEnabled: false,
                        navigationEnabled: true,
                        navigationNextLabel: _vm.slideShow.nextButton,
                        navigationPrevLabel: _vm.slideShow.prevButton,
                        paginationColor: _vm.slideShow.paginationColor,
                        paginationActiveColor:
                          _vm.slideShow.paginationColorActive,
                      },
                    },
                    _vm._l(_vm.companyLogos, function (companyLogo, index) {
                      return _c(
                        "slide",
                        { key: index },
                        [
                          _c("asset-image", {
                            staticClass: "logoContainer",
                            attrs: {
                              src: require("../../images/testimonials/" +
                                companyLogo.logo),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "px-3 py-3 testimonialBackground",
              attrs: { dense: _vm.isMobilePort },
            },
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c(
                    "h2",
                    {
                      staticClass: "font-weight-regular text-center py-5 pb-5",
                    },
                    [
                      _vm._v(
                        "\n                    What our Valued Customers are Saying "
                      ),
                      _c("br"),
                      _vm._v("about Digital Codes Premium\n                "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-carousel",
                    {
                      attrs: { "show-arrows": !_vm.isMobilePort },
                      model: {
                        value: _vm.slideNumber,
                        callback: function ($$v) {
                          _vm.slideNumber = $$v
                        },
                        expression: "slideNumber",
                      },
                    },
                    _vm._l(_vm.testimonials, function (testimonial, index) {
                      return _c("v-carousel-item", { key: index }, [
                        _c(
                          "div",
                          {
                            class: {
                              interTestimonialDivWrapper:
                                !_vm.isMobilePort || _vm.isMobilePort,
                              webWidth: !_vm.isMobilePort,
                              mobileWidth: _vm.isMobilePort,
                            },
                          },
                          [
                            _c("div", { staticClass: "interTestimonialDiv" }, [
                              _c("blockquote", { staticClass: "quotes" }, [
                                _c(
                                  "p",
                                  { staticClass: "py-7 mb-0 black--text" },
                                  [_vm._v(_vm._s(testimonial.content))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-weight-bold mb-0 mt-3 caption black--text",
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(testimonial.name) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mb-0 mt-3 caption black--text",
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(testimonial.company) +
                                      "\n                                        "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(testimonial.position) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-carousel",
            { attrs: { height: "auto", "show-arrows": !_vm.isMobilePort } },
            _vm._l(_vm.caseStudys, function (caseStudy, index) {
              return _c("v-carousel-item", { key: index }, [
                _c(
                  "a",
                  { attrs: { href: caseStudy.link, target: "_blank" } },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "caseStudy mt-10 px-3 py-3",
                        attrs: { dense: _vm.isMobilePort },
                      },
                      [
                        _c("v-col", [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "primary--text caseStudyHeading pb-5",
                            },
                            [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(caseStudy.heading) +
                                  "\n                                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("h3", { staticClass: "black--text fontSize18" }, [
                            _vm._v(
                              "\n                                            " +
                                _vm._s(caseStudy.subHeading) +
                                "\n                                        "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "caseStudyImageWrapper",
                        attrs: { dense: _vm.isMobilePort },
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-0" },
                          [
                            _c("asset-image", {
                              class: {
                                caseStudyImage:
                                  !_vm.isMobilePort || _vm.isMobilePort,
                                imageRight: !_vm.isMobilePort,
                                imageRightMobile: _vm.isMobilePort,
                              },
                              attrs: {
                                src: require("../../images/testimonials/" +
                                  caseStudy.image),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-12" },
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("h2", { staticClass: "font-weight-regular" }, [
                  _vm._v(
                    "\n                            Enjoying ICC Digital Codes Premium?\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                            Share you experience by leaving a review on Capterra.\n                        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.iccCapterraReviewLink,
                      target: "_blank",
                    },
                  },
                  [
                    _c("asset-image", {
                      staticClass: "my-8",
                      attrs: {
                        src: require("../../images/testimonials/capterra.jpg"),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }