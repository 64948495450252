var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { fixed: "", temporary: "", clipped: "", width: 400 },
      model: {
        value: _vm.drawer,
        callback: function ($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer",
      },
    },
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c("v-col", [
                    _c("img", {
                      staticClass: "logo",
                      attrs: {
                        height: "42",
                        src: _vm.asset(
                          require(`../../images/${_vm.logoSrc}`),
                          "default"
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "accent--text",
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.drawer = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("clear")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.component,
              expression: "!component",
            },
          ],
          key: "main",
        },
        [
          _c("link-list-icons", {
            attrs: { links: _vm.finalLinks },
            on: { updateComponent: (data) => (_vm.component = data) },
          }),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              attrs: {
                href: _vm.isActiveUser
                  ? _vm.getRoute("logout")
                  : _vm.getLoginUrl(),
              },
            },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-2" },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(
                          _vm.isActiveUser
                            ? "mdi-logout-variant"
                            : "mdi-login-variant"
                        ) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-title",
                { staticClass: "fs-16 font-weight-bold" },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.isActiveUser ? "Sign Out" : "Sign In") +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column pa-2 pa-md-3" },
            [
              !_vm.hasPremiumSubscription
                ? _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold mb-2",
                      attrs: {
                        elevation: "0",
                        block: "",
                        color: "accent2",
                        large: "",
                        href: _vm.getRoute(
                          _vm.pricingPageEnabled
                            ? "pricing_page"
                            : "premium_features"
                        ),
                      },
                    },
                    [_vm._v("\n          Subscribe\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("start-free-trial", {
                attrs: { color: "primary", outlined: true },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_vm.component, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.component,
            expression: "component",
          },
        ],
        key: _vm.component,
        tag: "component",
        on: {
          backToMain: function ($event) {
            _vm.component = null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }