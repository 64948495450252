var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "text-center d-none d-lg-block", attrs: { cols: "5" } },
        [
          _c("img", {
            attrs: {
              src: this.asset(
                require("../../images/homepage/stay-connected-mobile-app.png")
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-flex align-center", attrs: { cols: "12", lg: "7" } },
        [
          _c("div", [
            _c("h1", { staticClass: "font-weight-light mb-5 oxygen" }, [
              _vm._v(
                "Stay Connected on the Go with offline Access through ICC\n                Digital Codes Premium Mobile App"
              ),
            ]),
            _vm._v(" "),
            _c("h2", { staticClass: "primary--text mb-5" }, [
              _vm._v("\n                Available on "),
              _c(
                "a",
                {
                  staticClass: "cdpBlue--text",
                  attrs: {
                    href: "https://apps.apple.com/us/app/digital-codes-premium/id1523315914",
                    target: "_blank",
                  },
                },
                [_vm._v("App Store")]
              ),
              _vm._v(" or\n\n                "),
              _c(
                "a",
                {
                  staticClass: "cdpBlue--text",
                  attrs: {
                    href: "https://play.google.com/store/apps/details?id=org.iccsafe.codes",
                    target: "_blank",
                  },
                },
                [_vm._v("Google Play")]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-5" }, [
              _vm._v(
                "Access features and content through a native mobile interface while downloading up to 15\n                titles from your\n                Premium subscription for offline capabilities."
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://apps.apple.com/us/app/digital-codes-premium/id1523315914",
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mr-5",
                    attrs: {
                      src: this.asset(
                        require("../../images/homepage/download-app-store.png")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://play.google.com/store/apps/details?id=org.iccsafe.codes",
                    target: "_blank",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mr-5",
                    attrs: {
                      src: this.asset(
                        require("../../images/homepage/get-on-google-play.png")
                      ),
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }