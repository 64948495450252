<template>
    <v-card
        class="mt-2"
        outlined>
        <v-card-text class="pt-2">
            <v-row>
                <v-col>
                    <p>With Premium search category you can search across your entire library of Premium titles or refine search results using available filters including:</p>
                    <p>
                        Category / Location - narrow by category grouping from available contents <br />
                        Years - narrow by specific year(s) from available contents <br />
                        Premium Titles - narrow by specific titles from available contents <br />
                        Content Type - define content type including sections, tables, figures <br />
                        Advanced Search - define the type of search including all these words, any of these words and near search (terms that occur within 50 words of each other)
                    </p>
                    <p class="font-weight-bold mt-5">You Might Try:</p>
                    <ul>
                        <li v-for="(item, index) in mostPopularSearchTerms">
                            <a href="" @click.prevent="doSearch(item)">{{ item }}</a>
                        </li>
                    </ul>
                    <p class="font-weight-bold mt-5">
                        Looking for help?
                    </p>
                    <ul>
                        <li>
                            <a @click="initLiveChat()">Chat with us</a>
                        </li>
                        <li>
                            <a href="mailto:support@iccsafe.org">Email us</a>
                        </li>
                        <li>
                            <a @click="showFeedbackModal()">Submit Feedback</a>
                        </li>
                        <li>
                            Call <a href="tel:1-888-422-7233">888-ICC-SAFE</a> (<a href="tel:1-888-422-7233">888-422-7233</a>)
                        </li>
                    </ul>
                </v-col>
                <v-col
                    v-if="!isMobilePort"
                    cols="auto">
                    <img
                        style="max-width: 350px;"
                        :src="this.asset(require('../../../images/category_search.png'))">
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Mobile from "../../inc/mixins/mobile";
import FeedbackChat from "../../inc/mixins/feedback_chat";
import searchStatisticsMixin from "../../inc/mixins/searchStatisticsMixin";

export default {
    name: 'MissingSearchTerms',
    mixins: [
        Mobile,
        FeedbackChat,
        searchStatisticsMixin
    ],
    methods: {
        doSearch(terms) {
            EventBus.fire('update-content-search-results', terms);
        },
        showFeedbackModal() {
            EventBus.fire('show-feedback-modal');
        }
    }
}
</script>
