<template>
    <v-container>
        <v-row>
            <v-col>
                <h3 class="font-weight-regular">
                    <a href="http://www.iccsafe.org/errata-central/" target="_blank">
                        Visit Errata Central to access all posted errata documents.
                    </a>
                </h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="errata_headers"
                    :items="data"
                    hide-default-footer>
                    <template v-slot:item="{ item }">
                        <tr v-if="item.tag.is_active">
                            <td class="font-weight-bold"><a target="_blank" :href="item.tag.url" class="link-table-txt">{{ item.tag.title }}</a></td>
                            <td class="font-weight-bold">{{ item.tag.category }} </td>
                            <td>{{ item.tag.published_date | moment("MMMM Do, YYYY") }}</td>
                            <td>{{ item.tag.effective_date | moment("MMMM Do, YYYY") }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            errata_headers: [
                { text: 'Title', value: 'title' },
                { text: 'Category', value: 'category' },
                { text: 'Published Date', value: 'published_date' },
                { text: 'Effective Date', value: 'effective_date' }
            ]
        };
    },
    props: {
        data: {
            required: true,
            type: Object
        }
    }
}
</script>
