var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "open-on-click": "",
        "z-index": "1005",
        "max-width": "500px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: onMenu }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: onTooltip }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "logo",
                                  attrs: {
                                    "data-qa": "navigation-logo-menu",
                                    icon: "",
                                    text: "",
                                  },
                                },
                                { ...onMenu, ...onTooltip }
                              ),
                              [
                                _c("v-icon", { attrs: { color: "black" } }, [
                                  _vm._v("mdi-help-circle-outline"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v("Help and Feedback")])]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { staticClass: "navigation-help-menu" },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "v-list-item",
            _vm._g(
              {
                key: index,
                attrs: {
                  href: item.href ? item.href : null,
                  target: item.newWindow ? "_blank" : "_self",
                },
              },
              {
                click: item.onclick ? item.onclick : _vm.handlerThatDoesNothing,
              }
            ),
            [
              _c(
                "v-list-item-icon",
                { staticClass: "mr-4" },
                [
                  _c("v-icon", {
                    staticClass: "black--text",
                    domProps: { textContent: _vm._s(item.icon) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }