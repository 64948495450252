// temporary solution for Error:
// Support for the experimental syntax 'classProperties' isn't currently enabled (7:10):
let _classData = {};

/**
 * @typedef {Object} TrialInfo
 * @property {boolean} userOptedForTrial
 * @property {number} remainingDays
 * @property {boolean} isTrialActive
 */

/**
 * @typedef {Object} enterprise
 * @property {boolean} isAdmin
 * @property {number} id
 */

/**
 * TYPED object wrapper for API response from:
 * https://codes.iccsafe.org/api/user/me
 *
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} email
 * @property {boolean} show_astm_modal
 * @property {string} lastSyncDate
 *
 * @property {TrialInfo} trial
 * @property {boolean} premium_complete_trial
 * @property {boolean} premium_complete_no_trial
 * @property {enterprise} enterprise
 * @property {Object} preferences_json
 * @property {string} concurrent_subscription_level
 */
export class User {

    static CODE_CYCLE_LOCATION = 'ai_navigator:code_cycle_location';
    static CODE_CYCLE_YEAR = 'ai_navigator:code_cycle_year';
    static TRIAL_BLOCK_SECTION = 'dashboard:trial_block_section';
    static UPDATES_BLOCK_SECTION = 'dashboard:updates_block_section';

    constructor(data) {
        Object.assign(this, data);
        _classData = data;
    }

    getData() {
        return _classData;
    }

    /**
     *
     * @returns {Array}
     */
    getRoles() {
        return _.get(_classData, 'roles');
    }

    /**
     *
     * @returns {Array}
     */
    getArrayPreferences(preferenceName) {
        const val = _.get(_classData, 'preferences.' + preferenceName);
        return Array.isArray(val) ? val : [];
    }

    getPreference(name, defaultValue = undefined) {
        const obj = this.preferences_json || null;
        return _.get(obj, name, defaultValue);
    }
}
