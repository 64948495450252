<template>
    <v-container class="content-container" id="pricing_table">
        <v-row>
            <v-col>
                <section-heading
                    heading="Build your Premium subscription bundle"
                    subHeading="Digital Codes subscriptions work on a title by title basis.
                            Subscribe to Premium by Single, Collection, Complete or a combination based on your needs.">
                </section-heading>
                <v-row>
                    <v-col>
                        <p class="fs-20 mb-0">Search or filter titles</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="isMobilePort ? 12 : 6">
                        <v-text-field
                            v-model="search"
                            id="price_search"
                            clearable
                            dense
                            outlined
                            hide-details
                            append-icon="mdi-magnify"
                            @click:clear="clickedClear"
                            @keydown="keydown"
                            @click:append="performSearch"
                            autocomplete="off"
                            label="Search Titles">
                        </v-text-field>
                    </v-col>

                    <v-col :cols="isMobilePort ? 6 : 3">

                        <v-autocomplete
                            v-model="selectedCategories"
                            hide-details
                            clearable
                            dense
                            item-text="name"
                            item-value="name"
                            outlined
                            :items="sortedCategories"
                            label="Category"
                            multiple
                            @change="debouncedCategoryYear">
                            <template v-slot:item="data">

                                <v-list-item>
                                    <v-list-item-action class="mr-2">
                                        <v-checkbox
                                                :value="selectedCategories.includes(data.item.name)"
                                                @change="toggleCategoryCheckbox(data.item.name)"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <template v-if="data.item.child">&ndash;&ndash;</template> {{ data.item.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col :cols="isMobilePort ? 6 : 3">
                        <v-autocomplete
                            v-model="selectedYears"
                            hide-details
                            clearable
                            item-text="name"
                            item-value="name"
                            dense
                            outlined
                            :items="years"
                            label="Year"
                            multiple
                            @change="debouncedCategoryYear">
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="auto" >
                        <v-btn-toggle
                            v-model="activeTab"
                            mandatory>
                            <v-btn
                                v-for="(name, index) in filters"
                                :key="index"
                                :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}!important;`"
                                height="40"
                                class="fs-14 font-weight-bold"
                                :class="activeTab === index ? 'primary white--text' : 'primary--text btn-group-background'"
                                @click="selectedTab(name)"
                                >
                                {{ name }}
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                    <v-col>
                        <v-btn outlined @click="clearFilters">
                            Clear All
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!isLoading && this.pagination.totalResults > 0">
                    <v-col>
                        <p class="fs-16 mb-0 pa-1">
                            {{ this.pagination.totalResults }} titles {{ !hasInput ? '(year descending)' : ''}}
                        </p>
                    </v-col>
                </v-row>
                <template v-if="isMobileAndTablet">
                    <v-row v-if="isLoading">
                        <v-col class="text-center">
                            <v-progress-circular
                                indeterminate>
                            </v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row
                        hover
                        v-else-if="titles.length > 0"
                        v-for="(title, index) in titles"
                        @click="addItemToCart(title)"
                        :key="index"
                        style="border: 1px solid #E0E0E0;"
                        class="mx-0"
                        :class="{'unset-border' : index !== 0, 'selected-row-background': isTitleInCart(title)}">
                        <v-col cols="4" class="font-weight-bold fs-14">Titles</v-col>
                        <v-col cols="8">
                            <v-row dense>
                                <v-col>
                                    <p class="mb-0 fs-14 text-right">
                                        {{ title.display_title }}
                                    </p>
                                    <template v-if="title.is_collection">
                                        <p class="mb-0 grey--text text--darken-1 fs-14">
                                            {{title.collection_item_count}} titles
                                        </p>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4" class="font-weight-bold fs-14">
                            Price Per License ({{ billingPeriodText }})
                        </v-col>
                        <v-col cols="8" class="text-right">
                           <!-- Todo: Handle price for if user is a member -->
                           <template v-if="title.pricing">
                                ${{ displayPrice(title) | formatPrice}}
                            </template>
                            <template v-else>
                                Unavailable
                            </template>
                        </v-col>
                        <v-col cols="4" class="font-weight-bold fs-14">
                            Actions
                        </v-col>
                        <v-col cols="8" class="text-right">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-icon color="black" v-on="on">mdi-dots-vertical</v-icon>
                                </template>
                                <v-list>
                                    <v-list-item @click="redirectTo(title)">
                                        <v-list-item-title>Open Title</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-icon color="black" @click.stop="addItemToCart(title)">
                                {{ !isTitleInCart(title) ? 'mdi-plus' : 'mdi-check' }}
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col class="text-center">
                            <p>No titles found</p>
                        </v-col>
                    </v-row>
                    <v-dialog
                        scrollable
                        v-model="getShowCartQue"
                        :fullscreen="true">
                        <cart-que>
                        </cart-que>
                    </v-dialog>

                </template>
                <template v-else>
                    <v-row v-if="isLoading">
                        <v-col>
                            <v-card
                                class="mt-4"
                                outlined>
                                <v-card-text>
                                    <loading-linear>
                                    </loading-linear>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <template v-else>
                        <div v-if="titles.length > 0">
                            <v-row
                                style="border: 1px solid #E0E0E0;"
                                class="mx-0">
                                <v-col cols="8" class="font-weight-bold fs-14 grey--text text--darken-1">
                                    Title
                                </v-col>
                                <v-col class="font-weight-bold fs-14 grey--text text--darken-1 text-right">
                                    Price Per License ({{ billingPeriodText }})
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on }">
                                            <v-icon
                                                small
                                                v-on="on">
                                                mdi-information-outline
                                            </v-icon>
                                        </template>
                                        <span>Price is discounted for Annual and 3-year subscriptions</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="1" class="text-center font-weight-bold fs-14 grey--text text--darken-1">
                                    Actions
                                </v-col>
                            </v-row>
                            <v-row
                                align="center"
                                hover
                                v-for="(title, index) in titles"
                                :key="index"
                                @click="hasPrice(title) === false ? null : addItemToCart(title)"
                                style="border: 1px solid #E0E0E0;"
                                class="mx-0 result-row unset-border cp"
                                :class="{'selected-row-background': isTitleInCart(title)}">
                                <v-col cols="8" class="d-flex">
                                    <v-row
                                        dense
                                        align="center">
                                        <v-col cols="auto">
                                            <graphic
                                                :additionalClasses="'d-flex'"
                                                :title="title"
                                                thumbnail
                                                :displayOnlyTopicIcon="true"
                                                width="24px">
                                            </graphic>
                                        </v-col>
                                        <v-col cols="auto">
                                            <p class="mb-0 fs-14">
                                                {{ title.display_title }}
                                            </p>
                                            <template v-if="title.is_collection">
                                                <p class="mb-0 grey--text text--darken-1 fs-14">
                                                    {{title.collection_item_count}} titles
                                                </p>
                                            </template>
                                        </v-col>
                                        <v-col>
                                            <v-tooltip
                                                bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <a
                                                        v-on="on"
                                                        :href="title.link"
                                                        target="_blank"
                                                        @click.stop>
                                                        <v-icon
                                                            small v-on="on" v-bind="attrs"
                                                            class="pl-2"
                                                            color="black">
                                                            mdi-open-in-new
                                                        </v-icon>
                                                    </a>
                                                </template>
                                                <span>Open {{ title.display_title }} in a new tab</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="text-right">
                                    <!-- Todo: Break this out into a component -->
                                    <template v-if="hasPrice(title)">
                                        ${{ displayPrice(title) | formatPrice }}
                                    </template>
                                    <template v-else>
                                        {{ displayPrice(title) }}
                                    </template>
                                </v-col>
                                <v-col class="text-center" cols="1">
                                    <v-tooltip
                                        top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                icon
                                                :disabled="hasPrice(title) === false"
                                                @click.stop="addItemToCart(title)"
                                                v-on="on">
                                                <v-icon color="black">
                                                    {{ !isTitleInCart(title) ? 'mdi-plus' : 'mdi-check' }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span v-if="hasPrice(title)">
                                            {{ !isTitleInCart(title) ? 'Add to your Premium package' : 'Added in your Premium package' }}
                                        </span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-else>
                            <v-col class="text-center">
                                <p>No titles found</p>
                            </v-col>
                        </v-row>
                    </template>
                </template>
                <v-row
                    v-if="page < pagination.maxPerPage && !isLoading"
                    dense
                    justify="center">
                    <v-col cols="auto">
                        <v-btn
                            text
                            color="primary"
                            class="mb-4"
                            :loading="isLoadingMore"
                            @click="showMore">
                            Show More
                            <v-icon right>
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center fs-20">
                        <p class="mb-2">Need help choosing a subscription? We’re here to help</p>
                        <a :href="`mailto:${this.$getConst('sales_email_address')}`" class="font-weight-bold">
                            Contact our sales team for assistance
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <bundle-count-snack-bar
            v-show="!getShowCartQue"
            :item-count="addedToCartItems.length"
            @openPremiumBundle="openPremiumBundle">
        </bundle-count-snack-bar>
    </v-container>
</template>
<script>

import { SearchApi } from '../../api/Search';
import { TitlesApi } from '../../api/Titles';
import TitleCard from '../content/title/TitleCard.vue';
import mobile from '../inc/mixins/mobile';
import Graphic from '../titles/cover/Graphic.vue';
import CartQue from './CartQue.vue';
import BundleCountSnackBar from './BundleCountSnackBar';
import SectionHeading from '../SectionHeading';
import { mapState, mapGetters, mapActions } from 'vuex';
import LoadingLinear from "../loaders/LoadingLinear.vue";

export default {
    data(){
        return {
            search: '',
            categories: [],
            selectedCategories: [],
            years: [],
            selectedYears: null,
            activeTab: 0,
            filters: [
                'Singles',
                'Collections',
                'Complete'
            ],
            collectionOrPremiumCompleteOnly: '',
            isLoading: false,
            isLoadingMore: false,
            titles: [],
            limit: 10,
            page: 1,
            pagination: {
                currentPage: 1,
                totalResults: 0
            },
            hasInput: false
        }
    },
    computed: {
        ...mapState('PricingModule', ['addedToCartItems', 'frequency']),
        ...mapGetters('PricingModule', [
            'getCartItems',
            'getShowCartQue',
            'billingPeriodText',
            'getIsMember',
            'displayPrice'
        ]),
        sortedCategories(){
            const cats =  this.$store.getters.getSearchNavigationCategories;
            const combined = cats.Category.concat(cats.Location);

            const names = [];

            combined.forEach((category) => {

                names.push({
                    name: category.name,
                    child: false
                });

                if (category.children) {
                    category.children.forEach((subcategory) => {
                        names.push({
                            name: subcategory.name,
                            child: true
                        });
                    })
                }

            });

            return names;
        }
    },
    mixins:[mobile],
    components: {
        LoadingLinear,
        TitleCard,
        Graphic,
        CartQue,
        BundleCountSnackBar,
        SectionHeading
    },
    methods: {
        ...mapActions('PricingModule', [
            'addToCart',
            'removeFromCart',
            'setCartItems',
            'setShowCartQue',
            'setHideCartQue'
        ]),
        hasPrice(title) {
            return !isNaN(parseFloat(this.displayPrice(title))) && isFinite(parseFloat(this.displayPrice(title)))
        },
        toggleCategoryCheckbox(item) {
            const index = this.selectedCategories.indexOf(item);
            if (index > -1) {
                this.selectedCategories.splice(index, 1);
            } else {
                this.selectedCategories.push(item);
            }
            this.debouncedResult();
        },
        addItemToCart(titleObj) {
            const title = _.cloneDeep(titleObj);
            const itemInCart = this.getCartItems.find(item => item.id === title.id);
            if (!itemInCart && this.hasPrice(title)) {
                this.addToCart(title);
                if (this.getCartItems.length === 1 && !this.getShowCartQue) {
                    this.openPremiumBundle()
                }
            }
        },
        isTitleInCart(title) {
            return this.addedToCartItems.some(item => item.id === title.id);
        },
        openPremiumBundle(){
            this.setShowCartQue()
        },
        selectedTab(tab){
            this.collectionOrPremiumCompleteOnly = '';
            if (tab === 'Complete') {
                this.collectionOrPremiumCompleteOnly = 'Premium Complete';
            } else if (tab === 'Collections'){
                this.collectionOrPremiumCompleteOnly = 'Collection';
            }
            this.getTitlesResult();
        },
        redirectTo(title){
            window.location.href = title.link;
        },
        showMore(){
            this.isLoadingMore = true;
            this.page = this.page + 1;
            this.getTitlesResult(true);
        },
        performSearch(){
            this.getTitlesResult();
        },
        keydown(event) {
            if (event.key === "Enter") {
                this.getTitlesResult();
            }
        },
        clickedClear(){
            this.search = '';
            this.getTitlesResult();
        },
        clearFilters(){
            this.search = '';
            this.selectedCategories = [];
            this.selectedYears = null;
            this.collectionOrPremiumCompleteOnly = '';
            this.activeTab = 0;
            this.getTitlesResult();
        },
        getAllYears() {
            SearchApi.getAllYears().then(response => {
                const yearItems = response.data.map(year => {
                    return {
                        id: year.id,
                        name: `${year.name}`
                    }
                });
                this.years = yearItems;
            }).catch(this.handleError);
        },
        getCategories() {
            const data = {
                searchable: true
            };

            SearchApi.getCategories(data).then(response => {
                const items = response.data.data;
                // Sort the category list
                items.sort(function(a,b){
                    let category1 = a.name.toUpperCase();
                    let category2 = b.name.toUpperCase();
                    return (category1 < category2) ? -1 : (category1 > category2) ? 1 : 0;
                });

                this.categories = items;

            }).catch(this.handleError);
        },
        async getTitlesResult(showMore = false){
            if (!showMore) {
                this.isLoading = true;
                this.page = 1;
            }

            const titleCategories = this.selectedCategories || [];
            const titleYears = this.selectedYears || [];
            const collectionOrPremiumCompleteOnly = this.collectionOrPremiumCompleteOnly || '';
            const data = {
                search: this.search,
                page: this.page,
                limit: this.limit,
                year: titleYears,
                category: titleCategories,
                collectionOrPremiumCompleteOnly: collectionOrPremiumCompleteOnly,
                pricing: true
            }

            try {
                const response = await TitlesApi.getTitles(data);
                if (showMore) {
                    const newData = response.data.data;
                    this.titles.push(...newData);
                } else {
                    this.titles = response.data.data;;
                }

                this.pagination = response.data.pagination;
                this.isLoading = false;
                this.isLoadingMore = false;
                if (this.search ||
                    this.selectedCategories.length > 0 ||
                    this.selectedYears) {
                    this.hasInput = true;
                } else {
                    this.hasInput = false;
                }
            } catch (error) {
                // Todo: Handle error
                console.log(error)
            }
        },
        debouncedCategoryYear(){
            this.debouncedResult();
        },
        activatePricingTable(title){
            this.activeTab = this.filters.indexOf(title);
            this.selectedTab(title);
            setTimeout(() => this.$scrollTo(
                document.getElementById('pricing_table'),
                {offset: -50}
            ), 500);
        }

    },
    mounted(){
        this.getAllYears();
        this.getCategories();
        this.getTitlesResult();
    },
    created() {
        this.debouncedResult = _.debounce(this.getTitlesResult, 1000); // Debounce the API call with a delay of 1 second
        EventBus.listen('redirect-activate-pricing-table', (data) => this.activatePricingTable(data));
        EventBus.listen(
            'open-premium-bundle',
            () => this.openPremiumBundle()
        )
        this.refreshThePageIfBackBtnClick();
    },
}
</script>
<style scoped>
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.btn-group-background
    {
        background-color: #ffff !important;
    }
    .result-row:hover {
        background-color: var(--v-grayLight-base) !important;
    }
    .selected-row-background{
        background-color: var(--v-grayLight-base) !important;
    }
    .unset-border{
        border-top: unset !important;
    }
</style>