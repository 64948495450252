import axios from 'axios'

export const BillingApi = {
    http: axios.create(),
    cancelSubscription(apiData) {
        return this.http.post(Routing.generate('cancel_subscription_autorenew'), apiData);
    },
    getUserSubscriptions(userId = null) {
        const param = userId ? {userId: userId} : {};
        return this.http.get(Routing.generate('get_user_subscription', param));
    },
    getPaymentProfile(userId = null) {
        const param = userId ? {userId: userId} : {};
        return this.http.get(Routing.generate('get_billing_payment_profiles', param));
    },
    removeLicensesCheckout(request) {
        return this.http.post(Routing.generate('remove_licenses'), request);
    },
    addLicensesCheckout(request) {
        return this.http.post(Routing.generate('order_checkout'), request);
    },
    proRatePrice(request) {
        return this.http.get(Routing.generate('get_pro_rate_price', request));
    },
    logSubscriptionChange(request) {
        return this.http.post(Routing.generate('subscription_audit_log'), request);
    },
    cancelLicenseRemovalRequest(request) {
        return this.http.post(Routing.generate('cancel_license_removal_request'), request);
    }
};
