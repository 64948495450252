<template>
    <v-card
        class="mt-3 notes-expansion-panel-wrapper"
        :id="`notes-expansion-panel-wrapper-${sectionId}`"
        outlined v-if="hasBookmarksOrAnnotations">
        <v-expansion-panels
            v-model="panel"
            class="notes-expansion-panel">
            <v-expansion-panel>
                <v-expansion-panel-header expand-icon="mdi-chevron-down">
                    <div class="font-weight-bold">My Notes</div>
                </v-expansion-panel-header>
                <v-divider>
                </v-divider>
                <v-expansion-panel-content class="pa-0">
                    <template
                        v-for="item in bookmarks">
                        <v-card
                            :id="'bookmark-list-item-'+item.id"
                            flat>
                            <v-card-text class="pa-0">
                                <note-list-item
                                    type="bookmark"
                                    :data="item"
                                    :tags="tags">
                                </note-list-item>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template
                        v-for="item in annotations">
                        <v-card
                            :id="'annotation-list-item-'+item.id"
                            flat>
                            <v-card-text class="pa-0">
                                <note-list-item
                                    type="note"
                                    :data="item"
                                    :tags="tags">
                                </note-list-item>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import NoteListItem from './noteListItem';
    export default {
        data() {
            return {
                panel: 0,
                notesList: this.data
            }
        },
        computed: {
            bookmarks() {
                return this.notesList.bookmarks;
            },
            annotations() {
                return this.notesList.annotations;
            },
            hasBookmarksOrAnnotations() {
                return this.bookmarks.length || this.annotations.length;
            }
        },
        props: {
            data: {
                required: true,
                type: Object
            },
            tags: {
                required: true,
                type: Array
            },
            sectionId: {
                required: false,
                type: Number
            }
        },
        components: {
            NoteListItem
        },
        methods: {
            updateNoteList(data) {
                if (_.size(data.annotations) > 0) {
                    this.notesList.annotations = _.concat(this.notesList.annotations, data.annotations);
                } else {
                    this.notesList.bookmarks = _.concat(this.notesList.bookmarks, data.bookmarks);
                }
            },
            hideMyNotes: function(data) {
                const { type ,id } = data;
                switch (type) {
                    case 'annotation':
                        this.notesList.annotations = this.notesList.annotations.filter(element => element.id !== id);
                        break;
                    case 'bookmark':
                        this.notesList.bookmarks = this.notesList.bookmarks.filter(element => element.id !== id);
                        break;
                }
            }
        },
        created() {
            if (!_.isUndefined(this.sectionId)) {
                EventBus.listen('update-note-list-'+this.sectionId, (item) => this.updateNoteList(item));
                EventBus.listen('remove-notes'+this.sectionId, (data)=> this.hideMyNotes(data));
            }
        },
    }
</script>

<style lang="scss">
    .notes-expansion-panel {
        .v-expansion-panel:before {
            box-shadow: unset!important;
        }
        .v-expansion-panel-content__wrap {
            padding: 0;
        }
    }
</style>
