<template>
    <div>
        <back-to-main-menu
            @backToMain="backToMain">
        </back-to-main-menu>
        <v-list-item class="mt-2">
            Explore Premium
        </v-list-item>
        <link-list-icons :links="links">
        </link-list-icons>
    </div>
</template>

<script>
    import BackToMainMenu from "./BackToMainMenu.vue";
    import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
    import LinkListIcons from "./LinkListIcons.vue";
    import {AppParameters} from "../../AppParameters";

    export default {
        name: 'PremiumFeaturesPanel',
        components: {LinkListIcons, BackToMainMenu},
        mixins: [megaMenuMixin],
        data() {
            return {
                links: [
                    {
                        icon: 'mdi-medal-outline',
                        title: 'Premium Features',
                        link: this.getRoute('premium_features'),
                        active: true
                    },
                    {
                        icon: 'mdi-navigation-outline',
                        title: 'AI Navigator',
                        chip: 'Beta',
                        link: this.getRoute('features_ai_navigator'),
                        active: AppParameters.ai_navigator_enabled
                    },
                    {
                        icon: 'mdi-forum-outline',
                        title: 'Premium Answers',
                        chip: 'New',
                        link: this.getRoute('premium_answers'),
                        active: AppParameters.enable_premium_answers_page
                    },
                    {
                        icon: 'mdi-calculator-variant-outline',
                        title: 'Compliance Calculators',
                        chip: 'New',
                        link: this.getRoute('compliance_calculators'),
                        active: AppParameters.compliance_calculators_enabled
                    },
                    {
                        icon: 'mdi-alpha-r-box-outline',
                        title: 'Revit Add-in',
                        link: this.getRoute('revit'),
                        active: true
                    },
                    {
                        icon: 'mdi-monitor-account',
                        title: 'Webinars',
                        link: this.getRoute('webinars'),
                        active: true
                    },
                    {
                        icon: 'mdi-currency-usd',
                        title: 'Pricing',
                        link: this.getRoute('pricing_page'),
                        new: true,
                        active: AppParameters.pricing_page_enabled
                    },
                ]
            }
        }
    }
</script>
