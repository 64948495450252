<template>
    <v-menu
        offset-y
        left
        open-on-click
        allow-overflow
        z-index="1005"
        max-width="500px">
        <template v-slot:activator="{ on: onMenu }">

          <v-tooltip bottom>

            <template v-slot:activator="{on: onTooltip}">
              <v-btn
                  data-qa="navigation-logo-menu"
                  icon
                  text
                  class="logo"
                  v-on="{...onMenu, ...onTooltip}">
                <v-icon color="black">mdi-apps</v-icon>
              </v-btn>
            </template>

            <span>More ICC Solutions</span>
          </v-tooltip>

        </template>
        <v-card class="menu-icons">
            <v-container fluid grid-list-sm>
                <v-layout row>
                    <v-flex>
                        <p class="text-center primary--text my-4 title font-weight-bold">ICC Family of Solutions</p>
                    </v-flex>
                </v-layout>
                <v-layout
                    row
                    wrap
                    class="text-center">
                    <v-flex
                        v-if="icons"
                        v-for="(icon, index) in icons"
                        :key="index"
                        xs4>
                        <v-card
                            :data-qa="'navigation-logo-menu-'+icon.fileSlug"
                            flat
                            :href="icon.link"
                            target="_blank"
                            class="py-2">
                            <v-card-text>
                                <img :src="asset(require('../../../images/icons/menu_'+icon.fileSlug+'.png'), 'default')"/>
                                <p class="mb-0">{{ icon.text }}</p>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-menu>
</template>

<script>
    import {AppParameters} from "../../../AppParameters";

    export default {
        data: function () {
            return {
                icons: [
                    {
                        fileSlug: 'home',
                        text: 'ICC Home',
                        link: 'https://www.iccsafe.org/'
                    },
                    {
                        fileSlug: 'my_icc',
                        text: 'myICC',
                        link: AppParameters.my_icc_url
                    },
                    {
                        fileSlug: 'online_codes',
                        text: 'Digital Codes',
                        link: 'https://codes.iccsafe.org/'
                    },
                    {
                        fileSlug: 'cdp',
                        text: 'cdpACCESS',
                        link: 'https://www.cdpaccess.com/'
                    },
                    {
                        fileSlug: 'store',
                        text: 'Store',
                        link: 'https://shop.iccsafe.org/'
                    },
                    {
                        fileSlug: 'support',
                        text: 'Support',
                        link: 'https://support.iccsafe.org/'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .menu-icons {
        img {
            width: 50px;
        }
    }
    @media all and (-ms-high-contrast:none)
    {
        *::-ms-backdrop, .menu-icons {
            min-width: 500px;
        }
    }
</style>
