<template>
    <a @click="callMapPage">
        <v-tooltip bottom content-class="map-tooltip">
            <template v-slot:activator="{ on }">
                <span v-on="on">
                    <p>Find Codes by Location</p>
                    <img :src="asset(require('../../images/landing_map.png'))"/>
                </span>
            </template>
            <span>Click to access the map search.</span>
        </v-tooltip>
    </a>
</template>

<script>
    export default {
        methods: {
            callMapPage() {
                return window.location = Routing.generate('map_search_page');
            }
        }
    }
</script>
