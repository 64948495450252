var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isMobilePort
    ? _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c("section-heading", {
                staticClass: "my-5",
                attrs: {
                  heading: "Premium features for the titles you use",
                  subHeading:
                    "Sign up for free and subscribe to access features on the titles you’re using regularly.",
                },
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "border-bottom" },
                _vm._l(_vm.columnHeadings, function (columnHeading, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      staticClass: "fs-20 font-weight-medium black--text",
                      class: key !== 0 ? "text-center" : "",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(columnHeading) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "border-bottom" },
                _vm._l(_vm.subHeading, function (subHeading, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      staticClass: "fs-16",
                      class: key !== 0 ? "text-center" : "",
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(subHeading) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.premiumFeatures, function (premiumFeature, key) {
                return _c(
                  "v-row",
                  {
                    key: key,
                    staticClass: "fs-16",
                    class:
                      key !== _vm.premiumFeatures.length - 1
                        ? "border-bottom"
                        : "",
                    attrs: { align: "center" },
                  },
                  [
                    _c("v-col", [
                      _vm._v(
                        "\n                " +
                          _vm._s(premiumFeature.title) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "text-center" },
                      [
                        premiumFeature.isReadOnly
                          ? _c("v-icon", { attrs: { color: "grey" } }, [
                              _vm._v(
                                "\n                    mdi-check-circle-outline\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "text-center" },
                      [
                        premiumFeature.isIndividualTitles
                          ? _c("v-icon", { attrs: { color: "teal" } }, [
                              _vm._v(
                                "\n                    mdi-check-circle-outline\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "text-center" },
                      [
                        premiumFeature.isPremiumBundleTitles
                          ? _c("v-icon", { attrs: { color: "teal" } }, [
                              _vm._v(
                                "\n                    mdi-check-circle-outline\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "text-center" },
                      [
                        premiumFeature.isPremiumComplete
                          ? _c("v-icon", { attrs: { color: "teal" } }, [
                              _vm._v(
                                "\n                    mdi-check-circle-outline\n                "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "my-9" },
                [
                  _c("v-col", { staticClass: "text-center fs-20" }, [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "*Feature only apply to the titles for which you have a Premium subscription"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { href: _vm.getRoute("premium_features") },
                      },
                      [
                        _vm._v(
                          "\n                    Learn more about Premium features\n                "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }