<template>
    <div>
        <back-to-main-menu
            @backToMain="backToMain">
        </back-to-main-menu>
        <v-list-item class="mt-2">
            Favorites
            <template v-if="!isActiveUser">
                <br/>
                Sign in to view your favorites
            </template>
        </v-list-item>
        <template v-if="isActiveUser">
            <link-list-icons :links="[{
                icon: 'mdi-heart-cog-outline',
                title: 'Manage Favorites',
                link: this.getRoute('favorites_landing'),
                active: true
            }]">
            </link-list-icons>
            <v-card
                v-if="favoritesLoading"
                flat>
                <v-card-text>
                    <v-container class="my-2">
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    indeterminate
                                    size="16"
                                    color="primary">
                                </v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <template v-else>
                <v-tooltip
                    v-for="(book, key) in favorites"
                    right
                    z-index="203"
                    :key="key">
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            :href="book.title.link"
                            v-on="on">
                            <v-list-item-title class="font-weight-bold">
                                {{ book.title.display_title }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>
                        {{ book.title.display_title }} <br/> {{ book.title.is_astm ? book.title.subtitle : book.title.print_version }}
                    </span>
                </v-tooltip>
            </template>
        </template>
        <v-list-item
            v-else
            :href="getLoginUrl()">
            <v-list-item-icon class="mr-2">
                <v-icon color="primary">
                    mdi-exit-to-app
                </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="fs-16 font-weight-bold">
                Sign In
            </v-list-item-title>
        </v-list-item>
    </div>
</template>

<script>
    import BackToMainMenu from "./BackToMainMenu.vue";
    import LinkListIcons from "./LinkListIcons.vue";
    import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
    import favorites from "../inc/mixins/favorites";

    export default {
        name: 'FavoritesPanel',
        components: {LinkListIcons, BackToMainMenu},
        mixins: [
            megaMenuMixin,
            favorites
        ]
    }
</script>
