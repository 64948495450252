<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h2 class="primary--text headline">
                        <span class="licenses-step-1">Purchases/Overview</span>
                    </h2>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="ml-1 mr-2 primary"
                        @click="refreshSubscriptions('Purchases')">
                        <span v-if="!isRefreshing">Refresh Licenses</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="caption mb-0">
                        My Purchases lists all Premium subscription licenses purchased through the ICC shop. Individual licenses can be configured for personal use, assigned to a specific individual or made concurrent for shared access.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-expansion-panels accordion>
                        <v-data-iterator
                            :items="subscriptions"
                            :items-per-page="pagination.itemsPerPage"
                            :loading="loading"
                            :single-expand="true"
                            :options.sync="pagination"
                            :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                            :server-items-length="pagination.totalItems"
                            class="elevation-0 no-margin parent-row">
                            <template v-slot:header>
                                <v-row>
                                    <v-col
                                        v-for="(header, index) in headers"
                                        :key="index"
                                        :class="{
                                            'col-6 ml-2': index === 0,
                                            'col-4': index === 1
                                        }"
                                        class="mr-2 caption font-weight-bold">
                                        {{header.text}}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:default="data">
                                <v-expansion-panel v-for="item in data.items" :key="item.id">
                                    <v-expansion-panel-header>
                                        <template v-slot:actions>
                                            <v-icon @click="checkPanelEvent(item)" color="primary">expand_more</v-icon>
                                        </template>
                                        <v-container pa-0 pr-4>
                                            <v-row align="center">
                                                <v-col :cols="6">
                                                    <v-row align="center">
                                                        <v-col sm="auto">
                                                            <a @click="bookLink(item.document_info)">
                                                                <graphic
                                                                    :title="item.document_info"
                                                                    thumbnail
                                                                    width="50px"
                                                                    >
                                                                </graphic>
                                                            </a>
                                                        </v-col>
                                                        <v-col>
                                                            <a @click="bookLink(item.document_info)">
                                                                <book-title
                                                                    :title="getBookTitle(item.document_info)"
                                                                    :print_version_edition="printEdition(item.document_info.print_version_edition.version)"
                                                                    :printing_text="item.document_info.print_info">
                                                                </book-title>
                                                            </a>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col>
                                                    <v-chip
                                                        label
                                                        color="grey lighten-4"
                                                        class="elevation-2"
                                                        @click="changeTabTo('PersonalUse')">
                                                        <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'available')}}</v-avatar>
                                                        Personal
                                                    </v-chip>
                                                </v-col>
                                                <v-col>
                                                    <v-chip
                                                        label
                                                        color="grey lighten-4"
                                                        class="elevation-2 mx-2"
                                                        @click="changeTabTo('AssignedByMe')">
                                                        <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'assigned')}}</v-avatar>
                                                        Assigned
                                                    </v-chip>
                                                </v-col>
                                                <v-col>
                                                    <v-chip
                                                        label
                                                        color="grey lighten-4"
                                                        class="elevation-2"
                                                        @click="changeTabTo('Concurrent')">
                                                        <v-avatar left color="grey lighten-3">{{licenseConfigurationTotal(item.group_licenses, 'concurrent')}}</v-avatar>
                                                        Concurrent
                                                    </v-chip>
                                                </v-col>
                                                <v-col>
                                                    {{item.book_sku}}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="grey lighten-4">
                                        <v-container class="pt-0 pb-0">
                                            <v-row>
                                                <v-data-table
                                                    :headers="subscription_details"
                                                    :items="item.group_licenses"
                                                    hide-default-footer
                                                    class="no-margin details-row">
                                                    <template v-slot:item="{ item }">
                                                        <tr class="lighten-5" :class="getStatusClass(item)">
                                                            <td class="subtitle-2">
                                                                {{item.licenses}}
                                                            </td>
                                                            <td class="subtitle-2">
                                                                <a @click="gotToMyeCodes">
                                                                    {{item.purchase_id}}
                                                                </a>
                                                            </td>
                                                            <td class="text-xs-left subtitle-2">
                                                                {{checkSubscriptionType(item.subscription_type)}}
                                                            </td>
                                                            <td class="text-xs-left subtitle-2">{{trimDate(item.purchase_date) | moment("MMMM Do, YYYY")}}</td>
                                                            <td class="text-xs-left subtitle-2">{{trimDate(item.expiration_date) | moment("MMMM Do, YYYY")}}</td>
                                                            <td class="text-xs-left subtitle-2">{{getSubscriptionStatus(item)}}</td>
                                                            <td class="text-xs-left subtitle-2">
                                                                <v-btn
                                                                    small
                                                                    mt-2
                                                                    @click="gotToMyeCodes">
                                                                    {{getSubscriptionActionLabel(item.subscription_type)}}
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                            <template slot="no-data">
                                <div
                                    v-if="subscriptions.length == 0 && !loading"
                                    row
                                    class="pt-12 text-md-center">
                                    <p>You have not purchased any Premium subscriptions.  <a @click="goToRoute('search_page')">Browse Titles</a></p>
                                </div>
                                <div
                                    v-else-if="subscriptions.length == 0"
                                    class="text-center pt-6">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </div>
                            </template>
                        </v-data-iterator>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Licenses from '../../inc/mixins/licenses.js';
    import Purchases from './mixins/purchases.js'
    import Graphic from "../../titles/cover/Graphic";

    export default {
        mixins: [Licenses, Purchases],
        components: {
            Graphic
        },
        data() {
            return {
                headers: [
                    {text: 'Book Title', align: 'left', value: 'di.title', expand: true},
                    {text: 'Licenses', align: 'center', value: 's.licenses'},
                    {text: 'SKU', align: 'left', value: 'di.sku'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false},
                ],
                subscription_details: [
                    {text: 'License', sortable: false},
                    {text: 'Transaction Id', align: 'left', value: 'purchase_id', expand: true},
                    {text: 'Subscription Type', align: 'left', value: 'subscription_type'},
                    {text: 'Purchase Date', align: 'left', value: 'purchase_date'},
                    {text: 'Expiration Date', align: 'left', value: 'expiration_date'},
                    {text: 'Subscription Status', align: 'left', value: 'status'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false}
                ],
                panel: []
            };
        },
        methods: {
            checkPanelEvent(panelKey){
                if(!this.panel.length){
                    this.panel = [...Array(this.subscriptions.length).keys()].map(_ => false);
                }
                let array = [];
                this.panel.forEach(function(item, key){
                    array[key] = (key === panelKey.index) ? !item : item;
                });

                this.panel = array;
            },
        },
        mounted() {
            this.getUserAllSubscriptions();
        }
    }

</script>
<style>
    .parent-row, .details-row {
        width: 100%;
    }
</style>
