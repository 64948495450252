import axios from 'axios';

export const CartApi = {
    getProductPrice(sku) {
        return axios.get(Routing.generate('get_product_price', {documentSku: sku}));
    },
    addToCart(data) {
        return axios.get(Routing.generate('create_cart_product', {product: data}));
    },
    cartItemsCount(){
        return axios.get(Routing.generate('cart_items_count'));
    },
    addMultipleItemsToCart(data) {
        return axios.post(Routing.generate('add_cart_products'), data)
    },
}
