<template>
    <div
        :style="containerCss">
        <div
            v-if='!freeView'
            class="px-3 py-2"
            ref="insightsInformationContainer">
            <template v-if="!loading">
                <v-row dense>
                    <v-col>
                        <v-select
                            :disabled="freeView"
                            class="insights-select white"
                            outlined
                            dense
                            v-model="selectedInsights"
                            :items="items"
                            :clearable="true"
                            chips
                            small-chips
                            deletable-chips
                            label="Filter By Insight Type:"
                            multiple
                            item-text="title"
                            item-value="key"
                            hide-details
                            :menu-props="{ bottom: true, offsetY: true }">
                        </v-select>
                        <p class="mb-0 font-weight-medium caption mt-3">
                            What are Insights?
                        </p>
                        <p class="caption mb-0">
                            Premium Code Insights give additional context to code sections for greater understanding. Applicable Insights are identified by the [P] icon.
                        </p>
                    </v-col>
                </v-row>
            </template>
            <v-row v-else>
                <v-col class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary">
                    </v-progress-circular>
                </v-col>
            </v-row>
        </div>
        <template v-if="!loading && !freeView">
            <v-divider>
            </v-divider>
            <v-treeview
                id="insights-treeview"
                class="overscroll-contain"
                color="primary"
                :items="filteredSections"
                item-children="subsections"
                item-text="title"
                item-key="key"
                style="overflow-y: scroll"
                expand-icon="mdi-chevron-down"
                open-on-click
                :open.sync="openItems">
                    <v-row
                        slot="label"
                        slot-scope="{ item, active }"
                        dense
                        align="center"
                        row
                        class="ma-0">
                        <v-col class="xs11 pa-0">
                            <p
                                class="mb-0 py-2 body-2 black--text cp font-weight-medium">
                                <a
                                    tabindex="0"
                                    class="toc-chapter-item-focused"
                                    @click="insightClick(item)"
                                    @keydown="handleKeydown($event, item)">
                                    {{ item.title }}
                                </a>
                            </p>
                        </v-col>
                    </v-row>
            </v-treeview>
        </template>
        <div
            v-if="freeView"
            class="pa-3">
            <trial-action
                v-if="freeView"
                title="Access Premium Code Insights"
                content="Utilize relevant code Insights such as Commentaries,
                Code Change Details, Hearing Videos, Key Changes, Code Interpretations and more.">
            </trial-action>
        </div>
    </div>
</template>

<script>
    import TrialAction from './TrialAction.vue';
    import {AsyncUtils} from "../../../util/AsyncUtils";

    export default {
        name: 'PanelInsights',
        components: {
            TrialAction
        },
        props: {
            documentInfo: {
                required: true,
                type: Object
            },
            freeView: {
                type: Boolean,
                required: false
            },
            codeInsightsData: {
                required: true,
                type: Object
            },
        },
        data() {
            return {
                loading: true,
                selectedInsights: [],
                sections: [],
                items: [],
                chapterWrapHeaderHeight: 0,
                openItems: []
            }
        },
        computed: {
            filteredSections() {
                let _this = this;
                let sections = JSON.parse(JSON.stringify(this.sections));
                if (this.selectedInsights.length) {
                    sections = sections.map(obj => {
                        obj.subsections = obj.subsections.filter(sub => {
                            let hasKey = false;
                            for (const [key, value] of Object.entries(sub.types)) {
                                if (_this.selectedInsights.includes(value.id)) {
                                    hasKey = true;
                                    break;
                                }
                            }
                            return hasKey;
                        });

                        return obj;
                    });

                    sections = sections.filter(obj => obj.subsections.length > 0);
                }
                return sections;
            },
            filterHintText() {
                let text = 'Showing all sections across active title with an Insight'
                if (this.selectedInsights.length) {
                    let selected = []
                    this.selectedInsights.forEach((insightId) => {
                        const item = this.items.find((item) => {
                            return item.key === insightId
                        })
                        if (item) {
                            selected.push(item.title)
                        }
                    })
                    text = 'Showing section with "'+selected.join(", ")+'" Insights'
                }
                return text
            },
            containerCss() {
                return {
                    background: this.$vuetify.theme.themes.light.primaryLight,
                    height: `calc(100% - ${this.chapterWrapHeaderHeight}px)`,
                }
            }
        },
        methods: {
            getInsights() {
                this.loading = true;
                const results = this.codeInsightsData;
                for (const [key, value] of Object.entries(results.data.sections)) {
                    this.sections.push(
                        {
                            title: value.title,
                            subsections: value.sub_sections,
                            key: key
                        }
                    )
                }
                for (const [key, value] of Object.entries(results.data.premiumContentTypes)) {
                    this.items.push(
                        {
                            title: value.title,
                            key: value.id
                        }
                    )
                }
                this.loading = false
            },
            insightClick(data) {
                if (data.sectionId) {
                    const insightData = {
                        section_number: String(data.sectionId),
                        parentContentID: data.chapterId,
                        content_id: data.sectionId,
                        codeInsightFilter: true,
                        link: {
                            id: String(data.sectionId),
                            title: data.title
                        }
                    }
                    EventBus.fire('toggle-mobile-legend', false);
                    EventBus.fire('show-book-content', insightData);
                }
            },
            handleKeydown(event, item) {
                if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault();
                    this.insightClick(item);
                    // Toggle the expanded state
                    const index = this.openItems.indexOf(item.key);
                    if (index === -1) {
                        this.openItems.push(item.key);
                    } else {
                        this.openItems.splice(index, 1);
                    }
                }
            }
        },
        mounted() {
            this.getInsights();

            AsyncUtils.setIntervalOnce(() => {
                this.chapterWrapHeaderHeight = document.querySelector('#chapter-wrap-header').offsetHeight;
            }, 500, "insights:height")
        }
    }
</script>
