var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", { staticClass: "oxygen text-center font-weight-light mb-8" }, [
        _vm._v("Frequently Asked Questions"),
      ]),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        { staticClass: "roboto", attrs: { flat: "" } },
        _vm._l(_vm.questions, function (item, i) {
          return _c(
            "v-expansion-panel",
            { key: i, staticClass: "py-2 border-bottom" },
            [
              _c(
                "v-expansion-panel-header",
                {
                  staticClass: "primary--text text-uppercase font-weight-bold",
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.title) + "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-expansion-panel-content", [
                _c("p", {
                  staticClass: "mb-0",
                  domProps: { innerHTML: _vm._s(item.text) },
                }),
                _vm._v(" "),
                item.slug === `digital-codes-premium`
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToRoute("collections_landing", {
                              documentSlug: "premium-complete",
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Learn more\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.slug === `digital-codes-premium-complete`
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToRoute("collections_landing", {
                              documentSlug: "premium-complete",
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Click here for more details\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.slug === `digital-codes-contents`
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToRoute("codes")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Learn more\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                item.slug === `digital-codes-premium-mobile`
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: "https://play.google.com/store/apps/details?id=org.iccsafe.codes",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Learn more\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }