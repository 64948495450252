var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container" },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("img", {
              staticClass: "mr-9",
              staticStyle: { "max-width": "400px" },
              attrs: {
                src: _vm.asset(
                  require("../../../images/illistrations/gear_idea.webp")
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "h2",
                      { staticClass: "font-weight-regular primary--text" },
                      [
                        _vm._v(
                          "\n                        Unlock exclusive tools and features with "
                        ),
                        _c("strong", [_vm._v("Premium Complete")]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.items, function (item, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      staticClass: "py-2",
                      attrs: { cols: "12", sm: "6" },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-3",
                                  attrs: { color: "primary" },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(item.icon) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "text-decoration-underline font-weight-bold",
                                attrs: { href: item.link, target: "_blank" },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.text) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("v-row", [_c("v-col", [_vm._t("button")], 2)], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }