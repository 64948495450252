var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "primary white--text",
              class: {
                headline: !_vm.isMobilePort,
                "caption mobile-title": _vm.isMobilePort,
              },
            },
            [
              _vm._v("\n            Recent Updates\n            "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "accent2--text dark",
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("\n                close\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              !_vm.loading
                ? _c(
                    "v-tabs",
                    {
                      attrs: {
                        vertical: "",
                        "background-color": "grey lighten-3",
                        "active-class": "primary--text grey lighten-4",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm._l(_vm.updates, function (item, key) {
                        return _c("v-tab", { key: key }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.title) +
                              "\n                "
                          ),
                        ])
                      }),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.updates, function (item, key) {
                          return _c(
                            "v-tab-item",
                            { key: key },
                            [
                              _c(
                                "v-card",
                                {
                                  staticStyle: { "overflow-y": "scroll" },
                                  attrs: { flat: "", "max-height": "400" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "py-2" },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-col", [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "primary--text mb-0",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm._f("moment")(
                                                        item.publishDate,
                                                        "MMMM Do, YYYY"
                                                      )
                                                    ) +
                                                    "\n                                        "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c("v-col", [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.description
                                                ),
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              item.link
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: item.link.href,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(
                                                            item.link.title
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }