var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "v-alert",
        {
          staticClass: "actionable-alert",
          attrs: {
            text: "",
            color: "blue",
            border: "left",
            dense: "",
            dismissible: "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "close",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", color: "black" },
                        on: { click: _vm.clickedClose },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            506783881
          ),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column flex-md-row align-center black--text",
            },
            [
              _c(
                "div",
                { staticClass: "mr-md-5 mb-md-0 mb-2" },
                [_vm._t("text")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text mr-1 font-weight-bold",
                      attrs: { color: "blue darken-4" },
                      on: { click: _vm.actionClicked },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.actionText ?? "Take Action") +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { color: "blue darken-4", outlined: "" },
                      on: { click: _vm.dismissClicked },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.dismissText ?? "Dismiss") +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }