import {DomUtils} from "./util/DomUtils";
import {AsyncUtils} from "./util/AsyncUtils";

export const isLocalEnvironment = () => {
    return DomUtils.getMetaContents("serverEnvironment") === "localhost";
}

export const isProductionEnvironment = () => {
    return DomUtils.getMetaContents("serverEnvironment") === "production";
}

export const openLiveChat = () => {

    // livechat script already loaded
    if (typeof phplive_launch_chat_0 === 'function') {
        eval("phplive_launch_chat_0()");
    } else {

        AsyncUtils.loadScriptAsync("//livechat.iccsafe.org//js/phplive_v2.js.php?v=0|1440692297|1|").then(() => {

            // cannot assume script all been parsed yet - wait until this variable is seen
            AsyncUtils.awaitUntil(() => {
                return typeof phplive_launch_chat_0 === 'function' && typeof phplive_jquery === 'function';
            }, 100).then(() => {
                eval("phplive_launch_chat_0()");
            });

        });
    }
}

// ignore errors when decoding invalid base64
export const base64DecodeSafe = (encodedData, fallbackValue = null) => {

    try {
        return atob(encodedData);
    } catch (e) {
        console.error(e);
    }

    return fallbackValue;
}
