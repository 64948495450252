var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.accessDescriptors[_vm.accessLevel]
    ? _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-avatar",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              color:
                                _vm.accessDescriptors[_vm.accessLevel]
                                  .backGroundColor,
                              size: "24",
                            },
                          },
                          "v-avatar",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: "",
                              color:
                                _vm.accessDescriptors[_vm.accessLevel]
                                  .iconColor,
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.accessDescriptors[_vm.accessLevel].icon
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            189308622
          ),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(_vm._s(_vm.accessDescriptors[_vm.accessLevel].description)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }