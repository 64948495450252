var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: {
        "lazy-validation": "",
        id: "login_form",
        method: "post",
        action: _vm.formAction,
      },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.loginSubmit.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _vm.referer != ""
        ? _c("input", {
            attrs: { type: "hidden", name: "_target_path" },
            domProps: { value: _vm.referer },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.token,
            expression: "token",
          },
        ],
        attrs: { type: "hidden", name: "_csrf_token" },
        domProps: { value: _vm.token },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.token = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-text-field",
                {
                  class: { "input-group--dirty": _vm.dirtyEmail },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "emailAddress",
                    name: "email",
                    maxlength: "160",
                    type: "email",
                    readonly: _vm.isEnterprise,
                    error: _vm.emailAddressHasError,
                    rules: [_vm.rules.required, _vm.validEmail],
                    placeholder: "Email Address",
                    required: "",
                  },
                  on: { keyup: _vm.keyMonitor, blur: _vm.handleEmail },
                  model: {
                    value: _vm.emailAddress,
                    callback: function ($$v) {
                      _vm.emailAddress = $$v
                    },
                    expression: "emailAddress",
                  },
                },
                [
                  _vm.emailAddressHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-text-field",
                {
                  staticClass: "pass",
                  class: { "input-group--dirty": _vm.dirtyPassword },
                  attrs: {
                    outlined: "",
                    "hide-details": "",
                    id: "password",
                    name: "password",
                    maxlength: "50",
                    type: "password",
                    error: _vm.passwordHasError,
                    rules: [_vm.rules.required],
                    placeholder: "Password",
                    required: "",
                  },
                  on: { blur: _vm.checkError, keyup: _vm.keyMonitor },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                },
                [
                  _vm.passwordHasError
                    ? _c(
                        "v-icon",
                        {
                          attrs: { slot: "append", color: "red" },
                          slot: "append",
                        },
                        [
                          _vm._v(
                            "\n                    mdi-alert\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loginFailedCount >= 3
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "p",
                    {
                      staticClass: "mb-3",
                      class: _vm.isEnterprise ? "font-weight-bold" : "",
                    },
                    [_vm._v("\n                Spam Protection\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-text-field",
                    {
                      staticClass: "spam",
                      attrs: {
                        outlined: "",
                        "hide-details": "",
                        id: "spamProtection",
                        name: "spamProtection",
                        label: "What is " + _vm.captcha + "?",
                        error: _vm.captchaHasError,
                        rules: [_vm.rules.required],
                        required: "",
                      },
                      on: { blur: _vm.captchaCheckError },
                      model: {
                        value: _vm.spamProtection,
                        callback: function ($$v) {
                          _vm.spamProtection = $$v
                        },
                        expression: "spamProtection",
                      },
                    },
                    [
                      _vm.captchaHasError
                        ? _c(
                            "v-icon",
                            {
                              attrs: { slot: "append", color: "red" },
                              slot: "append",
                            },
                            [
                              _vm._v(
                                "\n                    mdi-alert\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-col",
            {
              attrs: {
                order: this.isEnterprise ? 2 : "",
                cols: this.isEnterprise && 12,
              },
            },
            [
              _c("input", {
                staticClass: "mr-1",
                attrs: {
                  id: "rememberMe",
                  type: "checkbox",
                  name: "rememberMe",
                  value: "1",
                },
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  class: this.isEnterprise ? "font-weight-bold" : "caption",
                  attrs: { for: "rememberMe" },
                },
                [
                  _vm._v(
                    "\n                Keep me logged in for 30 days\n            "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto", order: this.isEnterprise ? 1 : "" } },
            [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getRoute("request_reset"),
                    target: "_blank",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      class: this.isEnterprise
                        ? "text-decoration-underline"
                        : "caption mb-0",
                    },
                    [
                      _vm._v(
                        "\n                    Forgot your Password?\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    large: "",
                    color: "primary",
                    disabled: _vm.disabled,
                  },
                  on: { click: _vm.loginSubmit },
                },
                [
                  !_vm.disabled
                    ? [_c("b", [_vm._v("Sign In")])]
                    : _c("v-progress-circular", {
                        attrs: { size: "16", indeterminate: "" },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }