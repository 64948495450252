<template>
    <v-card
        outlined>
        <v-card-title
            class="primaryLight text-center py-2"
            style="word-break: normal">
            <v-row dense>
                <v-col>
                    <img
                        width="auto"
                        height="90"
                        :src="asset(require('../../images/banners/premium_answers.png'))"
                        alt="PREMIUMANSWERS"
                        title="premiumanswers">
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pt-4">
            <p>
                Premium Answers delivers a constantly expanding searchable database of questions and their carefully crafted interpretations from the ICC team of technical experts. Support for expedited custom questions is upcoming.
            </p>
            <v-row
                dense
                v-for="(item, key) in blurbs"
                :key="key">
                <v-col cols="auto">
                    <v-icon color="primary">
                        mdi-check
                    </v-icon>
                </v-col>
                <v-col>
                    <p class="mb-0">
                        {{item}}
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'WhatIsPremiumAnswers',
        data() {
            return {
                blurbs: [
                    'Real opinions from ICC experts',
                    'Additional questions added regularly',
                    'Useful links to relevant code sections',
                    'Broad collection of topics'
                ]
            }
        }
    }
</script>
