var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _vm.showYears
        ? _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-select", {
                attrs: {
                  outlined: "",
                  dense: "",
                  items: _vm.availableYears,
                  "hide-details": "",
                  clearable: "",
                  loading: _vm.yearsLoading,
                  disabled: _vm.yearsLoading,
                  label: "Year",
                  "data-aq": "find-codes-year-filter",
                },
                on: {
                  change: function ($event) {
                    return _vm.updateYear()
                  },
                },
                model: {
                  value: _vm.yearSelected,
                  callback: function ($$v) {
                    _vm.yearSelected = $$v
                  },
                  expression: "yearSelected",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }