var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container", attrs: { id: "pricing_table" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("section-heading", {
                attrs: {
                  heading: "Build your Premium subscription bundle",
                  subHeading:
                    "Digital Codes subscriptions work on a title by title basis.\n                        Subscribe to Premium by Single, Collection, Complete or a combination based on your needs.",
                },
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "fs-20 mb-0" }, [
                      _vm._v("Search or filter titles"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobilePort ? 12 : 6 } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          id: "price_search",
                          clearable: "",
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          "append-icon": "mdi-magnify",
                          autocomplete: "off",
                          label: "Search Titles",
                        },
                        on: {
                          "click:clear": _vm.clickedClear,
                          keydown: _vm.keydown,
                          "click:append": _vm.performSearch,
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobilePort ? 6 : 3 } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "hide-details": "",
                          clearable: "",
                          dense: "",
                          "item-text": "name",
                          "item-value": "name",
                          outlined: "",
                          items: _vm.sortedCategories,
                          label: "Category",
                          multiple: "",
                        },
                        on: { change: _vm.debouncedCategoryYear },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-action",
                                      { staticClass: "mr-2" },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            value:
                                              _vm.selectedCategories.includes(
                                                data.item.name
                                              ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.toggleCategoryCheckbox(
                                                data.item.name
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            data.item.child
                                              ? [_vm._v("––")]
                                              : _vm._e(),
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.name) +
                                                "\n                                    "
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedCategories,
                          callback: function ($$v) {
                            _vm.selectedCategories = $$v
                          },
                          expression: "selectedCategories",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobilePort ? 6 : 3 } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "hide-details": "",
                          clearable: "",
                          "item-text": "name",
                          "item-value": "name",
                          dense: "",
                          outlined: "",
                          items: _vm.years,
                          label: "Year",
                          multiple: "",
                        },
                        on: { change: _vm.debouncedCategoryYear },
                        model: {
                          value: _vm.selectedYears,
                          callback: function ($$v) {
                            _vm.selectedYears = $$v
                          },
                          expression: "selectedYears",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          attrs: { mandatory: "" },
                          model: {
                            value: _vm.activeTab,
                            callback: function ($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab",
                          },
                        },
                        _vm._l(_vm.filters, function (name, index) {
                          return _c(
                            "v-btn",
                            {
                              key: index,
                              staticClass: "fs-14 font-weight-bold",
                              class:
                                _vm.activeTab === index
                                  ? "primary white--text"
                                  : "primary--text btn-group-background",
                              style: `border: 1px solid ${_vm.$vuetify.theme.themes.light.primary}!important;`,
                              attrs: { height: "40" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectedTab(name)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "" },
                          on: { click: _vm.clearFilters },
                        },
                        [
                          _vm._v(
                            "\n                        Clear All\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isLoading && this.pagination.totalResults > 0
                ? _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "fs-16 mb-0 pa-1" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(this.pagination.totalResults) +
                              " titles " +
                              _vm._s(!_vm.hasInput ? "(year descending)" : "") +
                              "\n                    "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobileAndTablet
                ? [
                    _vm.isLoading
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "text-center" },
                              [
                                _c("v-progress-circular", {
                                  attrs: { indeterminate: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.titles.length > 0
                      ? _vm._l(_vm.titles, function (title, index) {
                          return _c(
                            "v-row",
                            {
                              key: index,
                              staticClass: "mx-0",
                              class: {
                                "unset-border": index !== 0,
                                "selected-row-background":
                                  _vm.isTitleInCart(title),
                              },
                              staticStyle: { border: "1px solid #E0E0E0" },
                              attrs: { hover: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.addItemToCart(title)
                                },
                              },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold fs-14",
                                  attrs: { cols: "4" },
                                },
                                [_vm._v("Titles")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-0 fs-14 text-right",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(title.display_title) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          title.is_collection
                                            ? [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 grey--text text--darken-1 fs-14",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          title.collection_item_count
                                                        ) +
                                                        " titles\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold fs-14",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _vm._v(
                                    "\n                        Price Per License (" +
                                      _vm._s(_vm.billingPeriodText) +
                                      ")\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "8" },
                                },
                                [
                                  title.pricing
                                    ? [
                                        _vm._v(
                                          "\n                            $" +
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                _vm.displayPrice(title)
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                            Unavailable\n                        "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold fs-14",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _vm._v(
                                    "\n                        Actions\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "8" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    {
                                                      attrs: { color: "black" },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v("mdi-dots-vertical")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.redirectTo(title)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("Open Title"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "black" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.addItemToCart(title)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            !_vm.isTitleInCart(title)
                                              ? "mdi-plus"
                                              : "mdi-check"
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "v-row",
                          [
                            _c("v-col", { staticClass: "text-center" }, [
                              _c("p", [_vm._v("No titles found")]),
                            ]),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c(
                      "v-dialog",
                      {
                        attrs: { scrollable: "", fullscreen: true },
                        model: {
                          value: _vm.getShowCartQue,
                          callback: function ($$v) {
                            _vm.getShowCartQue = $$v
                          },
                          expression: "getShowCartQue",
                        },
                      },
                      [_c("cart-que")],
                      1
                    ),
                  ]
                : [
                    _vm.isLoading
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { outlined: "" },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [_c("loading-linear")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : [
                          _vm.titles.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      staticStyle: {
                                        border: "1px solid #E0E0E0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "font-weight-bold fs-14 grey--text text--darken-1",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Title\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "font-weight-bold fs-14 grey--text text--darken-1 text-right",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Price Per License (" +
                                              _vm._s(_vm.billingPeriodText) +
                                              ")\n                                "
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                                            mdi-information-outline\n                                        "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4051426139
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "Price is discounted for Annual and 3-year subscriptions"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center font-weight-bold fs-14 grey--text text--darken-1",
                                          attrs: { cols: "1" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Actions\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.titles, function (title, index) {
                                    return _c(
                                      "v-row",
                                      {
                                        key: index,
                                        staticClass:
                                          "mx-0 result-row unset-border cp",
                                        class: {
                                          "selected-row-background":
                                            _vm.isTitleInCart(title),
                                        },
                                        staticStyle: {
                                          border: "1px solid #E0E0E0",
                                        },
                                        attrs: { align: "center", hover: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.hasPrice(title) === false
                                              ? null
                                              : _vm.addItemToCart(title)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "d-flex",
                                            attrs: { cols: "8" },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                attrs: {
                                                  dense: "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "auto" } },
                                                  [
                                                    _c("graphic", {
                                                      attrs: {
                                                        additionalClasses:
                                                          "d-flex",
                                                        title: title,
                                                        thumbnail: "",
                                                        displayOnlyTopicIcon: true,
                                                        width: "24px",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "auto" } },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 fs-14",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              title.display_title
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    title.is_collection
                                                      ? [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-0 grey--text text--darken-1 fs-14",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                " +
                                                                  _vm._s(
                                                                    title.collection_item_count
                                                                  ) +
                                                                  " titles\n                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                                attrs,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "a",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          href: title.link,
                                                                          target:
                                                                            "_blank",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "pl-2",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  color:
                                                                                    "black",
                                                                                },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                        mdi-open-in-new\n                                                    "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "Open " +
                                                              _vm._s(
                                                                title.display_title
                                                              ) +
                                                              " in a new tab"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { staticClass: "text-right" },
                                          [
                                            _vm.hasPrice(title)
                                              ? [
                                                  _vm._v(
                                                    "\n                                    $" +
                                                      _vm._s(
                                                        _vm._f("formatPrice")(
                                                          _vm.displayPrice(
                                                            title
                                                          )
                                                        )
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.displayPrice(title)
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "1" },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    _vm.hasPrice(
                                                                      title
                                                                    ) === false,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.addItemToCart(
                                                                        title
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "black",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        !_vm.isTitleInCart(
                                                                          title
                                                                        )
                                                                          ? "mdi-plus"
                                                                          : "mdi-check"
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(" "),
                                                _vm.hasPrice(title)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "\n                                        " +
                                                          _vm._s(
                                                            !_vm.isTitleInCart(
                                                              title
                                                            )
                                                              ? "Add to your Premium package"
                                                              : "Added in your Premium package"
                                                          ) +
                                                          "\n                                    "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "text-center" }, [
                                    _c("p", [_vm._v("No titles found")]),
                                  ]),
                                ],
                                1
                              ),
                        ],
                  ],
              _vm._v(" "),
              _vm.page < _vm.pagination.maxPerPage && !_vm.isLoading
                ? _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                text: "",
                                color: "primary",
                                loading: _vm.isLoadingMore,
                              },
                              on: { click: _vm.showMore },
                            },
                            [
                              _vm._v(
                                "\n                        Show More\n                        "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v(
                                  "\n                            mdi-chevron-down\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center fs-20" }, [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "Need help choosing a subscription? We’re here to help"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          href: `mailto:${this.$getConst(
                            "sales_email_address"
                          )}`,
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Contact our sales team for assistance\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bundle-count-snack-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.getShowCartQue,
            expression: "!getShowCartQue",
          },
        ],
        attrs: { "item-count": _vm.addedToCartItems.length },
        on: { openPremiumBundle: _vm.openPremiumBundle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }