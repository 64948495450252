var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPremiumInsights
    ? _c(
        "div",
        {
          staticClass: "mb-4 px-2 premium-tag-category-item",
          attrs: { id: `tag-category-${_vm.contentId}` },
        },
        [
          _c(
            "div",
            { staticClass: "scrollable-container" },
            [
              _c(
                "v-row",
                {
                  staticClass: "scrollable-row",
                  attrs: { dense: "", align: "center" },
                },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      {
                        staticClass: "pb-0 caption mb-0 text-left black--text",
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.activeTag.title) +
                            " :\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.categories, function (item, key) {
                    return item.active
                      ? _c(
                          "v-col",
                          { key: key, attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  bottom: "",
                                  "z-index": "199",
                                  "max-width": "300",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "cp",
                                                  attrs: {
                                                    label: "",
                                                    color: "grey lighten-5",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectCategory(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                "v-chip",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              item?.icon
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        size: "20",
                                                        color: "grey lighten-1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            mdi-" +
                                                          _vm._s(item.icon) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "caption black--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                            " +
                                                      _vm._s(item.name) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(item.description) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }