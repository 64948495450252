import moment from 'moment'

export class DateUtil {

    static SECONDS_IN_HOUR = 60 * 60;
    static SECONDS_IN_SIX_HOURS = 60 * 60 * 4;
    static SECONDS_IN_DAY = 60 * 60 * 24;
    static SECONDS_IN_WEEK = DateUtil.SECONDS_IN_DAY * 7;

    static MILLISECONDS_IN_MINUTE = 60 * 1000;

    /**
     *
     * @return {number}
     */
    static timestampInMilliseconds() {
        return (new Date()).getTime();
    }

    /**
     *
     * @return {number}
     */
    static timestampInSeconds() {
        let seconds = new Date().getTime() / 1000;
        return Math.ceil(seconds);
    }

    /**
     *
     * @param timestamp
     * @return {boolean}
     */
    static isTimestampInPast(timestamp) {
        let now = this.timestampInSeconds();
        return timestamp < now;
    }

    /**
     *
     * @param {string|number} dateString
     * @returns {string}
     */
    static timeToMdy(dateString) {
        dateString = typeof dateString === 'number' ? dateString : (new Date(dateString));
        return moment(dateString).format("MMMM Do, YYYY");
    }

    // returns POSITIVE number, if date passed it is in the past
    static differenceInSeconds(date) {
        const dateObj = typeof date === 'string' ? (new Date(date)) : date;
        const now = new Date();

        if (dateObj) {
            const diff = now.getTime() - dateObj.getTime();
            return Math.floor(diff / 1000);
        }

        return null;
    }
}
