var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showMessage,
          expression: "showMessage",
        },
      ],
      staticClass: "text-sm",
      class: { "red--text text--accent-4": !_vm.countdownremaining },
    },
    [
      _vm._v("\n    " + _vm._s(_vm.countDownMessage) + "\n    "),
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-4",
                          class: {
                            "red--text text--accent-4": !_vm.countdownremaining,
                          },
                        },
                        "v-icon",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        "\n                mdi-information-outline\n            "
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.countdownremaining
            ? _c("span", [
                _vm._v(
                  "\n            ICC AI Navigator allows users to ask up to 100 questions\n            across a 14 day period."
                ),
                _c("br"),
                _vm._v(
                  "\n            You have " +
                    _vm._s(_vm.countdownremaining) +
                    " questions remaining within the current period."
                ),
                _c("br"),
                _vm._v(
                  "\n            The next period begins on " +
                    _vm._s(_vm.countdownReset) +
                    " with allowance\n            of up to 100 additional questions.\n        "
                ),
              ])
            : _c("span", [
                _vm._v(
                  "\n            ICC AI Navigator allows user to ask 100 questions across\n            each 14 day period."
                ),
                _c("br"),
                _vm._v(
                  "\n            You have used all of your allowed questions for this period,\n            but will be granted"
                ),
                _c("br"),
                _vm._v(
                  "\n            up to 100 additional questions when the\n            next period begins on " +
                    _vm._s(_vm.countdownReset) +
                    ".\n        "
                ),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }