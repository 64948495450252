var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700" },
          model: {
            value: _vm.modal,
            callback: function ($$v) {
              _vm.modal = $$v
            },
            expression: "modal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v("\n                Bookmark / Note\n                "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 accent2--text",
                      attrs: { small: "" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("vue-editor", {
                            attrs: { "editor-toolbar": _vm.customToolbar },
                            model: {
                              value: _vm.editorData,
                              callback: function ($$v) {
                                _vm.editorData = $$v
                              },
                              expression: "editorData",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("Select Existing Project Tag"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      !_vm.loadingTags
                        ? [
                            _c(
                              "v-col",
                              [
                                _c("v-select", {
                                  attrs: {
                                    "hide-details": "",
                                    items: _vm.tags,
                                    "item-text": "tag",
                                    "item-value": "id",
                                    chips: "",
                                    label: "Select Tag from the list",
                                    solo: "",
                                  },
                                  model: {
                                    value: _vm.selectedTag,
                                    callback: function ($$v) {
                                      _vm.selectedTag = $$v
                                    },
                                    expression: "selectedTag",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary",
                                    on: {
                                      click: function ($event) {
                                        return _vm.createNewTagModal(
                                          _vm.isUpdate,
                                          _vm.selectedTag
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.isUpdate
                                      ? _c("span", [_vm._v("Edit Tag")])
                                      : _c("span", [_vm._v("New Tag")]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "v-col",
                              [
                                _c("v-progress-linear", {
                                  attrs: { indeterminate: true },
                                }),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.closeModal } },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.saveBookmark },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.removeModal,
            callback: function ($$v) {
              _vm.removeModal = $$v
            },
            expression: "removeModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "primary white--text",
                  class: {
                    headline: !_vm.isMobilePort,
                    "caption mobile-title": _vm.isMobilePort,
                  },
                },
                [
                  _vm._v("\n                Remove Bookmark\n                "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2 accent2--text",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          _vm.removeModal = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "mb-0 mt-5" }, [
                          _vm._v(
                            "Are you sure you want to remove this bookmark?"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.removeModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteBookmark },
                    },
                    [_vm._v("Remove")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.invalidNoteUrl,
            callback: function ($$v) {
              _vm.invalidNoteUrl = $$v
            },
            expression: "invalidNoteUrl",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("Invalid URL"),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n                Your note contains an Invalid URL?\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: {
                        click: function ($event) {
                          _vm.invalidNoteUrl = false
                        },
                      },
                    },
                    [_vm._v("Ok")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }