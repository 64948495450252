<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <h2 class="primary--text headline">
                        <span class="licenses-step-2">Personal Use</span>
                    </h2>
                </v-col>
                <v-col md="auto">
                    <v-btn
                        class="ml-1 mr-2 primary"
                        @click="refreshSubscriptions('Purchases')">
                        <span v-if="!isRefreshing">Refresh Licenses</span>
                        <v-progress-circular v-else :size="20" indeterminate color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="caption mb-0">
                        Personal Use licenses are those where the individual who purchases the premium subscription is the individual who is able to use the premium subscription.  Personal use licenses provide dedicated access to the individual as identified by the email address, but can be configured to be assigned to another individual or made concurrent.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-expansion-panels accordion>
                        <v-data-iterator
                            :items="subscriptions"
                            :items-per-page="pagination.itemsPerPage"
                            :loading="loading"
                            :single-expand="true"
                            :options.sync="pagination"
                            :server-items-length="pagination.totalItems"
                            :footer-props="{itemsPerPageOptions: pagination.rowsPerPageItems}"
                            class="elevation-0 no-margin parent-row">
                            <template v-slot:header>
                                <v-row>
                                    <v-col
                                        v-for="(header, index) in headers"
                                        :key="index"
                                        :class="{
                                            'ml-2': index === 0,
                                            'col-1': index === 1
                                        }"
                                        class="mr-2 caption font-weight-bold">
                                        {{header.text}}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:default="data">
                                <v-expansion-panel v-for="item in data.items" :key="item.id">
                                    <v-expansion-panel-header>
                                        <template v-slot:actions>
                                            <v-icon @click="checkPanelEvent(item)" color="primary">expand_more</v-icon>
                                        </template>
                                        <v-container pa-0 pr-4>
                                            <v-row align="center">
                                                <v-col md="auto">
                                                    <v-row align="center">
                                                        <v-col sm="auto">
                                                            <a @click="bookLink(item.document_info)">
                                                                <graphic
                                                                    :title="item.document_info"
                                                                    thumbnail
                                                                    width="50px"
                                                                    >
                                                                </graphic>
                                                            </a>
                                                        </v-col>
                                                        <v-col>
                                                            <a @click="bookLink(item.document_info)">
                                                                <book-title
                                                                    :title="getBookTitle(item.document_info)"
                                                                    :print_version_edition="printEdition(item.document_info.print_version_edition.version)"
                                                                    :printing_text="item.document_info.print_info">
                                                                </book-title>
                                                            </a>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="grey lighten-4">
                                        <v-container class="pt-0 pb-0">
                                            <v-row>
                                                <v-data-table
                                                    :headers="license"
                                                    :items="item.group_licenses"
                                                    hide-default-footer
                                                    class="no-margin details-row">
                                                    <template v-slot:item="{ item }">
                                                        <tr class="lighten-5" :class="getStatusClass(item)">
                                                            <td class="subtitle-2">{{item.license_configuration.available}}</td>
                                                            <td class="subtitle-2">{{checkSubscriptionType(item.subscription_type)}}</td>
                                                            <td class="subtitle-2">{{item.purchase_date | moment("MMMM Do, YYYY") }}</td>
                                                            <td class="subtitle-2">{{item.expiration_date | moment("MMMM Do, YYYY")}}</td>
                                                            <td class="subtitle-2">{{getSubscriptionStatus(item)}}</td>
                                                            <td align="center">
                                                                <v-btn
                                                                    small
                                                                    @click="goToTheShop(shopEncodesUrl)">
                                                                    <span v-if="item.subscription_type === 'M'">Manage</span>
                                                                    <span v-else>Renew</span>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-row>
                                        </v-container>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </template>
                            <template slot="no-data">
                                <div
                                    v-if="subscriptions.length == 0 && !loading"
                                    row
                                    class="pt-12 text-md-center">
                                    <p>No results found!</p>
                                </div>
                                <div
                                    v-else-if="subscriptions.length == 0"
                                    class="text-center pt-6">
                                    <v-progress-circular
                                        indeterminate
                                        color="primary">
                                    </v-progress-circular>
                                </div>
                            </template>
                        </v-data-iterator>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import InfoBanner from '../../banners/info_banner';
    import Licenses from '../../inc/mixins/licenses.js';
    import PersonalUse from './mixins/personal_use.js';
    import Purchases from './mixins/purchases.js';
    import Graphic from "../../titles/cover/Graphic";
    import Constants from "../../../constants/index";

    export default {
        mixins: [Licenses, PersonalUse, Purchases],
        data() {
            return {
                headers: [
                    {text: 'Book Title', align: 'left', value: 'di.title'},
                    // {text: 'Available Licenses', align: 'right', value: 'available_licenses'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false}
                ],
                license: [
                    {text: 'License', align: 'left', value: 'available_license'},
                    {text: 'Subscription Type', value: 'subscription_type'},
                    {text: 'Purchase Date', value: 'purchase_date'},
                    {text: 'Expiration Date', value: 'expiration_date'},
                    {text: 'Subscription Status', value: 'subscription_status'},
                    {text: 'Actions', align: 'center', value: 'name', sortable: false}
                ],
                panel: [],
                shopEncodesUrl: Constants.shopEncodesUrl
            };
        },
        methods: {
            checkPanelEvent(panelKey){
                if(!this.panel.length){
                    this.panel = [...Array(this.subscriptions.length).keys()].map(_ => false);
                }
                let array = [];
                this.panel.forEach(function(item, key){
                    array[key] = (key === panelKey.index) ? !item : item;
                });

                this.panel = array;
            }
        },
        components: {
            InfoBanner,
            Graphic
        },
        created() {
            this.getSelfAssignedSubscription();
        }
    }

</script>
