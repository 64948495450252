/**
 * @typedef {Object} AccessLevel
 * @property {number} id
 * @property {string} level
 * @property {string} label
 * @property {string} description
 */

/**
 * @property {AccessLevel} accessLevel
 */
export class DocumentInfo {

    constructor(data) {
        Object.assign(this, data);
    }

    canHaveNotes() {

        if (this.accessLevel && this.accessLevel.id === 12) {
            return false;
        }

        // TODO: others

        return true;
    }

    isBookmarkingSectionsAllowed() {
        return !(this.accessLevel && this.accessLevel.id === 12);
    }

    isAnnotationAllowed() {
        return !(this.accessLevel && this.accessLevel.id === 12)
    }
}
