import TrackDocument from '../../dashboard/quick_access/mixins/track_document_action';
import Seo from './mixins/seo';
export default {
    mixins: [TrackDocument, Seo],
    methods: {
        toggleSectionAction(id, action = null) {
            // Code to add/remove canonical Tag
            this.addCanonicalTag();
            // Get our objects.
            let section = document.getElementById('action-wrapper-'+id),
                sectionActionIcon,
                sectionActionButtons,
                data = {};

            // Toggle classes.
            if (!_.isNull(section)) {
                if(this.lastAccessedSection !== section){
                    this.lastAccessedSection = section;
                    let title = '';
                    if (section.getElementsByTagName('h1').length > 0) {
                         title = section.getElementsByTagName('h1')[0].textContent;
                    } else if(section.getElementsByClassName('table_title').length > 0){
                         title = section.getElementsByClassName('table_title')[0].textContent;
                    }
                    this.createAccessedDocument(this.documentid, id, title, 'book_content');
                }

                // Close all other sections and toggle section with provided id.
                let openSections = document.getElementsByClassName('section-action-wrapper');
                let _this = this;
                _.forEach(openSections, function(value) {
                    id = _this.stripIds(value.id);
                    sectionActionIcon = document.getElementById('section-action-icon-'+id);
                    sectionActionButtons = document.getElementById('section-action-buttons-'+id);
                    data = {
                        sectionId: id,
                        section: value,
                        sectionActionIcon: sectionActionIcon,
                        sectionActionButtons: sectionActionButtons
                    };
                    if (!value.classList.contains('v-card--flat') && value !== section) {
                        _this.hideSectionAction(data);
                    }
                    if (value === section) {
                        if (value.classList.contains('v-card--flat') || (!_.isNull(action) && action == 'show')) {
                            value.classList.remove('v-card--flat');
                            sectionActionIcon.classList.add('primary--text');
                            sectionActionButtons.style.display = 'block';
                        } else {
                            _this.hideSectionAction(data);
                        }
                    }
                });
            }
        },
        hideSectionAction(data) {
            // Add remove our classes.
            data.section.classList.add('v-card--flat');
            data.sectionActionIcon.classList.remove('primary--text');
            data.sectionActionButtons.style.display = 'none';
        },
        togglePrintAction(id, printSelected = false, printAllSections = false) {
            let printOptionSelected = printSelected;
            let isPrintAllSections = printAllSections;
            let elemCheck = document.getElementById('section-action-buttons-'+id).nextSibling;

            if (elemCheck.classList.contains('table')) {
                let json = {};
                json.html = elemCheck.innerHTML;
                json.title = elemCheck.getElementsByClassName('table_title')[0].innerText;

                this.$http.post(
                    Routing.generate("print_figure"), {data: JSON.stringify(json)}, {emulateJSON: true}
                ).then(result => {
                    if(result.data.length) {
                        window.open(result.data, '_blank');
                    }
                });
            } else {
                let tableWidth = 998; // default
                let printElement = document.getElementById('action-wrapper-'+id);

                if(null !== printElement && !_.isUndefined(printElement)) {
                    let tbl = printElement.getElementsByTagName("table");
                    tableWidth = tbl.length ? tbl[0].offsetWidth : tableWidth;
                }
                if (!printOptionSelected) {
                    let element = document.getElementById('print-menu-' + id);
                    if (null == element || _.isUndefined(element)) {
                        // Complete chapter prints are prevented (no print menu)
                        if ((id != this.activeChapter) && this.hasSubsections(id)) {
                            this.applySectionPrintMenu(id);
                        } else {
                            this.printContent(id, printAllSections, tableWidth);
                        }
                    } else {
                        this.togglePrintMenu(id);
                    }
                } else {
                    this.printContent(id, printAllSections, tableWidth);
                }
            }
            this.trackClickedAction('Print Section');
        },
        printContent(id, printAllSections, tableWidth) {
            window.open(Routing.generate('print_content', {
                'documentId': btoa(this.documentid),
                'chapterId': btoa(this.activeChapter),
                'sectionId': btoa(id),
                'tableWidth': tableWidth,
                'printAll': printAllSections ? 1 : 0,
            }), '_blank');
        },
        toggleShareAction(id) {
            let sectionID = document.getElementById('action-wrapper-' + id);

            /** @type HTMLElement */
            let sectionParent = sectionID ? sectionID.parentElement : null;

            // #action-wrapper-17427540 => #IBC2018P4_Ch03_Sec302.1
            if (sectionParent && sectionParent.tagName === 'SECTION' && sectionParent.hasAttribute('id')) {
                sectionID = sectionParent.getAttribute('id');
            } else {
                sectionID = sectionID.getAttribute('id');
            }

            EventBus.fire('show-modal-share-sections', {
                'content_id': id,
                'document_id': this.documentid,
                'chapter_id': this.activeChapter,
                'book_name': this.activeDocumentInfo.title,
                'section_title': document.getElementById('action-wrapper-'+id).getAttribute('data-section-title'),
                'section_id': sectionID
            });
            this.trackClickedAction('Share Section');
        },
        toggleBookmarkAction(sectionId) {
            let parentDiv = document.getElementById('action-wrapper-'+sectionId),
                bookmarkId = parentDiv.getAttribute("data-bookmark"),
                sectionTitle = parentDiv.getAttribute("data-section-title");

            this.trackClickedAction('Bookmark Section');

            EventBus.fire('bookmark-modal', {
                'sectionTitle': sectionTitle,
                'sectionId': sectionId,
                'chapterId': this.activeChapter,
                'document_id' : this.activeDocumentInfo.document_id,
                'id' : bookmarkId
            });
        },
        toggleNoteAction(noteId, sectionId) {
            let parentDiv = document.getElementById('action-wrapper-'+sectionId),
                chapterTitle = parentDiv.getAttribute('data-chapter-title');

            if (_.size(this.highlightedText) > 0) {
                EventBus.fire('note-modal', {
                    'id': noteId ? noteId : null,
                    'sectionId': sectionId,
                    'chapterTitle': chapterTitle,
                    'chapterId': this.activeChapter,
                    'bookId': this.activeDocumentInfo.id,
                    'documentId': this.activeDocumentInfo.document_id,
                    'bookSku': this.activeDocumentInfo.sku,
                    'isNewXml': this.activeDocumentInfo.isNewIccXml,
                    'highlightedText' : this.highlightedText
                });
                this.trackClickedAction('Note/Highlight Section');
            }
        }
    }
}
