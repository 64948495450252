import { render, staticRenderFns } from "./CartQue.vue?vue&type=template&id=636faa3f&scoped=true&"
import script from "./CartQue.vue?vue&type=script&lang=js&"
export * from "./CartQue.vue?vue&type=script&lang=js&"
import style0 from "./CartQue.vue?vue&type=style&index=0&id=636faa3f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636faa3f",
  null
  
)

export default component.exports