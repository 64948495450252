<template>
    <v-alert
            text
            color="blue"
            border="left"
            dense
            v-if="isVisible"
            dismissible
            class="actionable-alert"
    >

        <template v-slot:close>
            <v-btn
                    icon
                    color="black"
                    @click="clickedClose"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

        <div class="d-flex flex-column flex-md-row align-center black--text">

            <div class="mr-md-5 mb-md-0 mb-2">
                <slot name="text"></slot>
            </div>

            <div class="d-flex flex-nowrap">

                <v-btn color="blue darken-4" class="white--text mr-1 font-weight-bold" @click="actionClicked">
                    {{ actionText ?? 'Take Action' }}
                </v-btn>

                <v-btn color="blue darken-4" outlined class="font-weight-bold" @click="dismissClicked">
                    {{ dismissText ?? 'Dismiss' }}
                </v-btn>
            </div>

        </div>

    </v-alert>
</template>

<script>
export default {
    emits: ['onAction', 'onDismiss'],
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        actionText: {
            type: String,
            required: false,
        },
        dismissText: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'info'
        }
    },
    data() {
        return {
            isVisible: false
        }
    },
    methods: {
        clickedClose() {
            this.isVisible = false;
            this.dismissClicked();
        },
        actionClicked() {
            this.$emit('onAction');
        },
        dismissClicked() {
            this.$emit('onDismiss');
        }
    },
    mounted() {

        this.$watch(() => this.visible, (newValue) => {
            this.isVisible = !!newValue;
        }, {
            immediate: true
        });
    }
}
</script>

<style>
.actionable-alert .v-alert__border--left {
    /* blue darken-4 */
    color: #0D47A1 !important;
    opacity: 1 !important;
}
</style>
