<template>
    <v-container class="">

        <h1 class="oxygen font-weight-light text-center mb-8">
            Simplify your Workflow and Search from Anywhere with Digital Codes Premium
        </h1>

        <p class="text-uppercase text-center roboto font-weight-bold primary--text mb-8">
            Access the latest features and contents from your fingertips
        </p>

        <v-row justify-lg="center" class="mb-10">

            <v-col cols="12" lg="5" class="d-flex flex-column justify-center">
                <ul id="list_benefits">
                    <li
                            v-for="(item,i) in benefitList"
                            :key="i">
                        <v-icon class="theme--light accent2--text" large>{{ item.icon }}</v-icon>
                        <span class="ml-5 roboto font-weight-bold">{{ item.title }}</span>
                    </li>
                </ul>

                <div>
                    <a href="https://apps.apple.com/us/app/digital-codes-premium/id1523315914" target="_blank">
                        <img :src="this.asset(require('../../images/homepage/download-app-store.png'))" class="mr-5">
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=org.iccsafe.codes" target="_blank">
                        <img :src="this.asset(require('../../images/homepage/get-on-google-play.png'))" class="mr-5">
                    </a>

                </div>

            </v-col>

            <v-col cols="5" class="d-none d-lg-block text-center">
                <img :src="this.asset(require('../../images/pc_page/pc_mobile.png'))">
            </v-col>

        </v-row>

        <div class="text-center">

            <h1 class="oxygen font-weight-light mb-5">Premium Complete starting at <strong class="font-weight-bold">${{premiumCompletePrice}}</strong>
                per month</h1>

            <p class="roboto mb-8">ICC Member and Quantity Discounts applied automatically during checkout</p>

            <div>
                <v-btn
                        v-if="!hasCompleteSubscription || trialActive"
                        :x-large="isLargeAndUp"
                        dark
                        color="accent2"
                        class="roboto mr-5"
                        @click="premiumSubscribe">
                    Get Premium Complete
                </v-btn>

                <v-btn
                        v-if="!trialStarted && !hasCompleteSubscription"
                        :x-large="isLargeAndUp"
                        dark
                        class="cdpBlue roboto mr-5"
                        @click="goToRoute('trial_signup')">
                    Start Free 14-day Premium Complete Trial
                </v-btn>

                <v-btn
                        :x-large="isLargeAndUp"
                        dark
                        color="primary"
                        class="roboto"
                        @click="learnMoreClicked">
                    Learn More
                </v-btn>
            </div>

        </div>

    </v-container>
</template>

<script>

import Mobile from './../inc/mixins/mobile';
import {AppParameters} from "../../AppParameters";

export default {
    mixins: [Mobile],
    data() {
        return {
            premiumCompletePrice: AppParameters.complete_monthly_price,
            benefitList: [
                {title: 'Unlock exclusive contents', icon: 'mdi-database-search'},
                {title: 'Advanced Search', icon: 'mdi-magnify'},
                {title: 'Add and organize notes', icon: 'mdi-clipboard-check-outline'},
                {title: 'Never miss an update', icon: 'mdi-history'},
                {title: 'Invaluable supporting materials', icon: 'mdi-bookmark-multiple-outline'},
                {title: 'Sharing with entire team', icon: 'mdi-account-group'},
                {title: 'Premium Mobile App Offline Access', icon: 'mdi-cellphone'}
            ]
        }
    },
    methods: {
        learnMoreClicked() {
            window.location = Routing.generate('premium_features');
        },
        premiumSubscribe() {
            this.updatePremiumCompleteConversionUrl().then(() => {
                this.getPremiumCompleteBook();
            });
        }
    }
}
</script>

<style scoped lang="scss">
#list_benefits {

  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    padding-bottom: 2em;

    span {
      font-size: 1.2em;
    }
  }
}

</style>
