var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "grey lighten-5 ai-watermark" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-10 pb-6" },
                    [
                      _c("img", {
                        attrs: {
                          alt: "ICC Ai Navigator Logo",
                          src: _vm.asset(
                            require("../../images/ai_navigator/ai_navigator_logo.svg")
                          ),
                          width: "150",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "h1",
                        { staticClass: "fs-40 font-weight-bold oxygen" },
                        [
                          _vm._v(
                            "\n                        Understand codes faster with ICC AI Navigator\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "fs-20 font-weight-regular my-3" },
                        [
                          _vm._v(
                            "\n                        The power of AI, trained by ICC experts.\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.hasPremiumSubscription
                                ? _c("use-ai-navigation")
                                : _c("start-free-trial"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isMobilePort
                    ? _c("v-col", [
                        _c("img", {
                          attrs: {
                            alt: "ICC Ai Navigator Example",
                            src: _vm.asset(
                              require("../../images/ai_navigator/chat_graphic.webp")
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container text-center" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "h4",
                  {
                    staticClass:
                      "teal--text fs-20 font-weight-medium mt-8 mb-4",
                  },
                  [
                    _vm._v(
                      "\n                    Harnessing the power of OpenAI\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "fs-32 font-weight-bold oxygen" }, [
                  _vm._v(
                    "\n                    An AI assistant built for the future\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "my-6" },
            _vm._l(_vm.aiPower, function (item, key) {
              return _c(
                "v-col",
                { key: key, attrs: { cols: "12", sm: "3" } },
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: "primaryLight", size: "60" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { "x-large": "", color: "primaryMedium" } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.icon) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "fs-20 font-weight-medium mt-4" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.text) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                [
                  !_vm.isMobilePort
                    ? _c("v-col", [
                        _c("img", {
                          staticClass: "my-6",
                          attrs: {
                            alt: "ICC Ai Navigator Example",
                            src: _vm.asset(
                              require("../../images/ai_navigator/get_answers_blurb.webp")
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("h2", { staticClass: "fs-36 my-4 oxygen" }, [
                        _vm._v(
                          "\n                        Get answers to your compliance requirement questions anytime\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "fs-20 font-weight-medium" }, [
                        _vm._v(
                          "\n                        AI Navigator can offer quick, helpful replies to basic code questions, and is programmed to help users navigate our I-Codes like an expert.\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "auto" } },
                            [
                              _vm.hasPremiumSubscription
                                ? _c("use-ai-navigation")
                                : _c("start-free-trial"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container" },
        [
          _c(
            "v-row",
            { staticClass: "text-center mb-4" },
            [
              _c("v-col", [
                _c(
                  "h4",
                  {
                    staticClass:
                      "teal--text fs-20 font-weight-medium mt-8 mb-4",
                  },
                  [
                    _vm._v(
                      "\n                    Accuracy and consistency\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "fs-32 font-weight-bold oxygen" }, [
                  _vm._v(
                    "\n                    How we developed a tool to meet your needs\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-timeline",
            { attrs: { dense: _vm.isMobilePort } },
            _vm._l(_vm.howWeBuiltIt, function (item, key) {
              return _c(
                "v-timeline-item",
                {
                  key: key,
                  attrs: { color: "primaryMedium", "fill-dot": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "opposite",
                        fn: function () {
                          return [
                            _c("h2", {
                              staticClass: "font-weight-bold oxygen",
                              domProps: { textContent: _vm._s(item.title) },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.isMobilePort
                    ? _c("h3", {
                        staticClass: "font-weight-bold oxygen mb-4",
                        domProps: { textContent: _vm._s(item.title) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "grey lighten-5" },
                    [
                      _c("v-card-text", [
                        _c("div", {
                          staticClass: "fs-16",
                          domProps: { innerHTML: _vm._s(item.description) },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "text-center mt-6 mb-0" },
            [
              _c("v-col", [
                _c(
                  "h4",
                  {
                    staticClass:
                      "teal--text fs-20 font-weight-medium mt-8 mb-4",
                  },
                  [
                    _vm._v(
                      "\n                    Constantly improving and ever evolving\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "fs-32 font-weight-bold oxygen" }, [
                  _vm._v(
                    "\n                    Updates from the team\n                "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "grey lighten-5 mb-6",
                      attrs: { outlined: "" },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "py-1" },
                        [
                          _c("html-block", { attrs: { slug: "ai-updates" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-16 mt-4 text-center font-weight-medium w-full",
                              },
                              [
                                _vm._v(
                                  "\n                                No updates yet!"
                                ),
                                _c("br"),
                                _vm._v(
                                  "  Please check back later.\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5 mt-4" },
        [
          _c(
            "v-container",
            { staticClass: "content-container" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  !_vm.isMobilePort
                    ? _c("v-col", [
                        _c("img", {
                          attrs: {
                            alt: "ICC Ai Navigator Example",
                            src: _vm.asset(
                              require("../../images/ai_navigator/chat_graphic.webp")
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-16" },
                    [
                      _c(
                        "h1",
                        { staticClass: "fs-40 font-weight-bold oxygen" },
                        [
                          _vm._v(
                            "\n                        Try it now with Digital Codes Premium\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "fs-20 font-weight-regular my-3 mb-4" },
                        [
                          _vm._v(
                            "\n                        Try Premium today and get access to AI Navigator in addition to insights, advanced search and more.\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "auto" } },
                            [
                              _vm.hasPremiumSubscription
                                ? _c("use-ai-navigation")
                                : _c("start-free-trial"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "content-container mt-6 mb-8" },
        [
          _c(
            "v-row",
            [_c("v-col", [_c("f-a-qs", { attrs: { faqs: _vm.faqs } })], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }