var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "content-container py-6" },
    [
      _c("sub-main-heading", {
        attrs: {
          "sub-heading": "Testimonials",
          "main-heading": "Hear from our users",
        },
      }),
      _vm._v(" "),
      _c(
        "v-carousel",
        {
          staticClass: "mb-10 mx-auto",
          staticStyle: { "max-width": "790px" },
          attrs: {
            "show-arrows": false,
            "hide-delimiters": "",
            light: "",
            height: "auto",
            id: "top_carousel",
          },
          model: {
            value: _vm.slideNumber,
            callback: function ($$v) {
              _vm.slideNumber = $$v
            },
            expression: "slideNumber",
          },
        },
        _vm._l(_vm.testimonials, function (item, i) {
          return _c("v-carousel-item", { key: i }, [
            _c(
              "div",
              { staticClass: "pa-5" },
              [
                _c(
                  "v-card",
                  { attrs: { elevation: "7" } },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v(
                            "\n                            mdi-format-quote-close-outline\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "fs-20" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.text) +
                              "\n                        "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-carousel",
        {
          ref: "bottom_carousel",
          attrs: {
            "hide-delimiters": "",
            height: "auto",
            id: "bottom_carousel",
          },
          model: {
            value: _vm.slideNumber,
            callback: function ($$v) {
              _vm.slideNumber = $$v
            },
            expression: "slideNumber",
          },
        },
        _vm._l(_vm.testimonials, function (item, i) {
          return _c(
            "v-carousel-item",
            { key: i },
            [
              _c(
                "v-sheet",
                {
                  attrs: {
                    height: "100%",
                    tile: "",
                    light: "",
                    color: "transparent",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex flex-column fill-height mx-auto",
                      attrs: {
                        justify: "center",
                        align: "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "font-weight-medium mb-1 fs-20" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mb-0 font-weight-medium" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }