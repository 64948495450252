var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex-not-important", attrs: { id: "block" } },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center align-center w-full" },
            [
              _vm.isLoading
                ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
                : _vm._e(),
            ],
            1
          )
        : _vm.contents
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.contents) } })
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }