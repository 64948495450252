var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayTrialBlock
    ? _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "trial-block-alert-box",
                  style: `background-color: ${this.$vuetify.theme.themes.light.accent2Light};`,
                  attrs: {
                    type: "success",
                    "colored-border": "",
                    elevation: "0",
                    icon: "mdi-creation",
                    border: "left",
                    color: "accent2",
                  },
                  scopedSlots: _vm._u(
                    [
                      !_vm.trialActive
                        ? {
                            key: "close",
                            fn: function () {
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "top-right-dismiss",
                                    attrs: { icon: "", color: "black" },
                                    on: { click: _vm.closeTrialBlock },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-close")])],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.trialActive
                    ? _c("div", [
                        _c("h4", { staticClass: "mb-2" }, [
                          _vm._v(
                            "Premium Trial - " +
                              _vm._s(_vm.remainingDays) +
                              " remaining"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isPremiumConvertDiscountActive
                          ? _c("p", { staticClass: "text-body-1" }, [
                              _vm._v(
                                "\n                    Subscribe to Premium Complete before your trial ends for " +
                                  _vm._s(_vm.activeDiscountVal) +
                                  "% off!\n                "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c("h4", { staticClass: "mb-2" }, [
                          _vm._v(
                            "Start your free 14 day Premium Complete trial"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-body-1" }, [
                          _vm._v(
                            "Get access to bookmarks, content search, AI Navigator and more with Premium."
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c("StartFreeTrial", {
                    staticClass: "mb-2",
                    attrs: {
                      directStartTrial: "",
                      color: "accent2",
                      maxHeight: 35,
                    },
                  }),
                  _vm._v(" "),
                  _vm.trialActive
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold mb-2",
                          attrs: {
                            loading: _vm.isLoading,
                            depressed: "",
                            color: "accent2",
                          },
                          on: { click: _vm.premiumSubscribe },
                        },
                        [
                          _vm._v(
                            "\n                Subscribe to Premium Complete\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-bold mb-2",
                      attrs: {
                        outlined: "",
                        depressed: "",
                        target: "_blank",
                        href: _vm.getRoute("premium_features"),
                      },
                    },
                    [
                      _vm._v(
                        "\n                Learn About Premium\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.trialActive
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "black--text fs-14 font-weight-bold pa-2",
                          on: { click: _vm.cancelTrial },
                        },
                        [_vm._v("Cancel Trial\n            ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "confirm-dialog",
                    {
                      attrs: {
                        "is-open": _vm.showCancelConfirmDialog,
                        title: "Confirm Cancellation",
                      },
                      on: {
                        ok: _vm.confirmCancelTrial,
                        closed: function ($event) {
                          _vm.showCancelConfirmDialog = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Are you sure you want to cancel your FREE trial? The trial will automatically cancel at the end of the 14\n                    day period with no automatic charges being incurred.\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }