<template>
    <v-row
        :id="'sectionContent_'+sectionId"
        v-if="showSection">
        <v-col
            v-if="displayTitleSection"
            cols="12"
            sm="auto"
            class="text-center">
            <tile
                :title="document"
                :show-title="true">
            </tile>
        </v-col>
        <v-col>
            <v-card>
                <v-card-text primary-title class="newIccStyles purchasedViewStyles wrap-section-content pt-5">
                    <div v-if="!isDefinitionSection && isPremiumSubscribed(documentSku)" :class="{'section-action-buttons': this.$vuetify.breakpoint.mdAndUp, 'section-action-buttons-mobile': this.$vuetify.breakpoint.smAndDown}">
                        <span class="float-right">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionJumpAction" v-second-event="{ method: 'trackClickedAction', value: 'Jump to Section' }" class="right mr-2" v-on="on">input</v-icon>
                                </template>
                                <span>Jump to this Section</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionCopyAction" v-second-event="{ method: 'trackClickedAction', value: 'Copy to Clipboard' }" class="right mr-2" v-on="on">file_copy</v-icon>
                                </template>
                                <span>Copy this Section to Clipboard</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionPrintAction" v-second-event="{ method: 'trackClickedAction', value: 'Print Section' }" class="right mr-2" v-on="on">local_printshop</v-icon>
                                </template>
                                <span>Print this Section</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionShareAction" v-second-event="{ method: 'trackClickedAction', value: 'Share Section' }" class="right mr-2" v-on="on">link</v-icon>
                                </template>
                                <span>Share this Section</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click="sectionBookmarkAction" v-second-event="{ method: 'trackClickedAction', value: 'Bookmark Section' }" class="right" v-on="on">{{bookmarkIcon}}</v-icon>
                                </template>
                                <span>Bookmark this Section</span>
                            </v-tooltip>
                        </span>
                    </div>

                    <div class="quick-access-section-content">
                        <div v-if="selectedSectionData"
                            :id="'section-content_'+selectedSectionData.sectionId" v-html="selectedSectionData.sectionContent + '</section>'">
                        </div>
                        <div  v-else :id="'section-content_'+sectionId" v-html="sectionContent + '</section>'"></div>
                    </div>
                </v-card-text>
                <note-list v-if="loaded && hasBookmarksOrAnnotations" :data="note"></note-list>
            </v-card>
            <v-btn
                x-large
                class="mt-5"
                color="primary"
                @click="jumpToSection"
                v-second-event="{ method: 'trackClickedAction', value: 'Jump to Section' }">
                Jump to Section within Chapter
            </v-btn>
        </v-col>
        <share-section-modal :key="shareModalKey"></share-section-modal>
        <v-snackbar top right color="success" v-model="snackbar" :timeout="timeout">
            Content was copied to buffer
        </v-snackbar>
    </v-row>
</template>

<script>
    import '../../../scss/content/purchased.scss';
    import '../../../scss/content/free.scss';
    import Triggers from './mixins/section_triggers';
    import TrackDocument from './mixins/track_document_action';
    import NoteList from '../../titles/partials/noteList';
    import ShareSectionModal from '../../modals/ShareSection.vue';
    import Notes from '../../titles/notesModal/index';
    import Tile from "../../titles/cover/Tile";
    import ContentUtils from "../../titles/inc/contentUtils";

    export default {
        data() {
            return {
                document: {},
                documentId: null,
                documentSku: null,
                loadingNotes: true,
                chapterId: 0,
                sectionContent: '',
                sectionTitle: '',
                sectionId: 0,
                showSection: false,
                showActions: false,
                bookmarkIcon: 'bookmark_border',
                loaded: false,
                data: {},
                note: {
                    bookmarks: [],
                    annotations : [],
                },
                shareModalKey: 0,
                breadcrumbs: {},
                isDefinitionSection: false,
                lookupUrl: ''
            }
        },
        props: {
            type: {
                required: false,
                type: String,
                default: 'quick_access'
            },
            selectedSectionData: {
                required: false,
                type: Object
            }
        },
        computed: {
            hasBookmarksOrAnnotations() {
                return this.note.bookmarks.length || this.note.annotations.length;
            },
            displayTitleSection() {
                return !_.isEmpty(this.document);
            }
        },
        mixins: [Triggers, TrackDocument, ContentUtils],

        components: {
            Tile,
            NoteList,
            ShareSectionModal,
            Notes
        },

        methods: {
            jumpToSection(){
                if (this.isDefinitionSection) {
                    window.open(this.lookupUrl, '_blank');
                } else {
                    this.sectionJumpAction()
                }
            },
            setSectionContent(data) {
                let _this = this;
                this.document = !_.isEmpty(data.document) ? data.document : {};
                this.documentId = data.documentId ? data.documentId : data.document.document_id;
                this.documentSku = data.documentSku ? data.documentSku : data.document.sku;
                this.sectionContent = data.sectionContent;
                this.sectionId = this.selectedSectionData ? this.selectedSectionData.sectionId : data.sectionId;
                this.chapterId = data.chapterId;
                this.sectionTitle = data.sectionTitle;
                this.isDefinitionSection = data.isDefinitionSection ?? false;

                this.lookupUrl = data.metaXmlId ? `/lookup/${data.metaXmlId}` : '';
                if (this.sectionTitle != '') {
                    this.createAccessedDocument(
                        this.documentId,
                        this.sectionId,
                        this.sectionTitle,
                        this.type
                    );
                }
                this.$http.get(Routing.generate('content_search_result_breadcrumb', {
                    document_id: this.documentId,
                    content_id: this.sectionId || null
                })).then(response => {
                    this.breadcrumbs = response.data;
                });

                this.getNotes();
                this.showSection = true;
                // Identify the links and update the lookup url
                this.$nextTick(() => {
                    const sectionContent = document.getElementById('sectionContent_'+_this.sectionId);
                    if (sectionContent) {
                        let links = sectionContent.querySelectorAll('a');
                        links = Array.from(links);
                        _.forEach(links, function(link) {
                            let targetId = _this.getContentLinkTargetId(link);
                            if (targetId) {
                                link.setAttribute('href', '/lookup/' + targetId);
                            }
                        });
                    }
                });
            },
            cleanSection() {
                this.sectionContent = '';
                this.sectionId = '';
                this.accessedBookId = 0;
                this.showSection = false;
            },
            setTags() {
                this.$http.get(Routing.generate('get_my_tags'))
                    .then(response => {
                        this.$store.commit('setTags', response.data);
                    });
            }
        },
        created() {
            EventBus.listen('section-content', (data) => this.setSectionContent(data));
            EventBus.listen('close-section-content', () => this.cleanSection());
            EventBus.listen('update-notes', () => this.getNotes());
            EventBus.listen('force-rerender-share-sections', ()=> this.shareModalKey +=1);
            this.setTags();
        },
        mounted() {

            this.$watch(() => this.selectedSectionData, (newValue) => {

                if (newValue) {
                    this.setSectionContent(newValue);
                }

            }, {
                immediate: true
            });
        }
    }
</script>

<style>
    .quick-access-section-content {
        width: 100%;
    }
    .section-action-buttons {
        position: initial;
        top: 10px;
        right: 30px;
        cursor: pointer;
    }
    .section-action-buttons-mobile {
        width: 100%;
        text-align: right;
    }
    .wrap-section-content{
        word-break: normal !important;
        line-height: 1.8;
    }
    .wrap-section-content section.level3{
        margin-left: 8px !important;
    } 
</style>
