<template>
    <v-card flat class="card-container" :style="`border-top: 4px solid ${$vuetify.theme.themes.light.accent2}`">
        <v-card-title class="px-4 py-2">
            <v-row dense>
                <v-col>
                    <h2 class="fs-20 font-weight-bold">
                        Premium Bundle
                    </h2>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        small
                        icon
                        class="mt-n2"
                        @click="setHideCartQue">
                        <v-icon
                            color="accent">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            class="scrollable-content"
            :class="isMobilePort ? 'pt-1 px-2' : ''">
            <billing-frequency>
            </billing-frequency>
            <div v-if="unavailableTitles.length > 0">
                <p class="mb-0 mt-2">
                    The following title(s) are not available for {{ unavailableFrequenciesText }} billing frequency:
                </p>
                <ul>
                    <li v-for="item in unavailableTitles" :key="item.id">
                        {{ item.display_title }}
                    </li>
                </ul>
            </div>
            <license-quantity>
            </license-quantity>
            <v-row>
                <v-col><h4 class="pl-1">Titles ({{ getCartItems.length }})</h4></v-col>
            </v-row>
            <div :class="isMobilePort ? 'scrollable-content-mobile' : 'scrollable-content'">
                <v-row v-for="(item, key) in getCartItems"
                       :key="key"
                       style="border: 1px solid #E0E0E0;"
                       class="mx-1 mb-1">
                    <v-col>
                        <p
                            class="fs-14 font-weight-bold mb-0 text-truncate"
                            :style="isMobilePort ? 'max-width: 275px !important;' :  'max-width: 290px !important;'">
                            {{ item.display_title }}
                        </p>
                        <p class="fs-14 mb-0" v-if="item.pricePerLicense">
                            ${{ item.pricePerLicense | formatPrice }} / {{ billingPeriodShortText }}
                        </p>
                    </v-col>
                    <v-col cols="auto">
                        <v-icon color="black" @click.stop="removeItemFromCart(item)">
                            mdi-delete-outline
                        </v-icon>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
        <v-divider>
        </v-divider>
        <v-card-actions class="action-container">
            <v-container class="py-0 px-2">
                <v-sheet
                    v-if="savingsText.length"
                    class="rounded py-0 px-2 py-1 my-1"
                    color="accent2Light">
                    <v-row dense>
                        <v-col cols="auto">
                            <v-icon
                                color="accent2"
                                class="mt-n1">
                                mdi-sale-outline
                            </v-icon>
                        </v-col>
                        <v-col>
                            <h4 class="font-weight-bold">
                                Discounts Applied:
                            </h4>
                            <ul>
                                <li
                                    v-for="(item, key) in savingsText"
                                    :key="key">
                                    {{ item.discount }}% {{ item.label }}
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-sheet>
                <v-row dense class="px-2">
                    <v-col>
                        <h2>Total Price</h2>
                    </v-col>
                    <v-col cols="auto">
                        <h2>
                            ${{getIsMember ? cartTotalPrice.memberTotal : cartTotalPrice.nonMemberTotal | formatPrice}} / {{ billingPeriodShortText }}
                        </h2>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!getIsMember"
                    dense class="px-2">
                    <v-col>
                        <p class="mb-0 fs-16">ICC Member Price</p>
                        <a
                            class="text-decoration-underline"
                            target="_blank"
                            :href="getIccMembershipUrl()">
                            Learn about membership
                        </a>
                    </v-col>
                    <v-col cols="auto">
                        <p class="mb-0 fs-16">${{cartTotalPrice.memberTotal | formatPrice}} / {{ billingPeriodShortText }}</p>
                    </v-col>
                </v-row>
                <v-row
                    v-if="message"
                    align="center">
                    <v-col>
                        <alert-bar
                            class="mt-2"
                            :message="message"
                            :notification-type="type"
                            @dismiss="[message = null, type = null]">
                        </alert-bar>
                    </v-col>
            </v-row>
                <v-row dense class="px-2">
                    <v-col cols="6">
                        <v-btn
                            block
                            outlined
                            @click="setHideCartQue">
                            Close
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            block
                            color="accent2"
                            depressed
                            @click="addToCart"
                            :loading="cartLoading">
                            <v-icon>
                                mdi-cart-outline
                            </v-icon>
                            Add to Cart
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </v-card>
</template>

<script>
import mobile from '../inc/mixins/mobile';
import LicenseQuantity from "./LicenseQuantity.vue";
import BillingFrequency from "./BillingFrequency.vue";
import AlertBar from '../notifications/AlertBar';
import { CartApi } from '../../api/CartApi';
import { AppParameters } from '../../AppParameters';

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    components: {BillingFrequency, LicenseQuantity, AlertBar},
    mixins:[mobile],
    data() {
        return {
            isMember: true,
            cartLoading: false,
            type: null,
            message: null
        }
    },
    computed:{
        ...mapGetters('PricingModule', [
            'cartItemPrices',
            'cartTotalPrice',
            'billingPeriodText',
            'savingsText',
            'billingPeriodShortText',
            'getCartItems',
            'getIsMember',
        ]),
        ...mapState('PricingModule', ['quantity', 'frequency', 'cartLimitReached']),
        unavailableTitles() {
            return this.getCartItems.filter(item => this.unavailableFrequencies(item).length > 0);
        },
        unavailableFrequenciesText() {
            const allUnavailableFrequencies = this.unavailableTitles
                .flatMap(item => this.unavailableFrequencies(item));
            const uniqueUnavailableFrequencies = [...new Set(allUnavailableFrequencies)];
            return uniqueUnavailableFrequencies
                .map(freq => this.frequencyText(freq))
                .join(', ');
        }
    },
    watch:{
        cartLimitReached(newVal) {
            if (newVal) {
                this.type = 'error';
                this.message = `Cart allows a maximum of ${AppParameters.max_allowed_items_in_cart} titles.
                To add titles, please checkout with your current cart order.`;
            } else {
                this.type = null;
                this.message = null;
            }
        }
    },
    methods:{
        ...mapActions('PricingModule', [
            'removeFromCart',
            'setHideCartQue'
        ]),
        unavailableFrequencies(item) {
            const allFrequencies = ['M', 'B', 'C'];
            const pricingData = this.getIsMember ? item.pricing.member : item.pricing.general;
            const availableFrequencies = Object.keys(pricingData[item.sku] || {});
            return allFrequencies.filter(freq => !availableFrequencies.includes(freq));
        },
        frequencyText(frequency) {
            switch (frequency) {
                case 'M': return 'Monthly';
                case 'B': return 'Yearly';
                case 'C': return '3-Year';
                default: return '';
            }
        },
        removeItemFromCart(item){
            this.removeFromCart(item)
        },
        async addToCart(){
            const cartItems = this.getCartItems.map(item => {
                return {
                    'quantity': this.quantity,
                    'sku': item.sku,
                    'subscription_type': this.frequency
                };
            });
            if (this.isActiveUser) {
                this.cartLoading = true;
                try {
                    const response = await CartApi.addMultipleItemsToCart(cartItems);
                    if (response.data?.success) {
                            EventBus.fire('set-cart-items-count', {
                            type: 'increment',
                            qty:  cartItems[0].quantity
                        });
                        this.goToRoute('cart_page')
                    } else {
                        this.showError(response.data?.message || 'An error occurred while adding the item to your cart.')
                    }
                } catch (error) {
                    this.showError(error.response.data.error || 'An error occurred while adding the item to your cart.')
                } finally {
                    this.cartLoading = false;
                }
            } else {
                this.$session.set('cartItem', cartItems);
                EventBus.fire('openLoginModal', {
                    message: 'Login to add this item to your cart.',
                    fromCart: true
                });
            }
        },
        getIccMembershipUrl() {
            return this.$getConst('icc_membership_info_url');
        },
        showError(message) {
            this.type = 'error';
            this.message = message
        },
    }
}
</script>

<style scoped>
    .card-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .scrollable-content {
        flex: 1;
        overflow-y: auto;
    }
    .scrollable-content-mobile {
        max-height: 300px;
        min-height: 300px;
        overflow-y: auto;
    }
    .action-container {
        flex-shrink: 0;
    }
</style>
