<template>
    <div>
        <v-list role="menu" class="py-0">
            <v-list-item
                v-if="!isAuthenticated"
                class="list-item"
                target="_blank"
                :href="getRoute('signup')">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <v-icon class="primary--text">
                            mdi-account-plus
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Register
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <template
                v-else
                v-for="(profile, key) in profiles">
                <v-list-item
                    :key="key"
                    class="list-item"
                    :class="profile.is_active && getProfileColor(profile)"
                    @click="setUserProfile(profile)">
                    <v-row
                        dense
                        align="center"
                        :class="{'white--text': profile.is_active && profile.name.toLowerCase() === 'basic'}">
                        <v-col cols="auto">
                            <profile-icon :profile="profile">
                            </profile-icon>
                        </v-col>
                        <v-col>
                            <h4>
                                {{ profile.name }}
                            </h4>
                            <h5 class="text-uppercase font-weight-regular">
                                {{ profile.type }}
                            </h5>
                        </v-col>
                        <v-col
                            cols="auto"
                            v-if="profile.is_active">
                            <v-chip
                                x-small
                                :color="`${getProfileColor(profile)} lighten-1`">
                                Active
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>
            <v-divider>
            </v-divider>
            <v-list-item
                v-if="isAuthenticated && !activeConcurrentProfile && isMobilePort"
                class="list-item"
                :href="getRoute('cart_page')">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <template v-if="getItemsCount !== 0">
                            <v-badge color="accent2" class="mt-1 pt-1" right>
                                <template v-slot:badge>
                                    <span class="caption" v-html="getItemsCount"></span>
                                </template>
                                <v-icon color="primary">shopping_cart</v-icon>
                            </v-badge>
                        </template>
                        <template v-else>
                            <v-icon
                                color="primary">
                                shopping_cart
                            </v-icon>
                        </template>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Shopping Cart
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-list-item
                class="list-item"
                target="_blank"
                @click="showConcurrentModal()">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <v-icon class="primary--text">
                            supervised_user_circle
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Enter Concurrent Code
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-list-item
                v-if="isAuthenticated && isPreferencesPageEnabled()"
                class="list-item"
                :href="getRoute('dashboard_account')">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <v-icon class="primary--text">
                            mdi-account-circle-outline
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Account
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-list-item
                v-if="isAuthenticated && isBillingPageEnabled()"
                class="list-item"
                :href="getRoute('billing_page')">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <v-icon class="primary--text">
                            mdi-credit-card-outline
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Billing
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-list-item
                v-if="isMobilePort"
                class="list-item"
                target="_blank"
                @click="showUpdates()">
                <v-row
                    align="center" dense>
                    <v-col cols="auto">
                        <v-icon class="primary--text">mdi-bullhorn</v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Recent Updates
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-divider></v-divider>
            <template v-if="isActiveAdmin()">
                <v-list-item :href="getRoute('admin_dashboard')">
                    <v-row
                        dense
                        align="center">
                        <v-col cols="auto">
                            <v-icon class="primary--text">
                                mdi-crown
                            </v-icon>
                        </v-col>
                        <v-col>
                            <v-list-item-title class="primary--text">
                                Admin
                            </v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>
            <template v-if="showEasyAdminMenu()">
                <v-list-item
                    class="list-item"
                    href="/easyadmin">
                    <v-row
                        dense
                        align="center">
                        <v-col cols="auto">
                            <v-icon class="primary--text mt-n1">
                                mdi-shield-crown-outline
                            </v-icon>
                        </v-col>
                        <v-col>
                            <v-list-item-title class="primary--text">
                                EasyAdmin
                                <v-chip
                                    small
                                    dark
                                    color="blue"
                                    class="mb-1">
                                    beta
                                </v-chip>
                            </v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>
            </template>
            <v-list-item
                v-if="isAuthenticated"
                class="list-item"
                @click="goLogout">
                <v-row
                    dense
                    align="center">
                    <v-col cols="auto">
                        <v-icon class="primary--text">
                            mdi-exit-to-app
                        </v-icon>
                    </v-col>
                    <v-col>
                        <v-list-item-title class="primary--text">
                            Sign Out
                        </v-list-item-title>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import Mobile from './../../inc/mixins/mobile';
import ProfileIcon from "./ProfileIcon";
import NavigationMixin from './mixins/navigation';
import ConfirmDialog from "../../ConfirmDialog";
import {AppParameters} from "../../../AppParameters";

export default {
    mixins: [
        NavigationMixin,
        Mobile
    ],
    components: {
        ProfileIcon,
        ConfirmDialog
    },
    methods: {
        showEasyAdminMenu(){
            const user = this.$store.getters.getUser;
            if (user) {
                const roles = user.roles;
                return Array.isArray(roles) && roles.includes('ROLE_SUPER_ADMIN');
            }
            return false;
        },
        showUpdates() {
            EventBus.fire('show-notification-modal');
        },
        isBillingPageEnabled() {
            return AppParameters.billing_page_enabled;
        },
        isPreferencesPageEnabled() {
            return AppParameters.user_preferences_page_enabled;
        },
    }
}
</script>
