var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "sticky",
      staticClass: "sticky-chapter white",
      style: _vm.titleContainerStyle,
      attrs: { id: "sticky-chapter-info" },
    },
    [
      _vm.showExam ? _c("v-divider") : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "pb-0 pt-1", attrs: { fluid: "" } },
        [
          _vm.isMobilePort
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", "x-small": "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleToc(true)
                            },
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n                        mdi-menu-close\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(_vm.getBookTitle(_vm.documentInfo))),
                    ]),
                    _vm._v(" "),
                    _c("h4", {
                      staticClass: "mt-1",
                      domProps: { innerHTML: _vm._s(_vm.getChapterTitle) },
                    }),
                  ]),
                  _vm._v(" "),
                  !_vm.showExam
                    ? [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            !_vm.documentInfo.is_astm
                              ? _c("premium-status", {
                                  attrs: {
                                    displayRightAligned: "",
                                    displayIconOnly: "",
                                    documentInfo: _vm.documentInfo,
                                    contentPremium: _vm.contentPremium,
                                    contentProtection: _vm.contentProtection,
                                    contentBasic: _vm.contentBasic,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "v-row",
                { attrs: { align: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      !_vm.tocMini
                        ? _c(
                            "div",
                            { staticClass: "ml-n7" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "200", right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "elevation-1",
                                                  attrs: {
                                                    fab: "",
                                                    "x-small": "",
                                                    color: "white",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.tocMini = true
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "\n                                    mdi-menu-open\n                                "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3400887184
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "Click to toggle Table of Contents."
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-tooltip",
                            {
                              attrs: { "max-width": "200", right: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              fab: "",
                                              "x-small": "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.tocMini = false
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "\n                                mdi-menu-close\n                            "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("Click to toggle Table of Contents."),
                              ]),
                            ]
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      !_vm.showExam
                        ? _c("titles-breadcrumb", {
                            staticClass: "mt-n1",
                            attrs: { "document-info": _vm.documentInfo },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h3", {
                        staticClass: "mt-1",
                        domProps: { innerHTML: _vm._s(_vm.getChapterTitle) },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      !_vm.showExam
                        ? [
                            !_vm.documentInfo.is_astm
                              ? _c("premium-status", {
                                  attrs: {
                                    displayRightAligned: "",
                                    documentInfo: _vm.documentInfo,
                                    contentPremium: _vm.contentPremium,
                                    contentProtection: _vm.contentProtection,
                                    contentBasic: _vm.contentBasic,
                                  },
                                })
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-0 mt-1 caption font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        ASTM Standards are presented in read-only format.\n                    "
                                    ),
                                  ]
                                ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-right mt-1" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                dense: "",
                                align: "center",
                                justify: "end",
                              },
                            },
                            [
                              _vm.isCommentary && _vm.showCommentaryButton
                                ? _c(
                                    "v-col",
                                    [_c("titles-commentary-button")],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [_c("full-screen-button")],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _vm.documentInfo.document_id && !_vm.showExam
                                    ? _c("legend-button", {
                                        attrs: {
                                          "document-id":
                                            _vm.documentInfo.document_id,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "white" },
        [
          _c("newer-version-available-alert", {
            staticClass: "ma-2",
            attrs: {
              visible: !_vm.isLatestVersion && !_vm.showExam,
              "master-document-id": _vm.currentDocumentMasterId,
            },
          }),
          _vm._v(" "),
          _vm.isOnLandingPage && _vm.documentInfo?.id && !_vm.showExam
            ? _c("return-last-position-message", {
                staticClass: "ma-2",
                attrs: { "document-info-id": _vm.documentInfo.id },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("prior-version-note-migrate-alert", { staticClass: "ma-2" }),
          _vm._v(" "),
          !_vm.showExam ? _c("premium-information-modal") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }