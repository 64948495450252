var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "disable-selection tag-icons" },
    [
      _vm.tag === "es"
        ? _c(
            "v-chip",
            {
              staticClass: "px-1 cp",
              attrs: {
                "x-small": "",
                color: "primary",
                label: "",
                "text-color": "white",
              },
            },
            [_vm._v("\n        ES\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "errata"
        ? _c(
            "v-chip",
            {
              staticClass: "px-1 cp",
              attrs: {
                "x-small": "",
                color: "red",
                label: "",
                "text-color": "white",
              },
            },
            [_vm._v("\n        E\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "premium"
        ? _c(
            "v-tooltip",
            {
              attrs: { color: "black", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              staticClass: "px-1 cp",
                              attrs: {
                                "x-small": "",
                                color: "accent2",
                                title: "",
                                label: "",
                                "text-color": "white",
                              },
                            },
                            on
                          ),
                          [_vm._v("\n                P\n            ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3069557969
              ),
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "Premium Code Insights available at the end of the section"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "cdp"
        ? _c(
            "v-chip",
            {
              staticClass: "px-1 cp",
              attrs: {
                "x-small": "",
                color: "blue",
                label: "",
                "text-color": "white",
              },
            },
            [_vm._v("\n        CDP\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "srcc"
        ? _c(
            "v-chip",
            {
              staticClass: "px-1 cp",
              attrs: {
                "x-small": "",
                color: "purple",
                label: "",
                "text-color": "white",
              },
            },
            [_vm._v("\n        S\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag === "video"
        ? _c(
            "v-chip",
            {
              staticClass: "px-1 cp",
              attrs: {
                "x-small": "",
                color: "orange",
                label: "",
                "text-color": "white",
              },
            },
            [_vm._v("\n        V\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }