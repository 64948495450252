<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
        <div>
            <v-container class="premium-team-container">
                <v-row
                    :class="{'py-10': !isMobilePort} "
                    align="center"
                    justify="center"
                    >
                    <v-col
                       :class="{'text-center': isMobilePort}"
                       :cols="!isMobilePort ? 'auto' : '12'"
                       md="6"
                        >
                        <h1 class="font-weight-light mb-5 oxygen">
                            Premium for Teams
                        </h1>
                        <h2 class="font-weight-regular mb-5 oxygen">
                            Empower Collaboration
                            <br/>Within Your Organization
                        </h2>
                        <h3 class="font-weight-regular mb-5 oxygen">
                            Coordinated Team access to ICC Digital
                            <br/>Codes Premium. Boost your team's
                            <br/>collaboration and keep costs low.
                            <br/>Customize a solution that fits your needs.
                        </h3>
                        <v-btn
                            rounded
                            dark
                            x-large
                            color="accent2"
                            target="_blank"
                            :href="this.$getConst('demoUrl')"
                            >
                            <b>Schedule Demo</b>
                        </v-btn>
                        <br/>
                        <p class="mt-7">
                            <a class="text-decoration-underline mt-2"  @click="showModal()">
                                Already have a concurrent access code? <br/>
                                Click here to get started
                            </a>
                        </p>
                    </v-col>
                    <v-col order="1">
                        <img
                            width="486"
                            height="450"
                            :src="asset(require('../../images/teams/teams_people.jpg'))"/>
                    </v-col>
                </v-row>
            </v-container>
            <v-row>
                <v-col>
                    <v-card
                        flat
                        rounded
                        class="grey lighten-3">
                        <v-card-title class="pb-0">
                            <v-row
                                justify="center" dense>
                                <v-col cols="auto">
                                    <h2
                                        v-if="isMobilePort"
                                        class="font-weight-light pt-10 oxygen">
                                        Two Options to Meet Your Needs
                                    </h2>
                                     <h1
                                       v-else
                                        class="font-weight-light pt-10 oxygen">
                                        Two Options to Meet Your Needs
                                    </h1>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm=""
                                    >
                                    <v-card
                                        flat
                                        outlined
                                        class="grey lighten-3">
                                        <v-card-text class="text-center">
                                            <v-row>
                                                <v-col>
                                                    <img
                                                        :width="150"
                                                        :height="150"
                                                        :src="asset(require('../../images/teams/teams_icon_concurrent.png'))"/>
                                                </v-col>
                                            </v-row>
                                            <h2 class="pb-5">
                                                Concurrent Licenses
                                            </h2>
                                            <p class="option-text">
                                            Buy only what you need — share a select<br>
                                            number of licenses across your team
                                            </p>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm=""
                                    >
                                    <v-card
                                        flat
                                        outlined
                                        class="grey lighten-3">
                                        <v-card-text class="text-center">
                                            <v-row>
                                                <v-col>
                                                    <img
                                                        :width="150"
                                                        :height="150"
                                                        :src="asset(require('../../images/teams/teams_icon_enterprise.png'))"/>
                                                </v-col>
                                            </v-row>
                                            <h2 class="pb-5">
                                                Enterprise Seats
                                            </h2>
                                            <p class="option-text">
                                            Guaranteed access with dedicated <br/>
                                            seats for each person
                                            </p>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-container class="premium-team-container">
                <v-row
                    :class="{'py-10': !isMobilePort} "
                    align="center"
                    justify="center">
                    <v-col
                       :cols="!isMobilePort ? '6' : 'auto'"
                        class="text-center pt-20">
                        <img
                            width="550"
                            height="300"
                            :src="asset(require('../../images/teams/teams_youtube.png'))"/>
                    </v-col>
                    <v-col
                        class="text-center"
                        >
                        <h3>
                            For more details on the benefits<br>
                            of Concurrent vs. Enterprise, <br>
                            watch our latest video.
                        </h3>
                        <v-btn
                            rounded
                            class="my-4"
                            dark
                            x-large
                            color="accent2"
                            @click="redirectToUrl(youTubeUrl)"
                            >
                            <b>Watch Now</b>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="premium-team-container">
                <v-row class="access-highlights">
                    <v-col style="margin: auto;"
                        md="5"
                        sm="3"
                        class="text-center access-display">
                        <h1 class="font-weight-light oxygen">
                            Access <br>
                            Highlights
                        </h1>
                    </v-col>
                    <v-divider
                        class="v-divider mx-4"
                        vertical
                        ></v-divider>
                    <v-col class="access-display">
                        <v-card
                            flat
                            class="access-highlights"
                            >
                            <v-card-text class="text-center">
                                <v-row>
                                    <v-col>
                                        <img
                                            :width="100"
                                            :height="100"
                                            :src="asset(require('../../images/teams/teams_icon_concurrent.png'))"/>
                                    </v-col>
                                </v-row>
                                <h2 class="pb-1">
                                    Concurrent<br>Licenses
                                </h2>
                                <h3>
                                    Shared licenses
                                </h3>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-divider
                        vertical
                        class="v-divider mx-4"
                    ></v-divider>
                    <v-col class="access-display">
                        <v-card
                            flat
                            class="access-highlights"
                            >
                            <v-card-text class="text-center">
                                <v-row>
                                    <v-col>
                                        <img
                                            :width="100"
                                            :height="100"
                                            :src="asset(require('../../images/teams/teams_icon_enterprise.png'))"/>
                                    </v-col>
                                </v-row>
                                <h2 class="pb-1">
                                    Enterprise<br>Seats
                                </h2>
                                <h3>
                                    Dedicated seats
                                </h3>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row
                    v-for="(item, key) in accessHighlights"
                    :key="key"
                    :class="item.class"
                    align="center"
                    >
                    <v-col
                        md="5"
                        sm="3"
                        class="pa-7">
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-icon
                                    x-large
                                    color="accent2">
                                    {{item.icon}}
                                </v-icon>
                            </v-col>
                            <v-col>
                                <h4>
                                    {{item.title}}
                                </h4>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider
                        vertical
                        class="v-divider mx-4"
                    ></v-divider>
                    <v-col class="text-center">
                        <v-icon
                            x-large
                            v-if="item.isConcurrent"
                            color="primary">
                                mdi-check-circle
                        </v-icon>
                    </v-col>
                    <v-divider
                        vertical
                        class="v-divider mx-4"
                    ></v-divider>
                    <v-col class="text-center">
                        <v-icon
                            x-large
                            v-if="item.isEnterprise"
                            color="primary">
                                mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row
                    class="access-highlights"
                    align="center"
                    >
                    <v-col
                        md="5"
                        sm="3"
                        class="pa-7">
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-icon
                                    x-large
                                    color="accent2">
                                mdi-sale
                                </v-icon>
                            </v-col>
                            <v-col>
                                <h4>
                                    Volume discounts
                                </h4>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider
                        class="mx-4"
                        vertical
                        ></v-divider>
                    <v-col class="text-center">
                    <h4>Up to 50% off</h4>
                    </v-col>
                    <v-divider
                        class="mx-4"
                        vertical
                        ></v-divider>
                    <v-col class="text-center">
                        <h4>Lowest per-user cost </h4>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="grey lighten-3 my-10">
                <v-col>
                    <v-carousel
                        cycle
                        v-model="slideNumber"
                        :show-arrows="false"
                        hide-delimiter-background
                        light
                        height="auto"
                        class="mb-10"
                        id="top_carousel">
                        <v-carousel-item
                                v-for="(item, i) in testimonials"
                                :key="i">

                            <div class="pa-16 quoted-wrapper">
                                <p class="quoted">
                                    <q>{{ item.text }}</q>
                                </p>
                                <p class="font-weight-bold text-center">{{ item.name }}</p>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
            <v-container>
                <v-row
                    class="mt-6"
                    justify="center">
                    <v-col cols="auto">
                        <v-btn
                            rounded
                            :x-large="!isMobilePort"
                            :large="isMobilePort"
                            dark
                            color="accent2"
                            target="_blank"
                            :href="this.$getConst('demoUrl')">
                            <b>Schedule a Demo Today</b>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!isMobilePort"
                    justify="center">
                    <v-col cols="auto pa-8">
                        <img
                            width="322"
                            height="77"
                            class="cp"
                            @click="appClick()"
                            :src="asset(require('../../images/google_play_dark.png'))"/>
                    </v-col>
                    <v-col cols="auto pa-8">
                        <img
                            width="271"
                            height="77"
                            class="cp"
                            @click="appClick(true)"
                            :src="asset(require('../../images/apple_store_dark.png'))"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>
</template>

<script>
    import Mobile from './../inc/mixins/mobile';
    import Concurrent from './../concurrent/mixins/concurrent';

    import Moment from 'moment';
    import Login from './../content/partials/login';

    export default {
        data () {
            return {
                youTubeUrl: 'https://www.youtube.com/watch?v=GvkgIate2kg',
                accessHighlights: [
                    {
                        icon: 'mdi-home-map-marker',
                        title: 'Single point of purchase',
                        class: '',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-note-multiple-outline',
                        title: 'Shared notes',
                        class: 'grey lighten-3',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-marker',
                        title: 'Shared highlights and annotations',
                        class: '',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-bookmark-check',
                        title: 'Shared bookmarks and section links',
                        class: 'grey lighten-3',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-calendar-check',
                        title: 'Note migration to the next code year',
                        class: '',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-share-variant-outline',
                        title: 'Available to non-ICC members',
                        class: 'grey lighten-3',
                        isConcurrent: true,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-account-search',
                        title: 'Advanced user management',
                        class: '',
                        isConcurrent: false,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-clipboard-check-outline',
                        title: 'Robust reporting',
                        class: 'grey lighten-3',
                        isConcurrent: false,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-bulletin-board',
                        title: 'Specialized onboarding and training',
                        class: '',
                        isConcurrent: false,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-headset',
                        title: 'Dedicated ICC Account Manager',
                        class: 'grey lighten-3',
                        isConcurrent: false,
                        isEnterprise: true,
                    },
                    {
                        icon: 'mdi-lightbulb-on-outline',
                        title: 'Custom solutions',
                        class: '',
                        isConcurrent: false,
                        isEnterprise: true,
                    },
                ],
                slideNumber: 0,
                testimonials: [
                    {
                        text: `We saved money and enhanced efficiency by purchasing 7 licenses. It's a plus that we can add licenses if there is a need. Digital Codes Premium provides employees with all the digital tools to have a successful project.`,
                        name: "— Lee K.",
                    },
                    {
                        text: `We mostly access the I-codes and commentaries through Concurrent Access. Having the ability to quickly access codes from the field, home or office is the greatest benefit. Getting all these resources at a cheaper price has also helped with budget constraints.`,
                        name: "— David H.",
                    },
                    {
                        text: `We have access to volumes of code information without the need to have the code books with us. We utilize the digital codes for our field inspectors, which in turn makes the inspector more proficient and provides the capability to share information with professionals on the jobsite.`,
                        name: "— James M.",
                    },
                ]
            };
        },
        mixins: [Mobile, Concurrent],
        components: {
            Moment,
            Login
        },
        methods: {
            isLoggedIn() {
                return this.$store.getters.getLoggedIn !== '';
            },
            showModal() {
                EventBus.fire('show-modal-concurrent-access');
            }
        },
        mounted() {

        }
    }
</script>
<style
    lang="scss"
    scoped>
    .premium-team-container {
        max-width: 1200px;

        margin: 0 auto;
        img {
            max-width: 100%;
        }
        .oxygen {
            font-family: Oxygen;
        }
    }
    @media (min-width: 685px) {
        h1 {
            font-size: 54px;
            line-height: 68px;
        }
        h2 {
            font-size: 32px;
            line-height: 42px;
        }
        h3 {
            font-size: 23px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 685px) and (max-width: 820px)  {
         h1 {
            font-size: 42px;
        }
    }
    @media (max-device-width: 684px) {
        .access-display {
            padding: 0px !important;
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 13px;
            }
            h3 {
                font-size: 10px;
            }
        }
        .quoted-wrapper{
            padding: 30px !important;
        }
    }
    .option-text {
        font-size: 20px;
        line-height: 25px;
    }
    .access-highlights {
        background: #FEF5E4 !important;
    }
    .v-divider{
        border-color: #fff;
        border-width: 1px;
    }
    .quoted{
        font-size: 35px;
    }

</style>
