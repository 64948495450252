var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sticky", {
        attrs: {
          documentInfo: _vm.activeDocumentInfo,
          "show-commentary-button": "",
          isTrialActive: _vm.isTrialActive,
          contentPremium: _vm.contentPremium,
          contentProtection: _vm.contentProtection,
          contentBasic: _vm.contentBasic,
          bookAvailableToSubscribe: _vm.bookAvailableToSubscribe,
          pdfurl: _vm.pdfurl,
          "show-exam": "" !== _vm.examId,
          request: "content",
        },
      }),
      _vm._v(" "),
      "" !== _vm.examId ? _c("SearchTextBar") : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        {
          staticClass: "pt-0",
          class: { "px-0": _vm.isMobilePort },
          attrs: { fluid: "" },
        },
        [
          _c("chapter-section-tags"),
          _vm._v(" "),
          _c("smart-index", {
            attrs: {
              documentInfo: _vm.activeDocumentInfo,
              "exam-id": _vm.examId,
            },
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { class: { "ma-0": _vm.isMobilePort } },
            [
              _c(
                "v-col",
                {
                  class: _vm.classObject,
                  attrs: { xs12: "", id: "contentParent" },
                },
                [
                  !_vm.isXML
                    ? [
                        _c("pdf", {
                          attrs: {
                            youtubeid: _vm.youtubeid,
                            buylink: _vm.buylink,
                            contentProtection: _vm.contentProtection,
                          },
                        }),
                      ]
                    : [
                        _vm.notifications.length
                          ? _c(
                              "v-layout",
                              { attrs: { row: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    attrs: {
                                      xs12: "",
                                      "text-center": "",
                                      "py-12": "",
                                      "mt-6": "",
                                    },
                                  },
                                  _vm._l(
                                    _vm.notifications,
                                    function (notification, index) {
                                      return _c(
                                        "alert",
                                        {
                                          key: index,
                                          attrs: {
                                            message: notification.message,
                                            notificationType: notification.type,
                                            dismissible:
                                              notification.dismissible,
                                          },
                                          on: {
                                            dismiss: function ($event) {
                                              return notification.dismissHandler(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          notification.errorCodes.length
                                            ? [
                                                notification.errorCodes.length >
                                                1
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-left",
                                                        },
                                                        [
                                                          _c(
                                                            "ul",
                                                            _vm._l(
                                                              notification.errorCodes,
                                                              function (
                                                                errorCode
                                                              ) {
                                                                return _c(
                                                                  "li",
                                                                  [
                                                                    _c("span", {
                                                                      domProps:
                                                                        {
                                                                          innerHTML:
                                                                            _vm._s(
                                                                              errorCode
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            notification
                                                              .errorCodes[0]
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.content
                          ? [
                              _c("div", {
                                staticClass: "pt-4 chapter_styling",
                                class: {
                                  "pr-3": _vm.isMobilePort,
                                  "max-content-container":
                                    !_vm.isFullscreenContent,
                                },
                                domProps: { innerHTML: _vm._s(_vm.content) },
                              }),
                            ]
                          : [
                              !_vm.contentError
                                ? [
                                    _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              "text-center": "",
                                              "py-12": "",
                                              "mt-6": "",
                                            },
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                indeterminate: "",
                                                color: "primary",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoggedIn
            ? [
                _c("share-section-modal", { key: _vm.modalKey }),
                _vm._v(" "),
                _c("notes"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("back-to-top"),
        ],
        2
      ),
      _vm._v(" "),
      "" === _vm.examId
        ? _c(
            "div",
            [
              _c("cdp"),
              _vm._v(" "),
              _c("peak-ahead"),
              _vm._v(" "),
              _c("astm-modal"),
              _vm._v(" "),
              _c("ac-scope-modal"),
              _vm._v(" "),
              _c("access-restricted"),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "confirm-dialog",
        {
          attrs: { "is-open": _vm.showNoCppModal, title: "Action Not Allowed" },
          on: { closed: _vm.closeNoCppModal },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text",
                      on: { click: _vm.closeNoCppModal },
                    },
                    [_vm._v("\n                OK\n            ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.isExamPreview
            ? [
                _c("br"),
                _vm._v(
                  "\n            Copy and Paste controls are not available.\n        "
                ),
              ]
            : !_vm.canCopyPastePrint
            ? [
                _vm._v(
                  "\n            Copy and Paste controls are not available across this title. While Premium Lite titles include\n            features like content search, notes and link navigation, the contents are presented in a\n            read-only experience.\n        "
                ),
              ]
            : [
                _vm._v(
                  "\n            Copy and Paste controls are available within the Digital Codes Premium subscription.\n            To unlock Copy and Paste,\n            "
                ),
                _c("a", { attrs: { href: _vm.getRoute("trial_signup") } }, [
                  _c("b", [
                    _vm._v(
                      "start your free 14-Day Digital Codes Premium Complete trial."
                    ),
                  ]),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("premium-tag-modal"),
      _vm._v(" "),
      _c("premium-features-dialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }