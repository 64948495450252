import Moment from 'moment';
import {AppParameters} from "./../../../AppParameters";
import {GeoLocator} from "../../../classes/GeoLocator";
import {ExpirableStorage} from "../../../classes/ExpirableStorage";

export default {
    computed: {
        hideBanner: {
            get: function () {
                return this.$store.getters.isGlobalBannerHidden;
            },
            set: function (value) {
                this.$store.commit('setGlobalBannerHidden', value);
            }
        },
        astmCategory() {
            return this.titleSearchCategory === 'astm-standards';
        },
        gaEnabled() {
            return this.$store.getters.isGaEnabled;
        },
        gtmEnabled() {
            return this.$store.getters.isGtmEnabled;
        },
        userFirstName() {
            /** @type {User} */
            const user = this.$store.getters.getUser;

            return user ? `${user.first_name}` : undefined;
        },
        userFullName() {
            /** @type {User} */
            const user = this.$store.getters.getUser;

            return user ? `${user.first_name} ${user.last_name}` : undefined;
        },
        userNameInitials() {

            /** @type {User} */
            const user = this.$store.getters.getUser;
            if (user) {
                const first = user.first_name.charAt(0) || " ";
                const second = user.last_name.charAt(0) || " ";
                return first + second;
            }

            return "";
        },
        hasActiveTrial() {

            /** @type {User} */
            const user = this.$store.getters.getUser;

            return user && user.trial.isTrialActive;
        },
        isUserDataLoaded() {
            return !!this.$store.getters.getUser;
        },
        hasPremium() {
            return this.hasPremiumSubscription;
        }
    },
    methods: {
        goLogout() {
            this.$store.commit('setIsUserLoggingOut', true);
            this.clearProfiles();
            this.clearUserSession();
            localStorage.removeItem('hide-invitation');
            localStorage.removeItem('lastTokenSynchedAt');
            this.$store.commit('forgetDefaultSearch');
            ExpirableStorage.remove('hide_global_banner');
            this.$nextTick(() => {
                window.location = Routing.generate('logout');
            });
        },
        getBookPrintingInfo(book) {
            var printingInfo = '';
            if (book && !_.isUndefined(book.content_type) &&
                'Collection' !== book.content_type.name &&
                book.print_version_year) {
                // Day is static because it will be never used
                var objDate = new Date(book.print_version_month + "/01/" + book.print_version_year.id);
                var month = objDate.toLocaleString('en-us', {month: "short"});

                var version = '';
                if (book.print_version_edition && book.print_version_edition.version !== undefined) {
                    version = book.print_version_edition.version;
                }

                return '(' + version + ' Version: ' + month + ' ' + book.print_version_year.year + ')';
            }
            return printingInfo;
        },
        getBookCover: function (book) {
            if (book.document_cover_pdf) {
                return this.asset('/uploads/bookcover/' + book.document_cover_pdf);
            } else if (book.documentCoverPdf) {
                return this.asset('/uploads/bookcover/' + book.documentCoverPdf);
            } else if (book.year || book.bookYear || book.documentYear || book.yearStr) {
                var bookYear = this.getYear(book);
                var bookSku = '';

                if (book.document_sku) {
                    bookSku = book.document_sku;
                } else if (book.sku) {
                    bookSku = book.sku;
                }

                if ('' !== bookYear && '' !== bookSku) {
                    return this.asset('/bundles/document/document_images/document_covers/' + bookYear + '_' + bookSku + '_image.jpg');
                }
            } else if (book.document_id) {
                return this.asset('/uploads/bookcover/' + book.document_id + '.jpg');
            }

            return this.asset('/uploads/bookcover/document_placeholder.jpg');
        },
        loadDefaultBookCover: function (e) {
            e.target.src = this.asset('/uploads/bookcover/document_placeholder.jpg');
        },
        getFeaturedItemCover: function (src) {
            return this.asset('/uploads/featuredItems/' + src);
        },
        checkSubscriptionType(subscriptionType) {
            switch (subscriptionType) {
                case "M":
                    return "Monthly";
                case "F":
                    return "45 Day";
                case "A":
                    return "6 Month";
                case "B":
                    return "1 Year";
                case "C":
                    return "3 Year";
                case "D":
                    return "5 Year";
            }
            return subscriptionType;
        },
        getYear(book) {
            let bookYear = '';

            if (book.bookYear) {
                bookYear = book.bookYear;
            } else if (book.year && book.year.year) {
                bookYear = book.year.year;
            } else if (book.documentYear) {
                bookYear = book.documentYear;
            } else if (book.yearStr) {
                bookYear = book.yearStr;
            } else if (book.book_year) {
                bookYear = book.book_year;
            }

            return bookYear;
        },
        getBookTitle(book, includeYear = true) {
            let title = '';

            if (book) {
                if (book.title) {
                    title = book.title;
                } else if (book.display_title) {
                    title = book.display_title;
                }

                let contenType = '';
                if(!_.isUndefined(book.content_type)) {
                    contenType = book.content_type.name ? book.content_type.name : book.content_type;
                }

                if (includeYear &&
                    ('Premium Complete' !== contenType &&
                        'ICC ES XML' !== contenType &&
                        !book.astm_subject
                    )
                ) {
                    title = this.getYear(book) + ' ' + title;
                }
            }

            return title;
        },
        assignmentUsageReport(data) {
            var csvString = 'Email,Access Time,Exit Time\n';
            for (var i = 0; i < data.length; i++) {
                var access_time = Moment(data[i].access_time).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                var exit_time = data[i].exit_time ? Moment(data[i].exit_time).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ') : 'NA';
                csvString += data[i].email + ', ' + access_time + ',' + exit_time + "\n";
            }
            return csvString;
        },
        concurrentCodeUsageReport(data, code) {
            var csvString = 'Code,Email,Access Time,Exit Time,Status\n';
            for (var i = 0; i < data.length; i++) {
                var access_time = Moment(data[i].access_time).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                var exit_time = data[i].logout_time ? Moment(data[i].logout_time).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ') : 'NA';
                var status = data[i].status ? 'Success' : 'Failed (license limit)';
                csvString += code + ', ' + data[i].user.email + ',' + access_time + ',' + exit_time + ',' + status + "\n";
            }
            return csvString;
        },
        notesLog(data) {
            var csvString = 'Old Book Title,New Book Title,Old Section Title,New Section Title,Type,Action,Timestamp\n';
            for (let i = 0; i < data.notes.length; i++) {
                let type = (data.notes[i].is_annotation) ? 'Annotation' : 'Bookmark';
                let oldBookTitle = data.notes[i].fromYear + ' ' + data.notes[i].fromTitle + ' (' + data.printInfo.fromPrintInfo + ')';
                let newBookTitle = data.notes[i].toYear + ' ' + data.notes[i].toTitle + ' (' + data.printInfo.toPrintInfo[data.notes[i].newDocumentId] + ')';
                let createDate = Moment(data.notes[i].createdDate.date).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                csvString += oldBookTitle + ',' +
                    newBookTitle + ',' +
                    data.notes[i].fromSection + ',' +
                    data.notes[i].toSection + ',' +
                    type + ',' +
                    data.notes[i].action + ',' +
                    createDate + "\n";
            }
            return csvString;
        },
        exportTitleListCSV: function(data) {
            const { origin } = window.location;
            let csvString = 'Titles, SKU, Title URL\n';
            data.map( (element) =>  {
                element.display_title = element.display_title.replaceAll(",", " ");
                let displayTitle = element.display_title.replaceAll(";", "");
                csvString +=  displayTitle + ',' +  element.sku + ',' + ( origin + element.link )+ '\n';
                return element;
            });
            return csvString;
        },
        trackDocumentReport(data) {

            let csvString = 'ordId,User Name,Email,Book Title,Section,Time Stamp,Action Taken\n';
            for (let i = 0; i < data.length; i++) {
                let actions = '';
                data[i].actions.forEach(function (item) {
                    actions += item.action + ' | ';
                });
                csvString += data[i].id + ',' + data[i].user.first_name + data[i].user.last_name + ',' + data[i].user.email + ',' + this.getBookTitle(data[i].document_info)
                    + ',' + data[i].section_title + ',' + data[i].created_at + ',' + actions + "\n";
            }
            return csvString;
        },
        exportCsv(data, type, code = '') {
            let csvString = '';
            let filename = 'report.csv';
            const date = new Date();
            let dd = String(date.getDate()).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0');
            let yyyy = date.getFullYear();
            let today = dd + '-' + mm + '-' + yyyy;
            switch (type) {
                case "assignment":
                    csvString = this.assignmentUsageReport(data);
                    filename = 'assignment_usage_report.csv';
                    break;
                case "concurrent_code":
                    csvString = this.concurrentCodeUsageReport(data, code);
                    filename = 'concurrent_code_usage_report.csv';
                    break;
                case "track_document":
                    csvString = this.trackDocumentReport(data);
                    filename = 'track_document_report.csv';
                    break;
                case "notes_log":
                    csvString = this.notesLog(data);
                    filename = 'notes_log.csv';
                    break;
                case "premium-complete-titles":
                    csvString = this.exportTitleListCSV(data);
                    filename = 'premium_complete_titles_'+ today +'.csv';
                    break;
            }
            this.responseCsv(csvString, filename); // Download CSV
        },
        responseCsv(csvString, filename) {
            var blob = new Blob([csvString]);
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        bookLink(book, chapterSlug = null, sectionId = null) {
            // No click action for premium complete book
            if ('Premium Complete' === book.content_type.name) {
                return false;
            }
            // Redirect to the book TOC or show subscription required modal
            this.redirectToBookPage(book, chapterSlug, sectionId);
        },
        redirectToBookPage(book, chapterSlug = null, sectionId = null) {
            const hashString = sectionId ? ('#' + sectionId.replace('#', '')) : '';

            if (book.document_slug) {
                window.location.href = Routing.generate("content_document_slug", {
                    documentSlug: book.document_slug,
                    chapterSlug: chapterSlug // defaults to landing page when null
                }) + hashString;
            } else {
                window.location = Routing.generate('content_document', {
                    'documentId': book.document_id,
                    'chapterId': chapterSlug
                }) + hashString;
            }
        },
        toArray(obj) {
            let resultArray = Object.keys(obj).map(function (key) {
                return obj[key];
            });

            return resultArray;
        },
        getRoute(slug, opt_params = {}) {
            // https://github.com/FriendsOfSymfony/FOSJsRoutingBundle/blob/2.8.0/Resources/public/js/router.js#L286
            return Routing.generate(slug, opt_params);
        },
        goToRoute(route, opt_params = {}) {
            window.location = this.getRoute(route, opt_params);
        },
        getBookURL(book) {
            return (book.document_slug)
                ? Routing.generate("content_document_slug", {documentSlug: book.document_slug})
                : Routing.generate('content_document', {'documentId': book.document_id, 'chapterId': 'toc'});
        },
        exportPdf(pdfurl, filename) {
            let a = window.document.createElement("a");
            a.href = pdfurl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        sortTagsByTitle(tags) {
            if (tags.length > 0) {
                let results = _.sortBy(tags, function (item) {
                    return _.lowerFirst(item.tag);
                });
                return results;
            }
        },
        reloadSubscriptions() {
            // Refresh page to reflect any access level change (not for admin actions)
            if (!_.includes(window.location.href, 'admin/subscriptions/')) {
                this.clearPremiumSubscriptions();
                this.$session.remove('subscriptionType');
                this.$session.remove('contentSearchCategories');
            }
            window.location.reload();
        },
        authPortal() {
            return 'https://auth.iccsafe.org/login/?callback=' + window.location.href;
        },
        getLoginUrl() {
            let useLoginPortal = window.document.getElementById('app').attributes['data-use-login-portal'].value;
            return (Number(useLoginPortal) ? this.authPortal() : this.getRoute('security_login'));
        },
        isAstmCompassEnabled() {
            let astmCompassEnabled = window.document.getElementById('app').attributes['data-astm-compass-enabled'].value;
            return Number(astmCompassEnabled);
        },
        isEsAcScopeAcEnabled() {
            let esAcEnabled = window.document.getElementById('app').attributes['data-es-ac-scope-enabled'].value;
            return Number(esAcEnabled);
        },
        isNewPremiumTagCategoriesEnabled() {
            return AppParameters.new_premium_tag_categories_enabled;
        },
        isCodeInsightsFilterEnabled() {
            return AppParameters.code_insights_filter;
        },
        premiumCompleteTitlesCount: function() {
            return AppParameters.premium_complete_titles_count;
        },
        enterpriseSampleUser() {
            let app = window.document.getElementById('app');
            let sampleCsv = '';
            if(app){
                sampleCsv = app.attributes['data-enterprise-user-import-csv-sample'].value;
            } else {
                sampleCsv = window.document.getElementById('app_admin').attributes['data-enterprise-user-import-csv-sample'].value;
            }

            return sampleCsv;
        },
        getAstmToken(astmRefStd, sectionNumber) {
            let contentId = sectionNumber;
            let accessedFrom = 'content';
            if (null === contentId) {
                accessedFrom = 'category';
            }
            this.$http.get(Routing.generate('get_access_token', {
                iccDesignation: astmRefStd,
                contentId: sectionNumber
            })).then(response => {
                if (!_.isEmpty(response.data.token)) {
                    let eventData = {
                        astmId: response.data.astmStandard.id,
                        accessedFrom: accessedFrom,
                        contentId: contentId
                    };
                    this.logAccessAndRedirectToAstmCompass(eventData, response.data);
                } else {
                    EventBus.fire('onAstmTokenError', {errorMessage: 'Token not found'});
                }
            });
        },
        logAccessAndRedirectToAstmCompass(eventData, responseData) {
            this.$http.post(Routing.generate('log_astm_access'), eventData)
                .then(response => {
                    this.logGaEvent();
                    this.astmCompassRedirect(responseData);
                });
        },
        logAstmAccess(astmDocumentInfoId, contentId) {
            return new Promise((resolve, reject) => {
                let accessedFrom = 'category';
                if (null !== contentId) {
                    accessedFrom = 'content';
                }
                let eventData = {
                    accessedFrom: accessedFrom,
                    astmDocumentInfoId: astmDocumentInfoId,
                    contentId: contentId
                };
                this.$http.post(Routing.generate('log_astm_access'), eventData)
                    .then(response => {
                        this.logGaEvent();
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        logGaEvent() {
            this.$ga.event({
                hitType: 'event',
                eventCategory: 'astmStandardAccess',
                eventAction: 'click',
                eventLabel: 'ASTM Standard Accessed' // Abbr can be appended when made dynamic
            });
        },
        astmCompassRedirect(data) {
            let form = document.createElement("form");
            let element1 = document.createElement("input");
            let token = data.token;
            form.method = "POST";
            form.action = data.url;
            form.target = "_blank";
            element1.value = token;
            element1.name = "token";
            form.appendChild(element1);
            document.body.appendChild(form);
            form.submit();
        },
        appClick(apple = false) {
            let url;
            if (apple) {
                url = 'https://apps.apple.com/us/app/digital-codes-premium/id1523315914';
                this.$ga.event({
                    hitType: 'event',
                    eventCategory: 'appImageClickApple',
                    eventAction: 'click',
                    eventLabel: 'User clicked the Apple App image'
                })
            } else {
                url = 'https://play.google.com/store/apps/details?id=org.iccsafe.codes';
                this.$ga.event({
                    hitType: 'event',
                    eventCategory: 'appImageClickGoogle',
                    eventAction: 'click',
                    eventLabel: 'User clicked the Google App image'
                })
            }
            window.open(url, '_blank');
        },
        gtmEvent(category, action, label) {
            if (this.gtmEnabled) {
                window.dataLayer.push({
                    'event': 'gaEvent',
                    'category': category,
                    'action': action,
                    'label': label
                });
            }
        },
        getPremiumCompleteBook() {
            this.$http.get(Routing.generate('get_document_by_sku', {
                sku: this.$getConst('premiumCompleteSku')
            })).then(response => {
                if (response.data) {
                    this.goToRoute('collections_landing', {documentSlug: response.data.document_slug});
                }
            });
        },
        getPrintingInfo(title) {
            let printInfo = '';
            if (!title.is_collection && !title.is_astm) {
                let objDate = new Date(title.print_version_month + "/01/" + title.print_version_year.year),
                    month = objDate.toLocaleString('en-us', {month: "short"});

                printInfo = title.print_version_edition.version + ' Version: ' + month + ' ' + title.print_version_year.year;
            }
            return printInfo;
        },
        async getUserLocation(){
            /** @type GeolocationPosition */
            let geoLocationPosition = null;

            try {
                geoLocationPosition = await GeoLocator.getCurrentPosition();
            } catch (e) {
                // do nothing
            }

            // got the coordinates or not?
            const params = geoLocationPosition ? {
                lat: geoLocationPosition.coords.latitude,
                lon: geoLocationPosition.coords.longitude
            } : {};

            return params;
        },
        displayPremiumTitleBorder(title){
            return this.isPremiumSubscribed(title.sku) && !this.isTrialExcluded(title);
        },
        displayPremiumTitleBorderAstm(title){
            return title.is_astm
                && title.astm_subject
                && this.isPremiumSubscribed(title.sku)
                && !this.isTrialExcluded(title);
        },

        /**
         * redirect to given url or tab.
         *
         * @param {string} url redirect to a url.
         * @param {string} redirectSectionId scroll to a section.
         * @param {string} redirectTabId scroll and activate a tab.
         */
        redirectToUrlOrTab(url = null, redirectSectionId = null, redirectTabId = null){
            if (url) {
                window.open(url, '_blank');
            } else {
                const el = document.getElementById(redirectSectionId);
                if (el) {
                    el.click();
                    el.scrollIntoView({behavior: 'smooth'});
                }
                if (redirectTabId) {
                    EventBus.fire('activate_library_tab', parseInt(redirectTabId));
                }
            }
        },
        getProfileColor(profile) {
            switch (profile.type) {
                case 'personal':
                    if (profile.is_premium) {
                        return 'accent2';
                    }
                    return 'primary';
                case 'concurrent':
                    return  'accent2';
                case 'enterprise':
                    return 'cdpBlue';
            }
        },
        trimmedText(text, maxLength = 100) {
            if (text !=='') {
                let textLength = text.length;
                let truncatedText = text.slice(0, maxLength);
                return  textLength > truncatedText.length ? truncatedText + '...' : truncatedText;
            }

            return '';
        },
        updateFrameHeight(iframe) {
            const bounds = iframe.getBoundingClientRect();
            const yPos = (bounds.y + 20).toString();
            iframe.style.setProperty('height', `calc(100vh - ${yPos}px)`);
        },
        isCognitoTokenSyncRequired() {
            const allowInMinutes = 25; //refresh after 25 mins in FE
            const lastTokenSynchedAt = localStorage.getItem('lastTokenSynchedAt');
            if (lastTokenSynchedAt) {
                const currentTime = new Date().getTime();
                const timeElapsed = currentTime - parseInt(lastTokenSynchedAt);
                const minutesElapsed = timeElapsed / (1000 * 60);
                return minutesElapsed >= allowInMinutes;
            }
            return true;
        },
        refreshLastTokenSynchedAt(){
            const timestamp = new Date().getTime();
            localStorage.setItem('lastTokenSynchedAt', timestamp);
        },
        goToTheShop(url = '', isFullPathUrl = false) {
            const shopUrl = isFullPathUrl ? url : AppParameters.shop_url + url;
            if(!this.isCognitoTokenSyncRequired()){
                window.open(shopUrl, '_blank');
            } else {
                EventBus.fire('full-page-loader', true)
                this.$http.get(Routing.generate('get_shop_url'))
                    .then(response => {
                        this.refreshLastTokenSynchedAt();
                        EventBus.fire('full-page-loader', false)
                        window.open(shopUrl, '_blank');
                    });
            }

        },
        refreshThePageIfBackBtnClick: function() {
            window.addEventListener("pageshow", function(event) {
                if (event.persisted ||
                    ( "undefined" !== typeof window.performance  &&
                      "back_forward" === performance.getEntriesByType("navigation")[0].type)
                ) {
                   return window.location.reload(true);
                }
            });
        }
    }

}
