<template>
    <div class="disable-selection tag-icons">
        <v-chip
            x-small
            class="px-1 cp"
            v-if="tag === 'es'"
            color="primary"
            label
            text-color="white">
            ES
        </v-chip>
        <v-chip
            x-small
            class="px-1 cp"
            v-if="tag === 'errata'"
            color="red"
            label
            text-color="white">
            E
        </v-chip>
        <v-tooltip 
            v-if="tag === 'premium'" 
            color="black" 
            bottom>
            <template #activator="{ on }">
                <v-chip
                    x-small
                    class="px-1 cp"
                    v-on="on"
                    color="accent2"
                    title=""
                    label
                    text-color="white">
                    P
                </v-chip>
            </template>
            <span>Premium Code Insights available at the end of the section</span>
        </v-tooltip>
        <v-chip
            x-small
            class="px-1 cp"
            v-if="tag === 'cdp'"
            color="blue"
            label
            text-color="white">
            CDP
        </v-chip>
        <v-chip
            x-small
            class="px-1 cp"
            v-if="tag === 'srcc'"
            color="purple"
            label
            text-color="white">
            S
        </v-chip>
        <v-chip
            x-small
            class="px-1 cp"
            v-if="tag === 'video'"
            color="orange"
            label
            text-color="white">
            V
        </v-chip>
    </div>
</template>

<script>
    export default {
        props: {
            tag: {
                required: true,
                type: String
            }
        }
    }
</script>
