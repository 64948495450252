var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("premium-tool-header", {
        ref: "premiumToolHeader",
        attrs: {
          title: "Compliance Calculators",
          "sub-title": "In-app computations to streamline your workflow",
        },
        scopedSlots: _vm._u([
          {
            key: "avatar",
            fn: function () {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "accent2Light",
                    attrs: { color: "accent2", size: "36" },
                  },
                  [
                    _vm._v(
                      "\n                mdi-calculator-variant-outline\n            "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grey lighten-5" },
        [
          !_vm.iframe
            ? [
                _c(
                  "v-container",
                  { staticClass: "content-container pt-0 pb-12" },
                  [
                    _c("account-access-expand", {
                      attrs: { type: "calculatorCodeCompliance" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "subText",
                            fn: function () {
                              return [
                                _vm._v(
                                  "\n                        Powered by ClearCalcs, get access to available compliance calculators on Digital Codes\n                    "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3848607661
                      ),
                    }),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c(
                            "h1",
                            {
                              staticClass: "fs-24 oxygen font-weight-bold mt-6",
                            },
                            [
                              _vm._v(
                                "\n                            Get started\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.loading || !_vm.calculators.length
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mb-6",
                                    attrs: { outlined: "" },
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _vm.loading
                                          ? _c("loading-linear")
                                          : _c(
                                              "p",
                                              {
                                                staticClass: "mb-0 text-center",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    No calculators were found.  Please check back later.\n                                "
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          _vm._l(_vm.calculators, function (item, key) {
                            return _c(
                              "v-col",
                              {
                                key: key,
                                staticClass: "cp",
                                attrs: { cols: "12", sm: "4" },
                                on: {
                                  click: function ($event) {
                                    return _vm.calculatorClickAction(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "fill-height d-flex flex-column",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-right pa-1" },
                                      [
                                        _c("access-icon", {
                                          attrs: {
                                            accessLevel: _vm.hasPremium
                                              ? "premiumActive"
                                              : "premium",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text-center" }, [
                                      _c("img", {
                                        attrs: {
                                          height: "200px",
                                          src: _vm.asset(
                                            item.image_url
                                              ? item.image_url
                                              : require("../../images/calculators/calculator_placeholder.svg")
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-col", [
                                              _c(
                                                "h3",
                                                { staticClass: "fs-20" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(item.title) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                { staticClass: "mt-3 mb-0" },
                                                [
                                                  _vm._v(
                                                    "\n                                            " +
                                                      _vm._s(item.description) +
                                                      "\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-spacer"),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3 pt-1" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              disabled: !_vm.hasPremium,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "", dark: "" } },
                                              [
                                                _vm._v(
                                                  "\n                                        mdi-calculator-variant-outline\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                                    Open Calculator\n                                "
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "darkBlue py-2" },
                  [
                    _c(
                      "v-container",
                      { staticClass: "content-container white--text" },
                      [
                        _c(
                          "v-row",
                          { attrs: { align: "center" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "", align: "center" } },
                                  [
                                    _c("v-col", { attrs: { cols: "auto" } }, [
                                      _c("img", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          src: _vm.asset(
                                            require("../../images/calculators/clearcals_icon.webp")
                                          ),
                                          alt: "ClearCalcs Logo",
                                          title: "ClearCalcs Logo",
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-col", [
                                      _c(
                                        "h2",
                                        {
                                          staticClass:
                                            "fs-24 oxygen font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Powered by ClearCalcs\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "fs-20 font-weight-medium mb-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Overview of ClearCalcs\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-6" }, [
                                          _vm._v(
                                            "\n                                        ClearCalcs streamlines structural engineering with cloud structural design and analysis software to help engineers and designers do their best work with confidence.\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "fs-20 font-weight-medium mb-2",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        An ICC/ClearCalcs Collaboration\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "mb-6" }, [
                                          _vm._v(
                                            "\n                                        ICC and ClearCalcs are teaming up to provide high quality compliance calculators right in Digital Codes. This is a pilot program that we are very excited to make available and expand on.\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              href: _vm.clearCalcUrl,
                                              target: "_blank",
                                              block: _vm.isMobilePort,
                                              large: _vm.isMobilePort,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Learn more at ClearCalcs\n                                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            !_vm.isMobilePort
                              ? _c("v-col", [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.asset(
                                        require("../../images/calculators/calculator.webp")
                                      ),
                                      alt: "ClearCalcs Calculator",
                                      title: "ClearCalcs Calculator",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mt-4" },
                          [
                            _c("v-col", [
                              _c(
                                "h2",
                                {
                                  staticClass: "fs-20 oxygen font-weight-bold",
                                  class: { "text-center": !_vm.isMobilePort },
                                },
                                [
                                  _vm._v(
                                    "\n                                More calculations from ClearCalcs\n                            "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          _vm._l(_vm.clearCalcs, function (item, key) {
                            return _c(
                              "v-col",
                              { key: key, attrs: { cols: "12", sm: "3" } },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "fs-20 font-weight-medium mb-6",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(item.name) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(item.calcs, function (item, key) {
                                  return _c(
                                    "p",
                                    { key: key, staticClass: "mb-1" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-container",
                  { staticClass: "content-container py-12" },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [_c("f-a-qs", { attrs: { faqs: _vm.faqs } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticClass: "text-center" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.getPremiumCompleteBook()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mt-6",
                                attrs: {
                                  src: _vm.asset(
                                    require("../../images/banners/unlock_premium_complete.webp")
                                  ),
                                  alt: "Unlock Premium Complete",
                                  title: "Unlock Premium Complete",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _c(
                "v-container",
                { staticClass: "content-container" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "a",
                          {
                            staticClass: "fs-16",
                            on: {
                              click: function ($event) {
                                _vm.iframe = null
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mt-n1",
                                attrs: { color: "primary", left: "" },
                              },
                              [
                                _vm._v(
                                  "\n                            mdi-arrow-left\n                        "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n                        Back to Get Started\n                    "
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _c("v-card-title", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.iframe.title) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c("v-card-text", { staticClass: "pa-0" }, [
                                _c("iframe", {
                                  staticStyle: { border: "0" },
                                  attrs: {
                                    height: `${
                                      _vm.iframe.iframe_height
                                        ? _vm.iframe.iframe_height
                                        : 600
                                    }px`,
                                    width: "100%",
                                    src: _vm.iframe.iframe_url,
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }