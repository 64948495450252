<template>
    <div>
        <!--Create or Update Bookmark-->
        <v-dialog
            persistent
            v-model="modal"
            max-width="700">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Highlight / Note
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="closeModal">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>

                            <v-alert
                                    :value="selectionTruncated"
                                    color="error"
                                    icon="warning"
                                    outlined
                            >
                                The note you are creating will only apply to the active section and although highlighted
                                text may expand outside of this section the highlight will only apply to text within
                                that section once the note is saved.
                            </v-alert>

                            <vue-editor v-model="editorData" :editor-toolbar="customToolbar"/>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <p class="mb-0">Select Existing Project Tag</p>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <template v-if="!loadingTags">
                            <v-col>
                                <v-select
                                    hide-details
                                    v-model="selectedTag"
                                    :items="tags"
                                    item-text="tag"
                                    item-value="id"
                                    chips
                                    label="Select Tag from the list"
                                    solo>
                                </v-select>
                            </v-col>
                            <v-col sm="auto">
                                <v-btn
                                    class="primary"
                                    :disabled="isUpdate && selectedTag === 1"
                                    @click="createNewTagModal(isUpdate, selectedTag)">
                                    <span v-if="isUpdate">Edit Tag</span>
                                    <span v-else>New Tag</span>
                                </v-btn>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col>
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeModal">Cancel</v-btn>
                    <v-btn color="primary" @click="saveNote">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Remove Highlight annotation or bookmark Modal-->
        <v-dialog v-model="removeModal" max-width="400">
            <v-card>
                <v-card-title
                    class="primary white--text"
                    :class="{
                        'headline': !isMobilePort,
                        'caption mobile-title': isMobilePort
                    }">
                    Remove Note
                    <v-spacer></v-spacer>
                    <v-icon small class="mr-2 accent2--text" @click="removeModal = false">close</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col class="text-center">
                            <p class="mb-0 mt-5">Are you sure you want to remove this note?</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="removeModal = false">Cancel</v-btn>
                    <v-btn color="error" @click="deleteNote">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Validate URLs in the user note -->
        <v-dialog v-model="invalidNoteUrl" max-width="400">
            <v-card>
                <v-card-title class="headline">Invalid URL</v-card-title>
                <v-card-text>
                    Your note contains an Invalid URL?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="invalidNoteUrl = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import TagsMixins from './mixins/tags';
    import urlHelper from './mixins/urlHelper';
    import ContentUtils from '../inc/contentUtils';
    import EditorConfigs from '../../inc/editorConfigBar';
    import Mobile from '../../inc/mixins/mobile';

    export default {
        data() {
            return {
                modal: false,
                removeModal: false,
                note: {},
                tagId: 0,
                editorData: '',
                isUpdate: false,
                invalidNoteUrl: false,
                selectionTruncated: false
            }
        },
        mixins: [TagsMixins, urlHelper, ContentUtils, EditorConfigs, Mobile],

        methods: {
            saveNote() {
                // Validate URLs in the user note
                if(!this.validateLinksInNote(this.editorData)) {
                    this.invalidNoteUrl = true;
                    return false;
                }
                let object = _.assign(this.note, {'note': this.editorData, 'tag': this.selectedTag});

                this.$http.post(Routing.generate('create_note'), object)
                    .then(response => {
                        // Set our class applier information.
                        let tagId = _.toInteger(object.tag);
                        let options,
                            array = [],
                            tagDefaultColor = '8fb08c',
                            tag = _.find(this.$store.getters.getTags, {'id': tagId});

                            let tagColor = (_.isUndefined(tag.color)) ? tagDefaultColor : tag.color;
                            if(undefined != tag) {
                                let currentTagElements = document.querySelectorAll("span[data-tag-id='" + tagId + "']");
                                if(currentTagElements) {
                                    this.updateTagHighlightColor(currentTagElements, tagColor);
                                }
                            }

                        let data = {
                            annotationId: response.data[0].annotation.id,
                            tagColor: tagColor,
                            tagId: tagId
                        };
                        let applier = this.applierUtil(data),
                            section = document.getElementById('action-wrapper-' + response.data[0].section_number),
                            id = response.data[0].section_number;
                        if (section) {
                            // Check to see if we have a tag change, if so remove old highlight.
                            if (this.tagId !== 0 && this.tagId !== tagId)
                                this.removeHighlight({
                                    sectionId: response.data[0].section_number,
                                    note: response.data[0],
                                    tag: {
                                        id: this.tagId,
                                        color: this.getTagColor(this.tagId)
                                    }
                                });
                            // Apply highlight to dom.
                            this.highlightUtil(applier, section, response.data[0].annotation, 'add');
                        }
                        if (this.isUpdate) {
                            options = {
                                action: 'update',
                                sectionId: id,
                                type: 'annotation',
                                id: response.data[0].id,
                                notes: {
                                    annotations: response.data
                                }
                            };
                            response.data[0].isUpdate = true;
                            EventBus.fire('update-note-preview-'+response.data[0].annotation.id, response.data[0]);
                        } else {
                            options = {
                                action: 'create',
                                sectionId: id,
                                notes: {
                                    annotations: response.data
                                }
                            };
                            // Add our note preview.
                            let _this = this;
                            let element = document.getElementById('note-annotation-'+response.data[0].annotation.id);
                            if (element) {
                                element.addEventListener('mouseenter', function(event) {
                                    EventBus.fire(
                                        'toggle-note-preview-'+_this.stripIds(event.target.id),
                                        {
                                            mouse: true,
                                            modal: true,
                                            event: event
                                        }
                                    );
                                });
                                element.addEventListener('mouseleave', function(event) {
                                    EventBus.fire(
                                        'toggle-note-preview-'+_this.stripIds(event.target.id),
                                        {
                                            mouse: false,
                                            modal: false
                                        }
                                    );
                                });
                            }                            
                        }
                        EventBus.fire('update-notes', options);
                        this.highlightedText = {};
                        if (window.getSelection) {
                            window.getSelection().removeAllRanges();
                        } else if (document.selection){
                            document.selection.empty();
                            let noteElement = document.getElementById(id+'_note');
                            (noteElement) ? noteElement.classList.add('v-icon--disabled') : '';
                        }
                        this.closeModal();
                    });
            },

            updateNoteData(note) {
                this.isUpdate = true;
                this.$http.get(Routing.generate('get_note', {id: note.id}))
                    .then(response => {
                        this.note = {
                            id: response.data.id,
                            annotation: response.data.annotation
                        };
                        this.getUserTags(response.data.tag);
                        this.tagId = _.toInteger(response.data.tag);
                        this.editorData = response.data.annotation.text;
                    });
            },

            updateTagHighlightColor(elements, tagColor) {
                _.forEach(elements, function(spanTag) {
                    let element = document.getElementById(spanTag.getAttribute('id'));
                    if(element) {
                        element.style.backgroundColor = "#"+tagColor;
                    }
                });
            },

            closeModal() {
                this.note = {};
                this.editorData = '';
                this.tags = [];
                this.selectedTag = [];
                this.loadingTags = true;
                this.modal = false;
            },

            deleteNote() {
                // Remove highlight.
                this.$http.get(Routing.generate('remove_note', {noteId: this.note.id}))
                    .then(response => {
                        try {
                            let data = {
                                sectionId: this.note.sectionID,
                                note: this.note,
                                tag: {
                                    id: this.note.tagID,
                                    color: this.getTagColor(this.note.tagID)
                                }
                            };
                            this.removeHighlight(data);
                        }catch(e) {
                            //should return nothing
                        }
                        this.removeModal = false;
                        let options = {
                            action: 'remove',
                            type: 'annotation',
                            id: this.note.id,
                            sectionId: this.note.sectionID
                        };
                        EventBus.fire('update-notes', options);
                    });
            }
        },

        created() {
            EventBus.listen('note-modal', (note) => {
                if (note.id) {
                    this.updateNoteData(note);
                } else {
                    this.note = note;
                    this.getUserTags(1);
                    this.isUpdate = false;
                }
                this.modal = true;
                this.selectionTruncated = !!_.get(note, 'highlightedText.selectionTruncatedToSingleSection');
            });

            EventBus.listen('remove-note', (data) => {
                this.note = data;
                this.removeModal = true;
            });

            EventBus.listen('change-updated-tag', (data) => this.selectedTag = data);
        }

    }
</script>
<style lang="scss">
    .ql-editor {
        height: 200px !important;
    }
</style>
