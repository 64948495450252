<template>
    <div class="grey lighten-5" v-if="!isMobilePort">
        <v-container
            class="content-container">
            <section-heading
                class="my-5"
                heading="Premium features for the titles you use"
                subHeading="Sign up for free and subscribe to access features on the titles you’re using regularly.">
            </section-heading>
            <v-row class="border-bottom">
                <v-col
                    v-for="(columnHeading, key) in columnHeadings"
                    :key="key"
                    class="fs-20 font-weight-medium black--text"
                    :class="key !== 0 ? 'text-center': ''">
                    {{ columnHeading }}
                </v-col>
            </v-row>
            <v-row class="border-bottom">
                <v-col
                    v-for="(subHeading, key) in subHeading"
                    :key="key"
                    class="fs-16"
                    :class="key !== 0 ? 'text-center': ''">
                    {{ subHeading }}
                </v-col>
            </v-row>
            <v-row v-for="(premiumFeature, key) in premiumFeatures"
                :key="key"
                class="fs-16"
                :class="key !== premiumFeatures.length - 1 ? 'border-bottom': ''"
                align="center">
                <v-col>
                    {{ premiumFeature.title }}
                </v-col>
                <v-col class="text-center">
                    <v-icon
                        v-if="premiumFeature.isReadOnly"
                        color="grey">
                        mdi-check-circle-outline
                    </v-icon>
                </v-col>
                <v-col class="text-center">
                    <v-icon
                        v-if="premiumFeature.isIndividualTitles"
                        color="teal">
                        mdi-check-circle-outline
                    </v-icon>
                </v-col>
                <v-col class="text-center">
                    <v-icon
                        v-if="premiumFeature.isPremiumBundleTitles"
                        color="teal">
                        mdi-check-circle-outline
                    </v-icon>
                </v-col>
                <v-col class="text-center">
                    <v-icon
                        v-if="premiumFeature.isPremiumComplete"
                        color="teal">
                        mdi-check-circle-outline
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="my-9">
                <v-col class="text-center fs-20">
                    <p class="mb-2">*Feature only apply to the titles for which you have a Premium subscription</p>
                    <a
                        :href="getRoute('premium_features')"
                        class="font-weight-bold">
                        Learn more about Premium features
                    </a>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import SectionHeading from '../SectionHeading';
import mobile from '../inc/mixins/mobile';

export default {
    components: {SectionHeading},
    mixins: [mobile],
    data(){
        return {
            columnHeadings: [
                'Feature',
                'Basic',
                'Premium Singles',
                'Premium Collections',
                'Premium Complete'
            ],
            subHeading: [
                'Included titles',
                'Read-only titles',
                'Individual Premium titles',
                'Premade bundled Premium titles',
                `${this.premiumCompleteTitlesCount()}+ Premium titles`
            ],
            premiumFeatures: [
                {
                    title: 'Read access',
                    isReadOnly: true,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Add titles to favorites',
                    isReadOnly: true,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Search code titles',
                    isReadOnly: true,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Copy, paste and print code text*',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Advanced content search*',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Bookmarking*',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Premium Insights*',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'AI Navigator',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Premium Answers',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Compliance Calculators',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Project Tags',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'License Configuration',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                },
                {
                    title: 'Includes all prior cycles for the same title',
                    isReadOnly: false,
                    isIndividualTitles: true,
                    isPremiumBundleTitles: true,
                    isPremiumComplete: true
                }
            ]
        }
    }
}
</script>
<style scoped>
    .border-bottom {
        border-bottom: 1px solid #E0E0E0;
    }
</style>