<template>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        clipped
        :width="400">
        <v-list-item>
            <v-list-item-content>
                <v-row
                    dense
                    align="center">
                    <v-col>
                        <img
                            class="logo"
                            height="42"
                            :src="asset(require(`../../images/${logoSrc}`), 'default')"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                            class="accent--text"
                            icon
                            @click.stop="drawer = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
        <v-divider>
        </v-divider>
        <div v-show="!component" :key="'main'">
            <link-list-icons
                :links="finalLinks"
                @updateComponent="(data) => component = data">
            </link-list-icons>
            <v-list-item :href="isActiveUser ? getRoute('logout') : getLoginUrl()">
                <v-list-item-icon class="mr-2">
                    <v-icon color="primary">
                        {{isActiveUser ? 'mdi-logout-variant' : 'mdi-login-variant'}}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="fs-16 font-weight-bold">
                    {{isActiveUser ? 'Sign Out' : 'Sign In'}}
                </v-list-item-title>
            </v-list-item>

          <div class="d-flex flex-column pa-2 pa-md-3">

            <v-btn
                v-if="!hasPremiumSubscription"
                elevation="0"
                block
                color="accent2"
                class="font-weight-bold mb-2"
                large
                :href="getRoute(pricingPageEnabled ? 'pricing_page' : 'premium_features')"
            >
              Subscribe
            </v-btn>
            <start-free-trial color="primary" :outlined="true"></start-free-trial>
          </div>

        </div>
        <component
            :is="component"
            v-show="component"
            @backToMain="component = null"
            :key="component"/>
    </v-navigation-drawer>
</template>

<script>
    import PremiumFeaturesPanel from "./PremiumFeaturesPanel.vue";
    import LinkListIcons from "./LinkListIcons.vue";
    import PremiumToolsPanel from "./PremiumToolsPanel.vue";
    import megaMenuMixin from "../inc/mixins/megaMenu.mixin";
    import FavoritesPanel from "./FavoritesPanel.vue";
    import EnterprisePanel from "./EnterprisePanel.vue";
    import BrowseContentsPanel from "./BrowseContentPanel.vue";
    import favorites from "../inc/mixins/favorites";
    import StartFreeTrial from "../buttons/StartFreeTrial.vue";
    import {AppParameters} from "../../AppParameters";

    const PremiumTools = 'Premium Tools'

    export default {
        name: 'MegaMenu',
        components: {
          StartFreeTrial,
            BrowseContentsPanel,
            EnterprisePanel,
            FavoritesPanel,
            PremiumToolsPanel,
            LinkListIcons,
            PremiumFeaturesPanel
        },
        mixins: [megaMenuMixin, favorites],
        data() {
            return {
                drawer: false,
                component: null,
                pricingPageEnabled: AppParameters.pricing_page_enabled,
                links: [
                    {
                        icon: 'mdi-home-outline',
                        title: 'Home',
                        link: this.getRoute('index_landing'),
                        active: true
                    },
                    {
                        icon: 'mdi-text-box-multiple-outline',
                        title: 'Codes',
                        link: null,
                        component: BrowseContentsPanel,
                        active: true
                    },
                    {
                        icon: 'mdi-heart-outline',
                        title: 'Favorites',
                        link: null,
                        component: FavoritesPanel,
                        active: true
                    },
                    {
                        icon: 'mdi-creation',
                        iconColor: 'accent2',
                        title: PremiumTools,
                        link: null,
                        component: PremiumToolsPanel,
                        active: true
                    },
                    {
                        icon: 'mdi-medal-outline',
                        title: 'Explore Premium',
                        link: null,
                        component: PremiumFeaturesPanel,
                        new: true,
                        active: true
                    },
                    {
                        icon: 'mdi-school-outline',
                        title: 'Digital Codes for Exams',
                        link: this.getRoute('user_exams'),
                        active: true
                    },
                    {
                        icon: 'mdi-account-group-outline',
                        title: 'Premium for Teams',
                        link: this.getRoute('concurrent-access'),
                        active: true
                    },
                    {
                        icon: 'mdi-format-quote-close-outline',
                        title: 'Testimonials',
                        link: this.getRoute('testimonials'),
                        active: true
                    },
                ]
            }
        },
        computed: {
            logoSrc() {
                if (this.activeEnterpriseProfile) {
                    return 'DCPEnterpriseLogo.jpg'
                } else if (this.hasPremiumSubscription) {
                    return 'premium_large.png'
                }
                return 'logo_dc.png'
            },
            finalLinks() {
                
                // we need to make a copy so that splice operations below DO NOT affect original array
                let links = [...this.links];

                if (this.isActiveUser) {
                    links.splice(1, 0, {
                        icon: 'mdi-view-dashboard-outline',
                        title: 'Dashboard',
                        link: this.getRoute('dashboard_landing'),
                        active: true
                    })
                }
                if (this.isEnterpriseAdmin) {
                    const index = this.links.findIndex(obj => obj.title === PremiumTools)
                    links.splice(index+1, 0, {
                        icon: 'mdi-account-group-outline',
                        iconColor: 'cdpBlue',
                        title: 'Enterprise Admin Tools',
                        link: null,
                        component: EnterprisePanel,
                        active: true
                    })
                }
                //place search in menu
                let param = {
                    'section': 'titles',
                    'searchTermAny': ''
                };
                let searchMenuIndex = 2;
                if (this.isActiveUser && this.hasPremiumSubscription) {
                    this.$session.set(
                        'setExpandAdvancedSearch',
                        true
                    );
                    param = {
                        'section': 'content',
                        'searchTermAny': ''
                    };
                    searchMenuIndex = 3;
                }
                const searchUrl =  this.getRoute('sections_search_page', param);
                links.splice(searchMenuIndex, 0, {
                    icon: 'mdi-magnify',
                    title: 'Search',
                    link: searchUrl,
                    active: true
                })

                return links
            }
        },
        methods: {
            getFavoriteBooks() {
                this.$http.get(Routing.generate('get_favorite_books'))
                    .then(response => {
                        this.favorites = this.supplyFavoritesAndSort(response)
                        this.favoritesPromise = true
                        this.favoritesLoading = false
                    })
            },
            resetFavoriteBooks() {
                this.favoritesPromise = false;
                this.favoritesLoading = true;
                this.getFavoriteBooks();
            }
        },
        mounted() {
            if (this.isActiveUser) {
                this.getFavoriteBooks()
            }
        },
        created() {
            EventBus.listen('show-nav-drawer', (data) => this.drawer = data)
            EventBus.listen('reset-favorites', () => this.resetFavoriteBooks());
            if (this.isLoggedIn && !this.$session.get('favorites')) {
                this.resetFavoriteBooks();
            } else {
                // Make sure to clear out the session.
                this.favoritesLoading = false;
            }
        }
    }
</script>

<style lang="scss">
.v-overlay--active {
    z-index: 201;
}
.v-navigation-drawer--temporary {
    z-index: 202;
}
</style>
