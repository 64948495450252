var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", "max-width": 550 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _vm.element === "h3"
                ? _c(
                    "h3",
                    _vm._g(
                      _vm._b(
                        {
                          class: _vm.additionalClass,
                          domProps: { innerHTML: _vm._s(_vm.truncatedText) },
                        },
                        "h3",
                        attrs,
                        false
                      ),
                      _vm.showTooltip ? on : null
                    )
                  )
                : _c(
                    "p",
                    _vm._g(
                      _vm._b(
                        {
                          class: _vm.additionalClass,
                          domProps: { innerHTML: _vm._s(_vm.truncatedText) },
                        },
                        "p",
                        attrs,
                        false
                      ),
                      _vm.showTooltip ? on : null
                    )
                  ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }