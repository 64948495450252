var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "dashboard-tags" } },
    [
      _c("breadcrumb", { attrs: { title: "Manage Project Tags" } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { "justify-xl": "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "10" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "primary", dark: "" } },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", [_vm._v("comment")]),
                              _vm._v(" Project Tags\n                        "),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isMobilePort
                            ? [
                                _c("v-spacer"),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "accent2" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openModal(null)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Add Project Tag\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          [
                            _c(
                              "v-container",
                              [
                                _vm.isMobilePort
                                  ? _c(
                                      "v-row",
                                      {
                                        attrs: { dense: "", justify: "center" },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  dark: "",
                                                  color: "accent2",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openModal(null)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                            Add Project Tag\n                                        "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-data-table",
                              {
                                staticClass: "ma-0",
                                attrs: {
                                  "must-sort": "",
                                  headers: _vm.headers,
                                  items: _vm.tags,
                                  loading: _vm.loading,
                                  "items-per-page": _vm.itemsPerPage[0],
                                  "footer-props": {
                                    itemsPerPageOptions: _vm.itemsPerPage,
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(item.tag) +
                                                "\n                                        "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c("v-avatar", {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  color: "#" + item.color,
                                                  size: "15",
                                                },
                                              }),
                                              _vm._v(" "),
                                              !_vm.isMobilePort
                                                ? [
                                                    _vm._v(
                                                      "#" + _vm._s(item.color)
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _vm.isTagOwner(item.user)
                                            ? _c(
                                                "td",
                                                { staticClass: "text-right" },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { left: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      class: {
                                                                        "float-right":
                                                                          _vm.isMobilePort,
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "grey lighten-4",
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        dark: "",
                                                                        elevation:
                                                                          "1",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openModal(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey darken-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "edit"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Edit " +
                                                            _vm._s(item.tag)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { left: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      class: {
                                                                        "float-right":
                                                                          _vm.isMobilePort,
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "grey lighten-4",
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        dark: "",
                                                                        elevation:
                                                                          "1",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openConfirmModal(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey darken-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Delete " +
                                                            _vm._s(item.tag)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("td", [
                                                _vm._v(
                                                  "\n                                            View Only\n                                        "
                                                ),
                                              ]),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(" "),
                                _c("template", { slot: "no-results" }),
                              ],
                              2
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-modal"),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "400", persistent: "" },
          model: {
            value: _vm.dialogConfirm,
            callback: function ($$v) {
              _vm.dialogConfirm = $$v
            },
            expression: "dialogConfirm",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-expand-transition",
                        [
                          _c(
                            "v-row",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.error,
                                  expression: "error",
                                },
                              ],
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { type: "error", dense: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.error) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("p", [
                              _vm._v(
                                "You are about to remove all annotations and bookmarks associated with the project tag "
                              ),
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v('"' + _vm._s(_vm.tagName) + '"'),
                              ]),
                              _vm._v(".  This action cannot be undone."),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("Are you sure you wish to proceed?"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { sm: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error" },
                                  on: { click: _vm.closeConfirmModal },
                                },
                                [
                                  _vm._v(
                                    "\n                                Cancel\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { sm: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.deleteTag },
                                },
                                [
                                  !_vm.saving
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    Confirm\n                                "
                                        ),
                                      ])
                                    : _c("v-progress-circular", {
                                        attrs: {
                                          size: 20,
                                          indeterminate: "",
                                          color: "white",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }