var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("trial-header", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v("\n        Your FREE 14-Day Trial has expired\n    ")]
        },
        proxy: true,
      },
      {
        key: "description",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { "min-width": "950px" } },
              [
                _c(
                  "div",
                  { attrs: { id: "left" } },
                  [
                    _c("p", { staticClass: "oxygen primary--text text-2xl" }, [
                      _vm._v("Thank you for taking your "),
                      _c("strong", [_vm._v("Premium Complete")]),
                      _vm._v("\n                    trial.\n                "),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                    Unfortunately, the trial period has expired, but we encourage you to search across our\n                    wide library of titles and also purchase a\n                    "
                      ),
                      _c(
                        "strong",
                        {
                          staticClass:
                            "text-decoration-underline primary--text",
                        },
                        [_vm._v("Premium Complete")]
                      ),
                      _vm._v(
                        " subscription.\n                    All of the content you entered during your trial period is still waiting for you and\n                    will be unlocked once you upgrade to a premium subscription on your title.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-large": "",
                                  color: "primary",
                                  block: "",
                                },
                                on: { click: _vm.getPremiumCompleteBook },
                              },
                              [
                                _vm._v(
                                  "\n                            Get Premium Complete\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-large": "",
                                  outlined: "",
                                  color: "primary",
                                  href: _vm.getRoute("dashboard_landing"),
                                  block: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Go to My Library\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pl-10" },
                  [
                    _c("asset-image", {
                      attrs: {
                        src: require("../../images/workers-crane-building-background.png"),
                        width: "350",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }