<template>
    <v-card
        outlined
        class="grey lighten-4">
        <v-card-text class="py-1 px-3 pb-3">
            <v-row
                align="center"
                dense>
                <v-col>
                    <p class="font-weight-bold mb-0">
                        {{ title }}
                    </p>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        :disabled="!hasPremiumSubscription"
                        icon
                        :dark="hasPremiumSubscription"
                        small
                        color="black">
                        <v-icon
                            @click="expand = !expand">
                            {{ expand ? 'mdi-minus' : 'mdi-plus'}}
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <component
                :is="componentName"
                :expand="expand"
                @closeExpand="() => expand = false"
                @updateAdvancedSearch="(value) => this.updateAdvancedSearch(value)"/>
        </v-card-text>
    </v-card>
</template>

<script>
    import TitlesPanel from "./panels/TitlesPanel.vue";
    import AdvancedSearch from "./panels/AdvancedSearch.vue";
    import CategoriesPanel from "./panels/CategoriesPanel.vue";
    import YearsPanel from "./panels/YearsPanel.vue";
    import ContentTypePanel from "./panels/ContentTypePanel.vue";

    export default {
        name: 'FilterExpander',
        data() {
            return {
                expand: false
            }
        },
        components: {
            TitlesPanel,
            AdvancedSearch,
            CategoriesPanel,
            YearsPanel,
            ContentTypePanel
        },
        props: {
            title: {
                required: true,
                type: String
            },
            componentName: {
                required: true,
                type: String
            }
        },
        methods: {
            checkDefaultExpanded(componentName) {
                if (componentName === 'AdvancedSearch') {
                    if (this.$session.has('setExpandAdvancedSearch')) {
                        this.expand = true
                        this.$session.remove('setExpandAdvancedSearch')
                    }
                }
            },
            updateAdvancedSearch(value) {
                EventBus.fire('update-advanced-search', value);
            }
        },
        mounted() {
            this.checkDefaultExpanded(this.componentName);
        }
    }
</script>
